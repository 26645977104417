import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <AppBar 
      position="sticky" 
      sx={{ 
        background: 'linear-gradient(45deg, rgba(19, 47, 76, 0.4), rgba(19, 47, 76, 0.8))',
        backdropFilter: 'blur(10px)',
        borderBottom: '1px solid rgba(255,255,255,0.1)',
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '2px',
          background: 'linear-gradient(90deg, #ff5252, #ffd740, #69f0ae, #40c4ff)',
        }
      }}
    >
      <Toolbar>
        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          width: '100%',
          justifyContent: 'center'
        }}>
          <Button
            color="primary"
            variant={isActive('/') ? 'contained' : 'text'}
            onClick={() => navigate('/')}
            sx={{
              color: 'white',
              '&:hover': {
                background: 'rgba(255,255,255,0.1)'
              }
            }}
          >
            Calculator
          </Button>
          <Button
            color="primary"
            variant={isActive('/compare') ? 'contained' : 'text'}
            onClick={() => navigate('/compare')}
            sx={{
              color: 'white',
              '&:hover': {
                background: 'rgba(255,255,255,0.1)'
              }
            }}
          >
            Compare Funds
          </Button>
          <Button
            color="primary"
            variant={isActive('/monitor') ? 'contained' : 'text'}
            onClick={() => navigate('/monitor')}
            sx={{
              color: 'white',
              '&:hover': {
                background: 'rgba(255,255,255,0.1)'
              }
            }}
          >
            Monitor
          </Button>
          <Button
            color="primary"
            variant={isActive('/headlines') ? 'contained' : 'text'}
            onClick={() => navigate('/headlines')}
            sx={{
              color: 'white',
              '&:hover': {
                background: 'rgba(255,255,255,0.1)'
              }
            }}
          >
            Headlines
          </Button>
          <Button
            color="primary"
            variant={isActive('/faq') ? 'contained' : 'text'}
            onClick={() => navigate('/faq')}
            sx={{
              color: 'white',
              '&:hover': {
                background: 'rgba(255,255,255,0.1)'
              }
            }}
          >
            FAQ
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar; 