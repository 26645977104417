import axios from 'axios';

const YAHOO_API_BASE_URL = 'https://query2.finance.yahoo.com/v8/finance';
const MARKET_DATA_CACHE_TIME = 5 * 60 * 1000; // 5 minutes

// List of Vanguard ETF symbols we want to track
const VANGUARD_ETFS = {
  'VUKE.L': { name: 'Vanguard FTSE 100 UCITS ETF', isin: 'IE00B810Q511' },
  'VWRL.L': { name: 'Vanguard FTSE All-World UCITS ETF', isin: 'IE00B3RBWM25' },
  'VGOV.L': { name: 'Vanguard U.K. Gilt UCITS ETF', isin: 'IE00B42WWV65' },
  'VAGP.L': { name: 'Vanguard Global Aggregate Bond UCITS ETF', isin: 'IE00BG47KH54' },
  'VEVE.L': { name: 'Vanguard FTSE Developed World UCITS ETF', isin: 'IE00BKX55T58' }
};

class MarketDataService {
  constructor() {
    this.cache = {
      funds: null,
      lastUpdated: null
    };
  }

  async getFunds() {
    if (this.cache.funds && this.cache.lastUpdated && 
        (Date.now() - this.cache.lastUpdated) < MARKET_DATA_CACHE_TIME) {
      return this.cache.funds;
    }

    try {
      // Fetch data for all ETFs in parallel
      const symbols = Object.keys(VANGUARD_ETFS).join(',');
      const [quoteResponse, chartResponse] = await Promise.all([
        this.fetchQuotes(symbols),
        this.fetchHistoricalData(symbols)
      ]);
      
      const processedData = this.processFundData(quoteResponse, chartResponse);
      
      this.cache.funds = processedData;
      this.cache.lastUpdated = Date.now();
      
      return processedData;
    } catch (error) {
      console.error('Error fetching fund data:', error);
      throw error;
    }
  }

  async fetchQuotes(symbols) {
    try {
      const response = await axios.get(`${YAHOO_API_BASE_URL}/quote`, {
        params: {
          symbols,
          fields: 'shortName,longName,regularMarketPrice,regularMarketChange,regularMarketChangePercent,fiftyDayAverage,twoHundredDayAverage,regularMarketVolume,averageDailyVolume3Month,marketCap,dividendYield'
        }
      });
      return response.data.quoteResponse.result;
    } catch (error) {
      console.error('Error fetching quotes:', error);
      return [];
    }
  }

  async fetchHistoricalData(symbols) {
    try {
      const response = await axios.get(`${YAHOO_API_BASE_URL}/chart/${symbols}`, {
        params: {
          range: '5y',
          interval: '1mo',
          includePrePost: false,
          events: 'div,split'
        }
      });
      return response.data.chart.result;
    } catch (error) {
      console.error('Error fetching historical data:', error);
      return [];
    }
  }

  processFundData(quotes, historicalData) {
    return quotes.reduce((acc, quote) => {
      const symbol = quote.symbol;
      const fundInfo = VANGUARD_ETFS[symbol];
      if (!fundInfo) return acc;

      const history = historicalData.find(h => h.meta.symbol === symbol);
      const returns = this.calculateReturns(history);
      
      acc[fundInfo.isin] = {
        name: fundInfo.name,
        description: `${fundInfo.name} (${symbol})`,
        category: this.determineCategory(quote),
        assetType: 'ETF',
        historicalReturns: returns,
        riskLevel: this.calculateRiskLevel(returns),
        expenseRatio: 0.22, // Default expense ratio
        dividendYield: quote.dividendYield || 0,
        fundSize: quote.marketCap || 0,
        currency: quote.currency || 'GBP',
        isIsaEligible: true, // All Vanguard ETFs in the list are ISA eligible
        lastUpdated: new Date().toISOString(),
        currentPrice: quote.regularMarketPrice,
        dayChange: quote.regularMarketChangePercent
      };
      return acc;
    }, {});
  }

  calculateReturns(history) {
    if (!history || !history.indicators || !history.indicators.adjclose) {
      return {
        oneYear: 0,
        threeYear: 0,
        fiveYear: 0,
        tenYear: 0
      };
    }

    const prices = history.indicators.adjclose[0].adjclose;
    const current = prices[prices.length - 1];
    const oneYearAgo = prices[Math.max(0, prices.length - 12)] || current;
    const threeYearsAgo = prices[Math.max(0, prices.length - 36)] || current;
    const fiveYearsAgo = prices[Math.max(0, prices.length - 60)] || current;

    return {
      oneYear: (current - oneYearAgo) / oneYearAgo,
      threeYear: (current - threeYearsAgo) / threeYearsAgo,
      fiveYear: (current - fiveYearsAgo) / fiveYearsAgo,
      tenYear: 0 // We only fetch 5 years of data
    };
  }

  calculateRiskLevel(returns) {
    const volatility = Math.std(Object.values(returns));
    if (volatility > 0.2) return 'High';
    if (volatility > 0.1) return 'Moderate';
    return 'Low';
  }

  determineCategory(quote) {
    // Simple category determination based on symbol
    if (symbol.includes('GOV') || symbol.includes('VAGP')) return 'Fixed Income';
    return 'Equity';
  }

  async getMarketIndicators() {
    try {
      const indices = ['^FTSE', '^GSPC', '^IXIC'];
      const response = await this.fetchQuotes(indices.join(','));
      return response;
    } catch (error) {
      console.error('Error fetching market indicators:', error);
      throw error;
    }
  }
}

export const marketDataService = new MarketDataService(); 