export const HISTORICAL_DATA = [
  {
    year: 2023,
    headline: "AI Boom Drives Tech Stock Rally; Nvidia Becomes First $1 Trillion Chip Company",
    popularFund: "Vanguard S&P 500 ETF (VOO)",
    marketEvent: "Tech sector dominance and AI revolution reshape market landscape"
  },
  {
    year: 2022,
    headline: "Inflation Hits 40-Year High; Fed Implements Aggressive Rate Hikes",
    popularFund: "iShares TIPS Bond ETF (TIP)",
    marketEvent: "Global markets face correction amid inflation concerns and rate hikes"
  },
  {
    year: 2021,
    headline: "Meme Stocks and Crypto Surge; GameStop Short Squeeze Makes History",
    popularFund: "ARK Innovation ETF (ARKK)",
    marketEvent: "Retail investors drive unprecedented market movements"
  },
  {
    year: 2020,
    headline: "COVID-19 Pandemic Triggers Market Crash and Swift Recovery",
    popularFund: "Invesco QQQ Trust (QQQ)",
    marketEvent: "Markets experience fastest bear market and recovery in history"
  },
  {
    year: 2019,
    headline: "Trade War Tensions Ease; S&P 500 Gains 29% in Banner Year",
    popularFund: "iShares Core MSCI Emerging Markets ETF (IEMG)",
    marketEvent: "Global markets rally despite trade uncertainties"
  },
  {
    year: 2018,
    headline: "Tech Stocks Lead Market Volatility; FAANG Stocks Dominate Headlines",
    popularFund: "SPDR Gold Shares (GLD)",
    marketEvent: "Market experiences significant volatility and December selloff"
  },
  {
    year: 2017,
    headline: "Bitcoin Surges 1,300%; Dow Breaks 20,000 for First Time",
    popularFund: "Vanguard Total Stock Market ETF (VTI)",
    marketEvent: "Cryptocurrency boom and strong market performance mark the year"
  }
]; 