// Generated from Vanguard fund data - Last updated: 2025-01-11T17:10:56.543Z

export const VANGUARD_FUNDS = {
  "IE00B246KL88": {
    "name": "20+ Year Euro Treasury Index Fund - Euro Shares",
    "description": "20+ Year Euro Treasury Index Fund - Euro Shares - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0512,
      "threeYear": -0.1208,
      "fiveYear": -0.06532,
      "tenYear": -0.0037900000000000004
    }
  },
  "IE00BGCZ0L51": {
    "name": "20+ Year Euro Treasury Index Fund - Institutional Plus Euro Shares",
    "description": "20+ Year Euro Treasury Index Fund - Institutional Plus Euro Shares - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0507,
      "threeYear": -0.12026666666666669,
      "fiveYear": -0.06476000000000001,
      "tenYear": -0.001550000000000004
    }
  },
  "IE000TPX6S78": {
    "name": "Emerging Markets Bond Fund - CHF Hedged Dist",
    "description": "Emerging Markets Bond Fund - CHF Hedged Dist - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.006,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": null,
      "threeYear": 0,
      "fiveYear": 0,
      "tenYear": 0
    }
  },
  "IE00BKLWXS37": {
    "name": "Emerging Markets Bond Fund - EUR Hedged Acc",
    "description": "Emerging Markets Bond Fund - EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.053399999999999996,
      "threeYear": 0.004233333333333329,
      "fiveYear": 0.027659999999999997,
      "tenYear": 0.013829999999999999
    }
  },
  "IE000V13O2Q4": {
    "name": "Emerging Markets Bond Fund - EUR Hedged Dist",
    "description": "Emerging Markets Bond Fund - EUR Hedged Dist - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.006,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.053399999999999996,
      "threeYear": 0.0178,
      "fiveYear": 0.010679999999999999,
      "tenYear": 0.005339999999999999
    }
  },
  "IE00BKLWXP06": {
    "name": "Emerging Markets Bond Fund - GBP Hedged Acc",
    "description": "Emerging Markets Bond Fund - GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.067,
      "threeYear": 0.01726666666666667,
      "fiveYear": 0.038160000000000006,
      "tenYear": 0.019080000000000003
    }
  },
  "IE0002FABL13": {
    "name": "Emerging Markets Bond Fund - GBP Hedged Dist",
    "description": "Emerging Markets Bond Fund - GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.006,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": null,
      "threeYear": 0,
      "fiveYear": 0,
      "tenYear": 0
    }
  },
  "IE000T0AOV55": {
    "name": "Emerging Markets Bond Fund - Institutional CHF Hedged Acc",
    "description": "Emerging Markets Bond Fund - Institutional CHF Hedged Acc - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0055000000000000005,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.027000000000000003,
      "threeYear": 0.03933333333333333,
      "fiveYear": 0.0236,
      "tenYear": 0.0118
    }
  },
  "IE00BNNLXF63": {
    "name": "Emerging Markets Bond Fund - Institutional Plus CHF Hedged Acc",
    "description": "Emerging Markets Bond Fund - Institutional Plus CHF Hedged Acc - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0045000000000000005,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0296,
      "threeYear": -0.011433333333333328,
      "fiveYear": -0.006859999999999996,
      "tenYear": -0.003429999999999998
    }
  },
  "IE00BKLWXV65": {
    "name": "Emerging Markets Bond Fund - Institutional Plus EUR Hedged Acc",
    "description": "Emerging Markets Bond Fund - Institutional Plus EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0045000000000000005,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.055,
      "threeYear": 0.005700000000000001,
      "fiveYear": 0.0034200000000000007,
      "tenYear": 0.0017100000000000004
    }
  },
  "IE00BKLWXR20": {
    "name": "Emerging Markets Bond Fund - Institutional Plus GBP Hedged Acc",
    "description": "Emerging Markets Bond Fund - Institutional Plus GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0045000000000000005,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.06860000000000001,
      "threeYear": 0.06659999999999999,
      "fiveYear": 0.039959999999999996,
      "tenYear": 0.019979999999999998
    }
  },
  "IE00BKLWXN81": {
    "name": "Emerging Markets Bond Fund - Institutional Plus USD Acc",
    "description": "Emerging Markets Bond Fund - Institutional Plus USD Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0045000000000000005,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0729,
      "threeYear": 0.029566666666666668,
      "fiveYear": 0.04973999999999999,
      "tenYear": 0.024869999999999996
    }
  },
  "IE00BKLWXM74": {
    "name": "Emerging Markets Bond Fund - USD Acc",
    "description": "Emerging Markets Bond Fund - USD Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0712,
      "threeYear": 0.027999999999999997,
      "fiveYear": 0.048139999999999995,
      "tenYear": 0.024069999999999998
    }
  },
  "IE0004Q0MM73": {
    "name": "Emerging Markets Bond Fund - USD Dist",
    "description": "Emerging Markets Bond Fund - USD Dist - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.006,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": null,
      "threeYear": 0,
      "fiveYear": 0,
      "tenYear": 0
    }
  },
  "IE000QADMYA3": {
    "name": "ESG EUR Corporate Bond UCITS ETF - (EUR) Accumulating",
    "description": "ESG EUR Corporate Bond UCITS ETF - (EUR) Accumulating - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0011,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.045599999999999995,
      "threeYear": 0.04173333333333334,
      "fiveYear": 0.02504,
      "tenYear": 0.01252
    }
  },
  "IE000F37PGZ3": {
    "name": "ESG EUR Corporate Bond UCITS ETF - (EUR) Distributing",
    "description": "ESG EUR Corporate Bond UCITS ETF - (EUR) Distributing - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0011,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.045599999999999995,
      "threeYear": 0.04173333333333334,
      "fiveYear": 0.02504,
      "tenYear": 0.01252
    }
  },
  "IE000UQLOH05": {
    "name": "ESG EUR Corporate Bond UCITS ETF - GBP Hedged Accumulating",
    "description": "ESG EUR Corporate Bond UCITS ETF - GBP Hedged Accumulating - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0591,
      "threeYear": 0.0511,
      "fiveYear": 0.03066,
      "tenYear": 0.01533
    }
  },
  "IE00094XDHB2": {
    "name": "ESG Global Corporate Bond Index Fund - CHF Hedged Acc",
    "description": "ESG Global Corporate Bond Index Fund - CHF Hedged Acc - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.006999999999999999,
      "threeYear": 0.011000000000000001,
      "fiveYear": 0.0066,
      "tenYear": 0.0033
    }
  },
  "IE00BNDS0V25": {
    "name": "ESG Global Corporate Bond Index Fund - EUR Hedged Acc",
    "description": "ESG Global Corporate Bond Index Fund - EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0199,
      "threeYear": 0.0273,
      "fiveYear": 0.01638,
      "tenYear": 0.00819
    }
  },
  "IE00BNDS1195": {
    "name": "ESG Global Corporate Bond Index Fund - GBP Hedged Acc",
    "description": "ESG Global Corporate Bond Index Fund - GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0335,
      "threeYear": 0.036833333333333336,
      "fiveYear": 0.0221,
      "tenYear": 0.01105
    }
  },
  "IE00BNDS1203": {
    "name": "ESG Global Corporate Bond Index Fund - GBP Hedged Dist",
    "description": "ESG Global Corporate Bond Index Fund - GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0335,
      "threeYear": 0.03676666666666666,
      "fiveYear": 0.02206,
      "tenYear": 0.01103
    }
  },
  "IE00BNDS0X49": {
    "name": "ESG Global Corporate Bond Index Fund - Institutional Plus EUR Hedged Acc",
    "description": "ESG Global Corporate Bond Index Fund - Institutional Plus EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.020499999999999997,
      "threeYear": 0.006833333333333333,
      "fiveYear": 0.0040999999999999995,
      "tenYear": 0.0020499999999999997
    }
  },
  "IE00BNDS1310": {
    "name": "ESG Global Corporate Bond Index Fund - Institutional Plus GBP Hedged Acc",
    "description": "ESG Global Corporate Bond Index Fund - Institutional Plus GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0341,
      "threeYear": 0.037200000000000004,
      "fiveYear": 0.02232,
      "tenYear": 0.01116
    }
  },
  "IE000CI5UY91": {
    "name": "ESG Global Corporate Bond Index Fund - USD Hedged Acc",
    "description": "ESG Global Corporate Bond Index Fund - USD Hedged Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0374,
      "threeYear": 0.04156666666666667,
      "fiveYear": 0.02494,
      "tenYear": 0.01247
    }
  },
  "IE00BNDS1R53": {
    "name": "ESG Global Corporate Bond UCITS ETF - CHF Hedged Accumulating",
    "description": "ESG Global Corporate Bond UCITS ETF - CHF Hedged Accumulating - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0067,
      "threeYear": -0.042366666666666664,
      "fiveYear": -0.025419999999999998,
      "tenYear": -0.012709999999999999
    }
  },
  "IE00BNDS1S60": {
    "name": "ESG Global Corporate Bond UCITS ETF - CHF Hedged Distributing",
    "description": "ESG Global Corporate Bond UCITS ETF - CHF Hedged Distributing - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": -0.0068000000000000005,
      "threeYear": -0.04186666666666666,
      "fiveYear": -0.025119999999999996,
      "tenYear": -0.012559999999999998
    }
  },
  "IE00BNDS1P30": {
    "name": "ESG Global Corporate Bond UCITS ETF - EUR Hedged Accumulating",
    "description": "ESG Global Corporate Bond UCITS ETF - EUR Hedged Accumulating - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.020099999999999996,
      "threeYear": -0.024566666666666667,
      "fiveYear": -0.01474,
      "tenYear": -0.00737
    }
  },
  "IE00BNDS1Q47": {
    "name": "ESG Global Corporate Bond UCITS ETF - EUR Hedged Distributing",
    "description": "ESG Global Corporate Bond UCITS ETF - EUR Hedged Distributing - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.020099999999999996,
      "threeYear": -0.024466666666666668,
      "fiveYear": -0.014680000000000002,
      "tenYear": -0.007340000000000001
    }
  },
  "IE00BNDS1T77": {
    "name": "ESG Global Corporate Bond UCITS ETF - GBP Hedged Accumulating",
    "description": "ESG Global Corporate Bond UCITS ETF - GBP Hedged Accumulating - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0337,
      "threeYear": -0.011966666666666662,
      "fiveYear": -0.007179999999999997,
      "tenYear": -0.0035899999999999986
    }
  },
  "IE00BNDS1V99": {
    "name": "ESG Global Corporate Bond UCITS ETF - GBP Hedged Distributing",
    "description": "ESG Global Corporate Bond UCITS ETF - GBP Hedged Distributing - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0337,
      "threeYear": -0.011733333333333337,
      "fiveYear": -0.007040000000000002,
      "tenYear": -0.003520000000000001
    }
  },
  "IE00BNDS1W07": {
    "name": "ESG Global Corporate Bond UCITS ETF - USD Hedged Accumulating",
    "description": "ESG Global Corporate Bond UCITS ETF - USD Hedged Accumulating - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.037599999999999995,
      "threeYear": -0.002866666666666675,
      "fiveYear": -0.001720000000000005,
      "tenYear": -0.0008600000000000025
    }
  },
  "IE00BNDS1X14": {
    "name": "ESG Global Corporate Bond UCITS ETF - USD Hedged Distributing",
    "description": "ESG Global Corporate Bond UCITS ETF - USD Hedged Distributing - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.037599999999999995,
      "threeYear": -0.0029000000000000046,
      "fiveYear": -0.0017400000000000026,
      "tenYear": -0.0008700000000000013
    }
  },
  "IE000JQV8511": {
    "name": "ESG USD Corporate Bond UCITS ETF - (USD) Accumulating",
    "description": "ESG USD Corporate Bond UCITS ETF - (USD) Accumulating - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0011,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0225,
      "threeYear": 0.035899999999999994,
      "fiveYear": 0.021539999999999997,
      "tenYear": 0.010769999999999998
    }
  },
  "IE000RO1O3N4": {
    "name": "ESG USD Corporate Bond UCITS ETF - (USD) Distributing",
    "description": "ESG USD Corporate Bond UCITS ETF - (USD) Distributing - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0011,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0225,
      "threeYear": 0.035899999999999994,
      "fiveYear": 0.021539999999999997,
      "tenYear": 0.010769999999999998
    }
  },
  "IE000EKJRSZ3": {
    "name": "ESG USD Corporate Bond UCITS ETF - EUR Hedged Accumulating",
    "description": "ESG USD Corporate Bond UCITS ETF - EUR Hedged Accumulating - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0039000000000000003,
      "threeYear": 0.020633333333333333,
      "fiveYear": 0.012379999999999999,
      "tenYear": 0.006189999999999999
    }
  },
  "IE0000MOWF88": {
    "name": "ESG USD Corporate Bond UCITS ETF - GBP Hedged Accumulating",
    "description": "ESG USD Corporate Bond UCITS ETF - GBP Hedged Accumulating - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0173,
      "threeYear": 0.0301,
      "fiveYear": 0.01806,
      "tenYear": 0.00903
    }
  },
  "IE00BGYWT403": {
    "name": "EUR Corporate Bond UCITS ETF - (EUR) Accumulating",
    "description": "EUR Corporate Bond UCITS ETF - (EUR) Accumulating - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.045899999999999996,
      "threeYear": -0.003766666666666668,
      "fiveYear": 0.0007599999999999996,
      "tenYear": 0.0003799999999999998
    }
  },
  "IE00BZ163G84": {
    "name": "EUR Corporate Bond UCITS ETF - (EUR) Distributing",
    "description": "EUR Corporate Bond UCITS ETF - (EUR) Distributing - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.045899999999999996,
      "threeYear": -0.003766666666666668,
      "fiveYear": 0.0007599999999999996,
      "tenYear": 0.00771
    }
  },
  "IE00BH04GL39": {
    "name": "EUR Eurozone Government Bond UCITS ETF - (EUR) Accumulating",
    "description": "EUR Eurozone Government Bond UCITS ETF - (EUR) Accumulating - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0007000000000000001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0177,
      "threeYear": -0.031766666666666665,
      "fiveYear": -0.016279999999999996,
      "tenYear": -0.008139999999999998
    }
  },
  "IE00BZ163H91": {
    "name": "EUR Eurozone Government Bond UCITS ETF - (EUR) Distributing",
    "description": "EUR Eurozone Government Bond UCITS ETF - (EUR) Distributing - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0007000000000000001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0177,
      "threeYear": -0.031733333333333336,
      "fiveYear": -0.01626,
      "tenYear": -0.00046999999999999934
    }
  },
  "IE000P959QJ6": {
    "name": "Euro Government Bond Index Fund - CHF Hedged Acc",
    "description": "Euro Government Bond Index Fund - CHF Hedged Acc - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0088,
      "threeYear": 0.013166666666666667,
      "fiveYear": 0.0079,
      "tenYear": 0.00395
    }
  },
  "IE0007472990": {
    "name": "Euro Government Bond Index Fund - EUR Acc",
    "description": "Euro Government Bond Index Fund - EUR Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.018000000000000002,
      "threeYear": -0.03143333333333334,
      "fiveYear": -0.016520000000000007,
      "tenYear": 0.0029599999999999965
    }
  },
  "IE00BFRTD722": {
    "name": "Euro Government Bond Index Fund - GBP Hedged Acc",
    "description": "Euro Government Bond Index Fund - GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0317,
      "threeYear": -0.019133333333333336,
      "fiveYear": -0.006420000000000002,
      "tenYear": 0.01261
    }
  },
  "IE00BFPM9W02": {
    "name": "Euro Government Bond Index Fund - Institutional Plus EUR Acc",
    "description": "Euro Government Bond Index Fund - Institutional Plus EUR Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.018600000000000002,
      "threeYear": -0.030833333333333338,
      "fiveYear": -0.015920000000000007,
      "tenYear": 0.003759999999999996
    }
  },
  "IE00BK6S3N13": {
    "name": "Euro Government Bond Index Fund - Institutional Plus GBP Hedged Acc",
    "description": "Euro Government Bond Index Fund - Institutional Plus GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.032400000000000005,
      "threeYear": 0.010800000000000002,
      "fiveYear": 0.006480000000000001,
      "tenYear": 0.0032400000000000007
    }
  },
  "IE0007472115": {
    "name": "Euro Government Bond Index Fund - Investor EUR Acc",
    "description": "Euro Government Bond Index Fund - Investor EUR Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.018000000000000002,
      "threeYear": -0.03143333333333334,
      "fiveYear": -0.016520000000000007,
      "tenYear": 0.0027099999999999963
    }
  },
  "IE0032125126": {
    "name": "Euro Government Bond Index Fund - USD Acc",
    "description": "Euro Government Bond Index Fund - USD Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.045700000000000005,
      "threeYear": -0.056900000000000006,
      "fiveYear": -0.02658000000000001,
      "tenYear": 0.0007099999999999951
    }
  },
  "IE00B04FFJ44": {
    "name": "Euro Investment Grade Bond Index Fund - EUR Acc",
    "description": "Euro Investment Grade Bond Index Fund - EUR Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0381,
      "threeYear": -0.013500000000000003,
      "fiveYear": -0.006340000000000003,
      "tenYear": 0.0060999999999999995
    }
  },
  "IE00BFRTD839": {
    "name": "Euro Investment Grade Bond Index Fund - GBP Hedged Acc",
    "description": "Euro Investment Grade Bond Index Fund - GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.052199999999999996,
      "threeYear": -0.0006666666666666673,
      "fiveYear": 0.0038,
      "tenYear": 0.015650000000000004
    }
  },
  "IE00BFPM9X19": {
    "name": "Euro Investment Grade Bond Index Fund - Institutional Plus EUR Acc",
    "description": "Euro Investment Grade Bond Index Fund - Institutional Plus EUR Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.038599999999999995,
      "threeYear": -0.013066666666666671,
      "fiveYear": -0.0059200000000000025,
      "tenYear": 0.00682
    }
  },
  "IE0009591805": {
    "name": "Euro Investment Grade Bond Index Fund - Investor EUR Acc",
    "description": "Euro Investment Grade Bond Index Fund - Investor EUR Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0381,
      "threeYear": -0.013500000000000003,
      "fiveYear": -0.006340000000000003,
      "tenYear": 0.005859999999999999
    }
  },
  "IE00B04GQR24": {
    "name": "Eurozone Inflation-Linked Bond Index Fund - EUR Acc",
    "description": "Eurozone Inflation-Linked Bond Index Fund - EUR Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0013,
      "threeYear": -0.0128,
      "fiveYear": 0.012640000000000002,
      "tenYear": 0.01898
    }
  },
  "IE00BGCZ0719": {
    "name": "Eurozone Inflation-Linked Bond Index Fund - Institutional Plus EUR Acc",
    "description": "Eurozone Inflation-Linked Bond Index Fund - Institutional Plus EUR Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0019,
      "threeYear": -0.012200000000000003,
      "fiveYear": 0.013239999999999997,
      "tenYear": 0.01978
    }
  },
  "IE000E69G7V7": {
    "name": "Eurozone Inflation-Linked Bond Index Fund - Institutional Plus EUR Dist",
    "description": "Eurozone Inflation-Linked Bond Index Fund - Institutional Plus EUR Dist - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0019,
      "threeYear": 0.022033333333333332,
      "fiveYear": 0.013219999999999999,
      "tenYear": 0.0066099999999999996
    }
  },
  "IE00B04GQQ17": {
    "name": "Eurozone Inflation-Linked Bond Index Fund - Investor EUR Acc",
    "description": "Eurozone Inflation-Linked Bond Index Fund - Investor EUR Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0013,
      "threeYear": -0.0128,
      "fiveYear": 0.012640000000000002,
      "tenYear": 0.01873
    }
  },
  "IE00BG47KF31": {
    "name": "Global Aggregate Bond UCITS ETF - CHF Hedged Accumulating",
    "description": "Global Aggregate Bond UCITS ETF - CHF Hedged Accumulating - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.018000000000000002,
      "threeYear": -0.0489,
      "fiveYear": -0.029339999999999998,
      "tenYear": -0.014669999999999999
    }
  },
  "IE00BG47KH54": {
    "name": "Global Aggregate Bond UCITS ETF - EUR Hedged Accumulating",
    "description": "Global Aggregate Bond UCITS ETF - EUR Hedged Accumulating - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0086,
      "threeYear": -0.03133333333333333,
      "fiveYear": -0.014639999999999997,
      "tenYear": -0.007319999999999998
    }
  },
  "IE00BG47KB92": {
    "name": "Global Aggregate Bond UCITS ETF - EUR Hedged Distributing",
    "description": "Global Aggregate Bond UCITS ETF - EUR Hedged Distributing - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0087,
      "threeYear": -0.031133333333333336,
      "fiveYear": -0.014480000000000002,
      "tenYear": -0.007240000000000001
    }
  },
  "IE00BG47K971": {
    "name": "Global Aggregate Bond UCITS ETF - GBP Hedged Accumulating",
    "description": "Global Aggregate Bond UCITS ETF - GBP Hedged Accumulating - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0222,
      "threeYear": -0.018566666666666672,
      "fiveYear": -0.004060000000000003,
      "tenYear": -0.0020300000000000014
    }
  },
  "IE00BG47KG48": {
    "name": "Global Aggregate Bond UCITS ETF - GBP Hedged Distributing",
    "description": "Global Aggregate Bond UCITS ETF - GBP Hedged Distributing - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.022099999999999998,
      "threeYear": -0.018466666666666662,
      "fiveYear": -0.0040599999999999985,
      "tenYear": -0.0020299999999999993
    }
  },
  "IE00BG47KJ78": {
    "name": "Global Aggregate Bond UCITS ETF - USD Hedged Accumulating",
    "description": "Global Aggregate Bond UCITS ETF - USD Hedged Accumulating - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0258,
      "threeYear": -0.010633333333333328,
      "fiveYear": 0.002180000000000004,
      "tenYear": 0.001090000000000002
    }
  },
  "IE00B2RHVR18": {
    "name": "Global Bond Index Fund - CHF Hedged Acc",
    "description": "Global Bond Index Fund - CHF Hedged Acc - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0182,
      "threeYear": -0.049300000000000004,
      "fiveYear": -0.026879999999999998,
      "tenYear": -0.008770000000000002
    }
  },
  "IE00BN6QDG94": {
    "name": "Global Bond Index Fund - CHF Hedged Dist",
    "description": "Global Bond Index Fund - CHF Hedged Dist - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": -0.0183,
      "threeYear": -0.04933333333333334,
      "fiveYear": -0.029600000000000005,
      "tenYear": -0.014800000000000002
    }
  },
  "IE00B18GC888": {
    "name": "Global Bond Index Fund - EUR Hedged Acc",
    "description": "Global Bond Index Fund - EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0083,
      "threeYear": -0.0318,
      "fiveYear": -0.015140000000000004,
      "tenYear": 0.0006299999999999981
    }
  },
  "IE00BN6QDH02": {
    "name": "Global Bond Index Fund - EUR Hedged Dist",
    "description": "Global Bond Index Fund - EUR Hedged Dist - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0083,
      "threeYear": -0.0318,
      "fiveYear": -0.019080000000000003,
      "tenYear": -0.009540000000000002
    }
  },
  "IE00B50W2R13": {
    "name": "Global Bond Index Fund - GBP Hedged Acc",
    "description": "Global Bond Index Fund - GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0219,
      "threeYear": -0.019033333333333333,
      "fiveYear": -0.004640000000000001,
      "tenYear": 0.01096
    }
  },
  "IE00B2RHVP93": {
    "name": "Global Bond Index Fund - GBP Hedged Dist",
    "description": "Global Bond Index Fund - GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0219,
      "threeYear": -0.019033333333333333,
      "fiveYear": -0.004640000000000001,
      "tenYear": 0.01096
    }
  },
  "IE00BGCZ0C60": {
    "name": "Global Bond Index Fund - Institutional Plus CHF Hedged Acc",
    "description": "Global Bond Index Fund - Institutional Plus CHF Hedged Acc - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0178,
      "threeYear": -0.0489,
      "fiveYear": -0.02646,
      "tenYear": -0.008390000000000002
    }
  },
  "IE00BGCZ0B53": {
    "name": "Global Bond Index Fund - Institutional Plus EUR Hedged Acc",
    "description": "Global Bond Index Fund - Institutional Plus EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0088,
      "threeYear": -0.03136666666666666,
      "fiveYear": -0.014679999999999999,
      "tenYear": 0.0010800000000000004
    }
  },
  "IE00BPT2BQ43": {
    "name": "Global Bond Index Fund - Institutional Plus GBP Hedged Acc",
    "description": "Global Bond Index Fund - Institutional Plus GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0223,
      "threeYear": -0.018566666666666665,
      "fiveYear": -0.004140000000000002,
      "tenYear": 0.011489999999999998
    }
  },
  "IE00BPT2BP36": {
    "name": "Global Bond Index Fund - Institutional Plus GBP Hedged Dist",
    "description": "Global Bond Index Fund - Institutional Plus GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.022400000000000003,
      "threeYear": -0.018599999999999995,
      "fiveYear": -0.004179999999999999,
      "tenYear": 0.01147
    }
  },
  "IE00BGCZ0D77": {
    "name": "Global Bond Index Fund - Institutional Plus JPY Hedged Acc",
    "description": "Global Bond Index Fund - Institutional Plus JPY Hedged Acc - A fixed income fund denominated in JPY.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0327,
      "threeYear": -0.0595,
      "fiveYear": -0.030359999999999998,
      "tenYear": -0.009359999999999997
    }
  },
  "IE00BFPM9Y26": {
    "name": "Global Bond Index Fund - Institutional Plus USD Hedged Acc",
    "description": "Global Bond Index Fund - Institutional Plus USD Hedged Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.026000000000000002,
      "threeYear": -0.010633333333333337,
      "fiveYear": 0.0020599999999999967,
      "tenYear": 0.017949999999999997
    }
  },
  "IE00BFXZJ096": {
    "name": "Global Bond Index Fund - Institutional Plus USD Hedged Dist",
    "description": "Global Bond Index Fund - Institutional Plus USD Hedged Dist - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.026000000000000002,
      "threeYear": -0.010633333333333337,
      "fiveYear": 0.0020599999999999967,
      "tenYear": 0.009449999999999997
    }
  },
  "IE00BGCZ0826": {
    "name": "Global Bond Index Fund - Investor USD Hedged Acc",
    "description": "Global Bond Index Fund - Investor USD Hedged Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0255,
      "threeYear": -0.011100000000000004,
      "fiveYear": 0.0015799999999999994,
      "tenYear": 0.01729
    }
  },
  "IE00B18GCB14": {
    "name": "Global Bond Index Fund - USD Hedged Acc",
    "description": "Global Bond Index Fund - USD Hedged Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0255,
      "threeYear": -0.011100000000000004,
      "fiveYear": 0.0015799999999999994,
      "tenYear": 0.018369999999999997
    }
  },
  "IE00BN6QDF87": {
    "name": "Global Bond Index Fund - USD Hedged Dist",
    "description": "Global Bond Index Fund - USD Hedged Dist - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0255,
      "threeYear": -0.011100000000000004,
      "fiveYear": -0.006660000000000002,
      "tenYear": -0.003330000000000001
    }
  },
  "IE00BDFB5P87": {
    "name": "Global Corporate Bond Index Fund - CHF Hedged Acc",
    "description": "Global Corporate Bond Index Fund - CHF Hedged Acc - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0075,
      "threeYear": -0.04466666666666667,
      "fiveYear": -0.0268,
      "tenYear": -0.0134
    }
  },
  "IE00BDFB5N63": {
    "name": "Global Corporate Bond Index Fund - EUR Hedged Acc",
    "description": "Global Corporate Bond Index Fund - EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0194,
      "threeYear": -0.026900000000000004,
      "fiveYear": -0.01614,
      "tenYear": -0.00807
    }
  },
  "IE00BDFB5M56": {
    "name": "Global Corporate Bond Index Fund - GBP Hedged Acc",
    "description": "Global Corporate Bond Index Fund - GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.033,
      "threeYear": -0.014233333333333329,
      "fiveYear": 0.0023200000000000026,
      "tenYear": 0.00884
    }
  },
  "IE00BDFB5K33": {
    "name": "Global Corporate Bond Index Fund - GBP Hedged Dist",
    "description": "Global Corporate Bond Index Fund - GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0334,
      "threeYear": -0.013833333333333342,
      "fiveYear": -0.008300000000000005,
      "tenYear": -0.004150000000000003
    }
  },
  "IE00BDFB5F89": {
    "name": "Global Corporate Bond Index Fund - Institutional Plus EUR Hedged Acc",
    "description": "Global Corporate Bond Index Fund - Institutional Plus EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.02,
      "threeYear": -0.026266666666666674,
      "fiveYear": -0.006100000000000006,
      "tenYear": 0.005989999999999997
    }
  },
  "IE00BDFB5D65": {
    "name": "Global Corporate Bond Index Fund - Institutional Plus GBP Hedged Acc",
    "description": "Global Corporate Bond Index Fund - Institutional Plus GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0337,
      "threeYear": -0.013533333333333333,
      "fiveYear": 0.0029200000000000003,
      "tenYear": 0.009420000000000001
    }
  },
  "IE000MRSHTF3": {
    "name": "Global Corporate Bond Index Fund - Institutional Plus GBP Hedged Dist",
    "description": "Global Corporate Bond Index Fund - Institutional Plus GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0337,
      "threeYear": 0.03803333333333333,
      "fiveYear": 0.022819999999999997,
      "tenYear": 0.011409999999999998
    }
  },
  "IE00BDFB5T26": {
    "name": "Global Corporate Bond Index Fund - USD Hedged Acc",
    "description": "Global Corporate Bond Index Fund - USD Hedged Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0368,
      "threeYear": -0.005333333333333338,
      "fiveYear": 0.011079999999999996,
      "tenYear": 0.0168
    }
  },
  "IE00BYV1RG46": {
    "name": "Global Credit Bond Fund - GBP Hedged Acc",
    "description": "Global Credit Bond Fund - GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0034999999999999996,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0378,
      "threeYear": -0.008199999999999999,
      "fiveYear": 0.016540000000000003,
      "tenYear": 0.01776
    }
  },
  "IE00BYV1RH52": {
    "name": "Global Credit Bond Fund - GBP Hedged Dist",
    "description": "Global Credit Bond Fund - GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0034999999999999996,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0381,
      "threeYear": -0.008066666666666666,
      "fiveYear": 0.016460000000000002,
      "tenYear": 0.008230000000000001
    }
  },
  "IE00BJHGQZ02": {
    "name": "Global Credit Bond Fund - Institutional AUD Hedged Acc",
    "description": "Global Credit Bond Fund - Institutional AUD Hedged Acc - A fixed income fund denominated in AUD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.003,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.029900000000000003,
      "threeYear": -0.013566666666666663,
      "fiveYear": 0.012940000000000004,
      "tenYear": 0.006470000000000002
    }
  },
  "IE0007RE1E54": {
    "name": "Global Credit Bond Fund - Institutional CHF Hedged Acc",
    "description": "Global Credit Bond Fund - Institutional CHF Hedged Acc - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.003,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.002,
      "threeYear": 0.014133333333333333,
      "fiveYear": 0.00848,
      "tenYear": 0.00424
    }
  },
  "IE00BF7MPP31": {
    "name": "Global Credit Bond Fund - Institutional EUR Hedged Acc",
    "description": "Global Credit Bond Fund - Institutional EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.003,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0248,
      "threeYear": -0.0202,
      "fiveYear": 0.006880000000000002,
      "tenYear": 0.01059
    }
  },
  "IE000STP7RR0": {
    "name": "Global Credit Bond Fund - Institutional EUR Hedged Dist",
    "description": "Global Credit Bond Fund - Institutional EUR Hedged Dist - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.003,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0248,
      "threeYear": 0.008266666666666667,
      "fiveYear": 0.00496,
      "tenYear": 0.00248
    }
  },
  "IE0007XHW766": {
    "name": "Global Credit Bond Fund - Institutional GBP Hedged Acc",
    "description": "Global Credit Bond Fund - Institutional GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.003,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0384,
      "threeYear": 0.04,
      "fiveYear": 0.024,
      "tenYear": 0.012
    }
  },
  "IE00BF7MPR54": {
    "name": "Global Credit Bond Fund - Institutional GBP Hedged Dist",
    "description": "Global Credit Bond Fund - Institutional GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.003,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0384,
      "threeYear": 0.012799999999999999,
      "fiveYear": 0.007679999999999999,
      "tenYear": 0.0038399999999999997
    }
  },
  "IE00BF7MPZ39": {
    "name": "Global Credit Bond Fund - Institutional Plus GBP Hedged Acc",
    "description": "Global Credit Bond Fund - Institutional Plus GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": null,
      "threeYear": 0,
      "fiveYear": 0,
      "tenYear": 0
    }
  },
  "IE00BF7MQ610": {
    "name": "Global Credit Bond Fund - Institutional Plus GBP Hedged Dist",
    "description": "Global Credit Bond Fund - Institutional Plus GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": null,
      "threeYear": 0,
      "fiveYear": 0,
      "tenYear": 0
    }
  },
  "IE00BF7MPN17": {
    "name": "Global Credit Bond Fund - Institutional USD Hedged Acc",
    "description": "Global Credit Bond Fund - Institutional USD Hedged Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.003,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.042,
      "threeYear": 0.0011666666666666676,
      "fiveYear": 0.0247,
      "tenYear": 0.02545
    }
  },
  "IE0002TSINW6": {
    "name": "Global Credit Bond Fund - Institutional USD Hedged Dist",
    "description": "Global Credit Bond Fund - Institutional USD Hedged Dist - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.003,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": null,
      "threeYear": 0,
      "fiveYear": 0,
      "tenYear": 0
    }
  },
  "IE00BYV1RD15": {
    "name": "Global Credit Bond Fund - USD Hedged Acc",
    "description": "Global Credit Bond Fund - USD Hedged Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0034999999999999996,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0415,
      "threeYear": 0.0006666666666666765,
      "fiveYear": 0.024180000000000007,
      "tenYear": 0.02508
    }
  },
  "IE00BH65QQ53": {
    "name": "Global Short-Term Bond Index Fund - CHF Hedged Acc",
    "description": "Global Short-Term Bond Index Fund - CHF Hedged Acc - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0005,
      "threeYear": -0.01993333333333333,
      "fiveYear": -0.012,
      "tenYear": -0.00784
    }
  },
  "IE00BH65QP47": {
    "name": "Global Short-Term Bond Index Fund - EUR Hedged Acc",
    "description": "Global Short-Term Bond Index Fund - EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0269,
      "threeYear": -0.0025333333333333367,
      "fiveYear": -0.0005400000000000016,
      "tenYear": 0.0011699999999999992
    }
  },
  "IE000ZIEFDC9": {
    "name": "Global Short-Term Bond Index Fund - EUR Hedged Dist",
    "description": "Global Short-Term Bond Index Fund - EUR Hedged Dist - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0269,
      "threeYear": 0.008966666666666666,
      "fiveYear": 0.00538,
      "tenYear": 0.00269
    }
  },
  "IE00BH65QG55": {
    "name": "Global Short-Term Bond Index Fund - GBP Hedged Acc",
    "description": "Global Short-Term Bond Index Fund - GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0409,
      "threeYear": 0.011333333333333334,
      "fiveYear": 0.01064,
      "tenYear": 0.01159
    }
  },
  "IE00BH65QH62": {
    "name": "Global Short-Term Bond Index Fund - GBP Hedged Dist",
    "description": "Global Short-Term Bond Index Fund - GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0409,
      "threeYear": 0.011333333333333334,
      "fiveYear": 0.01064,
      "tenYear": 0.011600000000000001
    }
  },
  "IE00BH65QV07": {
    "name": "Global Short-Term Bond Index Fund - Institutional Plus CHF Hedged Acc",
    "description": "Global Short-Term Bond Index Fund - Institutional Plus CHF Hedged Acc - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0015,
      "threeYear": -0.01933333333333333,
      "fiveYear": -0.011459999999999998,
      "tenYear": -0.00717
    }
  },
  "IE00BH65QT84": {
    "name": "Global Short-Term Bond Index Fund - Institutional Plus EUR Hedged Acc",
    "description": "Global Short-Term Bond Index Fund - Institutional Plus EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0274,
      "threeYear": -0.002066666666666666,
      "fiveYear": -0.000059999999999999636,
      "tenYear": 0.00165
    }
  },
  "IE00BPT2BZ34": {
    "name": "Global Short-Term Bond Index Fund - Institutional Plus GBP Hedged Acc",
    "description": "Global Short-Term Bond Index Fund - Institutional Plus GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0415,
      "threeYear": 0.011866666666666666,
      "fiveYear": 0.01116,
      "tenYear": 0.012120000000000002
    }
  },
  "IE00BPT2BY27": {
    "name": "Global Short-Term Bond Index Fund - Institutional Plus GBP Hedged Dist",
    "description": "Global Short-Term Bond Index Fund - Institutional Plus GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0414,
      "threeYear": 0.0118,
      "fiveYear": 0.01112,
      "tenYear": 0.012170000000000002
    }
  },
  "IE00BH65QS77": {
    "name": "Global Short-Term Bond Index Fund - Institutional Plus USD Hedged Acc",
    "description": "Global Short-Term Bond Index Fund - Institutional Plus USD Hedged Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.044199999999999996,
      "threeYear": 0.017366666666666666,
      "fiveYear": 0.015860000000000003,
      "tenYear": 0.017860000000000004
    }
  },
  "IE00BH65QK91": {
    "name": "Global Short-Term Bond Index Fund - Investor EUR Hedged Acc",
    "description": "Global Short-Term Bond Index Fund - Investor EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0269,
      "threeYear": -0.0025333333333333367,
      "fiveYear": -0.0005400000000000016,
      "tenYear": 0.0009499999999999995
    }
  },
  "IE00BH65QN23": {
    "name": "Global Short-Term Bond Index Fund - USD Hedged Acc",
    "description": "Global Short-Term Bond Index Fund - USD Hedged Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0437,
      "threeYear": 0.016866666666666665,
      "fiveYear": 0.015360000000000002,
      "tenYear": 0.018510000000000002
    }
  },
  "IE00BN71SS04": {
    "name": "Global Short-Term Bond Index Fund - USD Hedged Dist",
    "description": "Global Short-Term Bond Index Fund - USD Hedged Dist - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0437,
      "threeYear": 0.016866666666666665,
      "fiveYear": 0.00868,
      "tenYear": 0.00434
    }
  },
  "IE00BDFB7290": {
    "name": "Global Short-Term Corporate Bond Index Fund - EUR Hedged Acc",
    "description": "Global Short-Term Corporate Bond Index Fund - EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0385,
      "threeYear": 0.002233333333333337,
      "fiveYear": 0.004820000000000002,
      "tenYear": 0.002410000000000001
    }
  },
  "IE00BDFB7639": {
    "name": "Global Short-Term Corporate Bond Index Fund - GBP Hedged Acc",
    "description": "Global Short-Term Corporate Bond Index Fund - GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0524,
      "threeYear": 0.015866666666666668,
      "fiveYear": 0.01484,
      "tenYear": 0.01155
    }
  },
  "IE00BDFB6X37": {
    "name": "Global Short-Term Corporate Bond Index Fund - Institutional Plus EUR Hedged Acc",
    "description": "Global Short-Term Corporate Bond Index Fund - Institutional Plus EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.039,
      "threeYear": 0.0027666666666666673,
      "fiveYear": 0.005340000000000001,
      "tenYear": 0.0059700000000000005
    }
  },
  "IE00BDFB6W20": {
    "name": "Global Short-Term Corporate Bond Index Fund - Institutional Plus GBP Hedged Acc",
    "description": "Global Short-Term Corporate Bond Index Fund - Institutional Plus GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.053099999999999994,
      "threeYear": 0.0166,
      "fiveYear": 0.015339999999999998,
      "tenYear": 0.012079999999999999
    }
  },
  "IE00BDFB7308": {
    "name": "Global Short-Term Corporate Bond Index Fund - USD Hedged Acc",
    "description": "Global Short-Term Corporate Bond Index Fund - USD Hedged Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0553,
      "threeYear": 0.022000000000000002,
      "fiveYear": 0.02114,
      "tenYear": 0.018189999999999998
    }
  },
  "IE00BLPJRH48": {
    "name": "Japan Government Bond Index Fund - EUR Hedged Acc",
    "description": "Japan Government Bond Index Fund - EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0036,
      "threeYear": -0.011066666666666667,
      "fiveYear": -0.00664,
      "tenYear": -0.00332
    }
  },
  "IE00BFRTD946": {
    "name": "Japan Government Bond Index Fund - GBP Hedged Acc",
    "description": "Japan Government Bond Index Fund - GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0108,
      "threeYear": 0.0028333333333333288,
      "fiveYear": 0.0004799999999999971,
      "tenYear": 0.011539999999999998
    }
  },
  "IE00BJDQNK87": {
    "name": "Japan Government Bond Index Fund - Institutional Plus EUR Hedged Acc",
    "description": "Japan Government Bond Index Fund - Institutional Plus EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0029,
      "threeYear": -0.010566666666666667,
      "fiveYear": -0.01012,
      "tenYear": -0.00506
    }
  },
  "IE00BJ06F500": {
    "name": "Japan Government Bond Index Fund - Institutional Plus GBP Hedged Dist",
    "description": "Japan Government Bond Index Fund - Institutional Plus GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.011399999999999999,
      "threeYear": 0.0033666666666666636,
      "fiveYear": 0.001019999999999998,
      "tenYear": 0.000509999999999999
    }
  },
  "IE00BGCZ0H16": {
    "name": "Japan Government Bond Index Fund - Institutional Plus JPY Acc",
    "description": "Japan Government Bond Index Fund - Institutional Plus JPY Acc - A fixed income fund denominated in JPY.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0416,
      "threeYear": -0.036066666666666664,
      "fiveYear": -0.023999999999999997,
      "tenYear": -0.004759999999999999
    }
  },
  "IE00BJ06F617": {
    "name": "Japan Government Bond Index Fund - Institutional Plus USD Hedged Acc",
    "description": "Japan Government Bond Index Fund - Institutional Plus USD Hedged Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.013300000000000001,
      "threeYear": 0.008166666666666668,
      "fiveYear": 0.005200000000000001,
      "tenYear": 0.0026000000000000003
    }
  },
  "IE0033005699": {
    "name": "Japan Government Bond Index Fund - JPY Acc",
    "description": "Japan Government Bond Index Fund - JPY Acc - A fixed income fund denominated in JPY.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.042199999999999994,
      "threeYear": -0.03663333333333333,
      "fiveYear": -0.024579999999999998,
      "tenYear": -0.00548
    }
  },
  "IE0032915443": {
    "name": "Japan Government Bond Index Fund - USD Acc",
    "description": "Japan Government Bond Index Fund - USD Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.1408,
      "threeYear": -0.13033333333333333,
      "fiveYear": -0.09102,
      "tenYear": -0.029200000000000004
    }
  },
  "IE00BYSX5D68": {
    "name": "SRI Euro Investment Grade Bond Index Fund - EUR Acc",
    "description": "SRI Euro Investment Grade Bond Index Fund - EUR Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.037200000000000004,
      "threeYear": -0.014066666666666663,
      "fiveYear": -0.006899999999999998,
      "tenYear": 0.0009900000000000013
    }
  },
  "IE00BYSX5L43": {
    "name": "SRI Euro Investment Grade Bond Index Fund - Institutional Plus EUR Acc",
    "description": "SRI Euro Investment Grade Bond Index Fund - Institutional Plus EUR Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.037599999999999995,
      "threeYear": -0.013700000000000004,
      "fiveYear": -0.006520000000000003,
      "tenYear": 0.0013899999999999982
    }
  },
  "IE00BH04GW44": {
    "name": "U.K. Gilt UCITS ETF - (GBP) Accumulating",
    "description": "U.K. Gilt UCITS ETF - (GBP) Accumulating - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0007000000000000001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.044000000000000004,
      "threeYear": -0.0936,
      "fiveYear": -0.04802,
      "tenYear": -0.02401
    }
  },
  "IE00B42WWV65": {
    "name": "U.K. Gilt UCITS ETF - (GBP) Distributing",
    "description": "U.K. Gilt UCITS ETF - (GBP) Distributing - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0007000000000000001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": -0.044000000000000004,
      "threeYear": -0.0936,
      "fiveYear": -0.04802,
      "tenYear": -0.0031000000000000025
    }
  },
  "IE00BMX0B524": {
    "name": "U.K. Gilt UCITS ETF - EUR Hedged Accumulating",
    "description": "U.K. Gilt UCITS ETF - EUR Hedged Accumulating - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0575,
      "threeYear": -0.1053,
      "fiveYear": -0.07578,
      "tenYear": -0.03789
    }
  },
  "IE00BLPJRG31": {
    "name": "U.K. Government Bond Index Fund - EUR Hedged Acc",
    "description": "U.K. Government Bond Index Fund - EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.057300000000000004,
      "threeYear": -0.10516666666666667,
      "fiveYear": -0.0631,
      "tenYear": -0.03155
    }
  },
  "IE00B1S75374": {
    "name": "U.K. Government Bond Index Fund - GBP Acc",
    "description": "U.K. Government Bond Index Fund - GBP Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0443,
      "threeYear": -0.09386666666666665,
      "fiveYear": -0.04847999999999999,
      "tenYear": -0.003439999999999995
    }
  },
  "IE00B1S75820": {
    "name": "U.K. Government Bond Index Fund - GBP Dist",
    "description": "U.K. Government Bond Index Fund - GBP Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": -0.0443,
      "threeYear": -0.09386666666666665,
      "fiveYear": -0.04849999999999999,
      "tenYear": -0.0034499999999999956
    }
  },
  "IE00BPT2BS66": {
    "name": "U.K. Government Bond Index Fund - Institutional Plus GBP Acc",
    "description": "U.K. Government Bond Index Fund - Institutional Plus GBP Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0437,
      "threeYear": -0.09326666666666666,
      "fiveYear": -0.04788,
      "tenYear": -0.002700000000000002
    }
  },
  "IE00BPT2BR59": {
    "name": "U.K. Government Bond Index Fund - Institutional Plus GBP Dist",
    "description": "U.K. Government Bond Index Fund - Institutional Plus GBP Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": -0.0437,
      "threeYear": -0.09326666666666666,
      "fiveYear": -0.04788,
      "tenYear": -0.002700000000000002
    }
  },
  "GB00B45Q9038": {
    "name": "U.K. Inflation-Linked Gilt Index Fund - GBP Acc",
    "description": "U.K. Inflation-Linked Gilt Index Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.087,
      "threeYear": -0.14216666666666666,
      "fiveYear": -0.055200000000000006,
      "tenYear": 0.004329999999999996
    }
  },
  "GB00B467FS56": {
    "name": "U.K. Inflation-Linked Gilt Index Fund - GBP Inc",
    "description": "U.K. Inflation-Linked Gilt Index Fund - GBP Inc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": -0.087,
      "threeYear": -0.14216666666666666,
      "fiveYear": -0.055200000000000006,
      "tenYear": 0.004339999999999996
    }
  },
  "GB00BPN5P451": {
    "name": "U.K. Inflation-Linked Gilt Index Fund - Institutional Plus GBP Acc",
    "description": "U.K. Inflation-Linked Gilt Index Fund - Institutional Plus GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.08650000000000001,
      "threeYear": -0.14166666666666666,
      "fiveYear": -0.05469999999999999,
      "tenYear": 0.0050300000000000015
    }
  },
  "GB00BPN5P345": {
    "name": "U.K. Inflation-Linked Gilt Index Fund - Institutional Plus GBP Inc",
    "description": "U.K. Inflation-Linked Gilt Index Fund - Institutional Plus GBP Inc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": -0.0863,
      "threeYear": -0.1416,
      "fiveYear": -0.054599999999999996,
      "tenYear": 0.005080000000000002
    }
  },
  "IE00B1S74Q32": {
    "name": "U.K. Investment Grade Bond Index Fund - GBP Acc",
    "description": "U.K. Investment Grade Bond Index Fund - GBP Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0171,
      "threeYear": -0.024533333333333338,
      "fiveYear": -0.005280000000000004,
      "tenYear": 0.020259999999999997
    }
  },
  "IE00B1S74W91": {
    "name": "U.K. Investment Grade Bond Index Fund - GBP Dist",
    "description": "U.K. Investment Grade Bond Index Fund - GBP Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0171,
      "threeYear": -0.024533333333333338,
      "fiveYear": -0.005300000000000002,
      "tenYear": 0.020249999999999997
    }
  },
  "IE00BPT2BV95": {
    "name": "U.K. Investment Grade Bond Index Fund - Institutional Plus GBP Acc",
    "description": "U.K. Investment Grade Bond Index Fund - Institutional Plus GBP Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0177,
      "threeYear": -0.023933333333333334,
      "fiveYear": -0.004680000000000001,
      "tenYear": 0.02099
    }
  },
  "IE00BPT2BT73": {
    "name": "U.K. Investment Grade Bond Index Fund - Institutional Plus GBP Dist",
    "description": "U.K. Investment Grade Bond Index Fund - Institutional Plus GBP Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0177,
      "threeYear": -0.023933333333333334,
      "fiveYear": -0.004680000000000001,
      "tenYear": 0.02099
    }
  },
  "GB00B4M89245": {
    "name": "U.K. Long Duration Gilt Index Fund - GBP Acc",
    "description": "U.K. Long Duration Gilt Index Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.1063,
      "threeYear": -0.16446666666666668,
      "fiveYear": -0.08522,
      "tenYear": -0.009550000000000001
    }
  },
  "GB00B44DQC62": {
    "name": "U.K. Long Duration Gilt Index Fund - GBP Inc",
    "description": "U.K. Long Duration Gilt Index Fund - GBP Inc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": -0.1063,
      "threeYear": -0.16443333333333335,
      "fiveYear": -0.08518,
      "tenYear": -0.009550000000000003
    }
  },
  "GB00BPN5P675": {
    "name": "U.K. Long Duration Gilt Index Fund - Institutional Plus GBP Acc",
    "description": "U.K. Long Duration Gilt Index Fund - Institutional Plus GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.1059,
      "threeYear": -0.16413333333333333,
      "fiveYear": -0.08484,
      "tenYear": -0.009119999999999993
    }
  },
  "GB00BPN5P568": {
    "name": "U.K. Long Duration Gilt Index Fund - Institutional Plus GBP Inc",
    "description": "U.K. Long Duration Gilt Index Fund - Institutional Plus GBP Inc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": -0.1059,
      "threeYear": -0.164,
      "fiveYear": -0.08476,
      "tenYear": -0.00912
    }
  },
  "IE00B9M1BB17": {
    "name": "U.K. Short-Term Investment Grade Bond Index Fund - GBP Acc",
    "description": "U.K. Short-Term Investment Grade Bond Index Fund - GBP Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0401,
      "threeYear": 0.011533333333333335,
      "fiveYear": 0.01048,
      "tenYear": 0.015059999999999999
    }
  },
  "IE00B95W7137": {
    "name": "U.K. Short-Term Investment Grade Bond Index Fund - GBP Dist",
    "description": "U.K. Short-Term Investment Grade Bond Index Fund - GBP Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0401,
      "threeYear": 0.011533333333333335,
      "fiveYear": 0.01048,
      "tenYear": 0.015059999999999999
    }
  },
  "IE00BPT2BX10": {
    "name": "U.K. Short-Term Investment Grade Bond Index Fund - Institutional Plus GBP Acc",
    "description": "U.K. Short-Term Investment Grade Bond Index Fund - Institutional Plus GBP Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0005,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0408,
      "threeYear": 0.012199999999999997,
      "fiveYear": 0.01116,
      "tenYear": 0.01591
    }
  },
  "IE00BPT2BW03": {
    "name": "U.K. Short-Term Investment Grade Bond Index Fund - Institutional Plus GBP Dist",
    "description": "U.K. Short-Term Investment Grade Bond Index Fund - Institutional Plus GBP Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0005,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0408,
      "threeYear": 0.012199999999999997,
      "fiveYear": 0.01116,
      "tenYear": 0.01592
    }
  },
  "IE00077YXI85": {
    "name": "U.S. Government Bond Index Fund - CHF Hedged Acc",
    "description": "U.S. Government Bond Index Fund - CHF Hedged Acc - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0375,
      "threeYear": -0.013966666666666667,
      "fiveYear": -0.00838,
      "tenYear": -0.00419
    }
  },
  "IE0007471471": {
    "name": "U.S. Government Bond Index Fund - EUR Hedged Acc",
    "description": "U.S. Government Bond Index Fund - EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.011200000000000002,
      "threeYear": -0.04680000000000001,
      "fiveYear": -0.021860000000000004,
      "tenYear": -0.009130000000000003
    }
  },
  "IE00BD6D4566": {
    "name": "U.S. Government Bond Index Fund - GBP Dist",
    "description": "U.S. Government Bond Index Fund - GBP Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.024399999999999998,
      "threeYear": -0.002800000000000001,
      "fiveYear": 0.0040799999999999986,
      "tenYear": 0.01137
    }
  },
  "IE00BFRTDB69": {
    "name": "U.S. Government Bond Index Fund - GBP Hedged Acc",
    "description": "U.S. Government Bond Index Fund - GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0021,
      "threeYear": -0.03376666666666667,
      "fiveYear": -0.010659999999999998,
      "tenYear": 0.0012700000000000016
    }
  },
  "IE00BDD0SS10": {
    "name": "U.S. Government Bond Index Fund - GBP Hedged Dist",
    "description": "U.S. Government Bond Index Fund - GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0022,
      "threeYear": -0.033766666666666674,
      "fiveYear": -0.010640000000000005,
      "tenYear": -0.0005400000000000023
    }
  },
  "IE00BF6T7R10": {
    "name": "U.S. Government Bond Index Fund - Institutional Plus EUR Hedged Acc",
    "description": "U.S. Government Bond Index Fund - Institutional Plus EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0106,
      "threeYear": -0.04616666666666666,
      "fiveYear": -0.02116,
      "tenYear": -0.00887
    }
  },
  "IE00BK6S3P37": {
    "name": "U.S. Government Bond Index Fund - Institutional Plus GBP Hedged Acc",
    "description": "U.S. Government Bond Index Fund - Institutional Plus GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0028000000000000004,
      "threeYear": 0.0009333333333333334,
      "fiveYear": 0.0005600000000000001,
      "tenYear": 0.00028000000000000003
    }
  },
  "IE000UZS9RV3": {
    "name": "U.S. Government Bond Index Fund - Institutional Plus GBP Hedged Dist",
    "description": "U.S. Government Bond Index Fund - Institutional Plus GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0026,
      "threeYear": 0.0008666666666666666,
      "fiveYear": 0.00052,
      "tenYear": 0.00026
    }
  },
  "IE00BFPM9Z33": {
    "name": "U.S. Government Bond Index Fund - Institutional Plus USD Acc",
    "description": "U.S. Government Bond Index Fund - Institutional Plus USD Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.006999999999999999,
      "threeYear": -0.025399999999999995,
      "fiveYear": -0.0043999999999999985,
      "tenYear": 0.0092
    }
  },
  "IE0007471695": {
    "name": "U.S. Government Bond Index Fund - Investor USD Acc",
    "description": "U.S. Government Bond Index Fund - Investor USD Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0064,
      "threeYear": -0.025966666666666666,
      "fiveYear": -0.004960000000000001,
      "tenYear": 0.00817
    }
  },
  "IE0007471927": {
    "name": "U.S. Government Bond Index Fund - USD Acc",
    "description": "U.S. Government Bond Index Fund - USD Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0064,
      "threeYear": -0.025966666666666666,
      "fiveYear": -0.004960000000000001,
      "tenYear": 0.00842
    }
  },
  "IE00B04GQT48": {
    "name": "U.S. Investment Grade Credit Index Fund - EUR Acc",
    "description": "U.S. Investment Grade Credit Index Fund - EUR Acc - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0941,
      "threeYear": 0.015333333333333332,
      "fiveYear": 0.021679999999999998,
      "tenYear": 0.04086000000000001
    }
  },
  "IE00BFRTDC76": {
    "name": "U.S. Investment Grade Credit Index Fund - GBP Hedged Acc",
    "description": "U.S. Investment Grade Credit Index Fund - GBP Hedged Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.021099999999999997,
      "threeYear": -0.02323333333333333,
      "fiveYear": -0.0007599999999999996,
      "tenYear": 0.0164
    }
  },
  "IE00BFPMB139": {
    "name": "U.S. Investment Grade Credit Index Fund - Institutional Plus EUR Acc",
    "description": "U.S. Investment Grade Credit Index Fund - Institutional Plus EUR Acc - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.09449999999999999,
      "threeYear": 0.015733333333333332,
      "fiveYear": 0.02208,
      "tenYear": 0.04195000000000001
    }
  },
  "IE00BZ04LQ92": {
    "name": "U.S. Investment Grade Credit Index Fund - Institutional Plus EUR Hedged Acc",
    "description": "U.S. Investment Grade Credit Index Fund - Institutional Plus EUR Hedged Acc - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.008100000000000001,
      "threeYear": -0.03576666666666666,
      "fiveYear": -0.010799999999999994,
      "tenYear": 0.007580000000000003
    }
  },
  "IE00BD07TS20": {
    "name": "U.S. Investment Grade Credit Index Fund - Institutional Plus GBP Hedged Acc",
    "description": "U.S. Investment Grade Credit Index Fund - Institutional Plus GBP Hedged Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0215,
      "threeYear": -0.023433333333333344,
      "fiveYear": -0.0007600000000000078,
      "tenYear": 0.012289999999999997
    }
  },
  "IE00BDBBNM56": {
    "name": "U.S. Investment Grade Credit Index Fund - Institutional Plus GBP Hedged Dist",
    "description": "U.S. Investment Grade Credit Index Fund - Institutional Plus GBP Hedged Dist - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0217,
      "threeYear": -0.0228,
      "fiveYear": -0.0003799999999999998,
      "tenYear": 0.01248
    }
  },
  "IE00BFPMB022": {
    "name": "U.S. Investment Grade Credit Index Fund - Institutional Plus USD Acc",
    "description": "U.S. Investment Grade Credit Index Fund - Institutional Plus USD Acc - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.026000000000000002,
      "threeYear": -0.013566666666666663,
      "fiveYear": 0.007420000000000001,
      "tenYear": 0.02597
    }
  },
  "IE00BZ04LP85": {
    "name": "U.S. Investment Grade Credit Index Fund - Institutional Plus USD Dist",
    "description": "U.S. Investment Grade Credit Index Fund - Institutional Plus USD Dist - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.026000000000000002,
      "threeYear": -0.013566666666666663,
      "fiveYear": 0.007420000000000001,
      "tenYear": 0.02616
    }
  },
  "IE00B04GQX83": {
    "name": "U.S. Investment Grade Credit Index Fund - USD Acc",
    "description": "U.S. Investment Grade Credit Index Fund - USD Acc - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0256,
      "threeYear": -0.01393333333333333,
      "fiveYear": 0.007020000000000004,
      "tenYear": 0.02511
    }
  },
  "IE00BLRPPV00": {
    "name": "U.S. Treasury 0-1 Year Bond UCITS ETF - (USD) Accumulating",
    "description": "U.S. Treasury 0-1 Year Bond UCITS ETF - (USD) Accumulating - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0005,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0521,
      "threeYear": 0.03726666666666667,
      "fiveYear": 0.022340000000000002,
      "tenYear": 0.011170000000000001
    }
  },
  "IE00BLRPPW17": {
    "name": "U.S. Treasury 0-1 Year Bond UCITS ETF - MXN Hedged Accumulating",
    "description": "U.S. Treasury 0-1 Year Bond UCITS ETF - MXN Hedged Accumulating - A fixed income fund denominated in MXN.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1148,
      "threeYear": 0.10456666666666665,
      "fiveYear": 0.07224,
      "tenYear": 0.03612
    }
  },
  "IE00BD87Q617": {
    "name": "U.S. Treasury Inflation-Protected Securities Index Fund - Select CHF Hedged Acc",
    "description": "U.S. Treasury Inflation-Protected Securities Index Fund - Select CHF Hedged Acc - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.010700000000000001,
      "threeYear": -0.0366,
      "fiveYear": 0.00023999999999999854,
      "tenYear": -0.0007500000000000004
    }
  },
  "IE00BD87Q724": {
    "name": "U.S. Treasury Inflation-Protected Securities Index Fund - Select CHF Hedged Dist",
    "description": "U.S. Treasury Inflation-Protected Securities Index Fund - Select CHF Hedged Dist - A fixed income fund denominated in CHF.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": -0.010700000000000001,
      "threeYear": -0.03666666666666667,
      "fiveYear": 0.00015999999999999904,
      "tenYear": -0.0007900000000000001
    }
  },
  "IE00BD87Q831": {
    "name": "U.S. Treasury Inflation-Protected Securities Index Fund - Select EUR Hedged Acc",
    "description": "U.S. Treasury Inflation-Protected Securities Index Fund - Select EUR Hedged Acc - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.015600000000000001,
      "threeYear": -0.019599999999999996,
      "fiveYear": 0.011600000000000001,
      "tenYear": 0.0064
    }
  },
  "IE00BD87Q948": {
    "name": "U.S. Treasury Inflation-Protected Securities Index Fund - Select EUR Hedged Dist",
    "description": "U.S. Treasury Inflation-Protected Securities Index Fund - Select EUR Hedged Dist - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.015600000000000001,
      "threeYear": -0.019633333333333336,
      "fiveYear": 0.011599999999999997,
      "tenYear": 0.006399999999999998
    }
  },
  "IE00BD87QC73": {
    "name": "U.S. Treasury Inflation-Protected Securities Index Fund - Select GBP Hedged Acc",
    "description": "U.S. Treasury Inflation-Protected Securities Index Fund - Select GBP Hedged Acc - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0292,
      "threeYear": -0.006733333333333332,
      "fiveYear": 0.0221,
      "tenYear": 0.014860000000000002
    }
  },
  "IE00BD87QD80": {
    "name": "U.S. Treasury Inflation-Protected Securities Index Fund - Select GBP Hedged Dist",
    "description": "U.S. Treasury Inflation-Protected Securities Index Fund - Select GBP Hedged Dist - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0292,
      "threeYear": -0.006699999999999998,
      "fiveYear": 0.022140000000000003,
      "tenYear": 0.01488
    }
  },
  "IE00BD87QR27": {
    "name": "U.S. Treasury Inflation-Protected Securities Index Fund - Select SGD Hedged Acc",
    "description": "U.S. Treasury Inflation-Protected Securities Index Fund - Select SGD Hedged Acc - A fixed income fund denominated in SGD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.013500000000000002,
      "threeYear": -0.011833333333333335,
      "fiveYear": 0.020839999999999997,
      "tenYear": 0.01705
    }
  },
  "IE00BD87Q492": {
    "name": "U.S. Treasury Inflation-Protected Securities Index Fund - Select USD Acc",
    "description": "U.S. Treasury Inflation-Protected Securities Index Fund - Select USD Acc - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0325,
      "threeYear": 0.0005000000000000004,
      "fiveYear": 0.028559999999999995,
      "tenYear": 0.022549999999999997
    }
  },
  "IE00BD87Q500": {
    "name": "U.S. Treasury Inflation-Protected Securities Index Fund - Select USD Dist",
    "description": "U.S. Treasury Inflation-Protected Securities Index Fund - Select USD Dist - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0325,
      "threeYear": 0.0005000000000000004,
      "fiveYear": 0.02858,
      "tenYear": 0.022560000000000004
    }
  },
  "IE00BGYWSV06": {
    "name": "USD Corporate 1-3 Year Bond UCITS ETF - (USD) Accumulating",
    "description": "USD Corporate 1-3 Year Bond UCITS ETF - (USD) Accumulating - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0535,
      "threeYear": 0.023566666666666666,
      "fiveYear": 0.02132,
      "tenYear": 0.01066
    }
  },
  "IE00BDD48R20": {
    "name": "USD Corporate 1-3 Year Bond UCITS ETF - (USD) Distributing",
    "description": "USD Corporate 1-3 Year Bond UCITS ETF - (USD) Distributing - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0535,
      "threeYear": 0.023566666666666666,
      "fiveYear": 0.02132,
      "tenYear": 0.01593
    }
  },
  "IE00BGYWSW13": {
    "name": "USD Corporate 1-3 Year Bond UCITS ETF - EUR Hedged Accumulating",
    "description": "USD Corporate 1-3 Year Bond UCITS ETF - EUR Hedged Accumulating - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.036000000000000004,
      "threeYear": 0.012000000000000002,
      "fiveYear": 0.007200000000000001,
      "tenYear": 0.0036000000000000003
    }
  },
  "IE00BGYWSZ44": {
    "name": "USD Corporate 1-3 Year Bond UCITS ETF - GBP Hedged Distributing",
    "description": "USD Corporate 1-3 Year Bond UCITS ETF - GBP Hedged Distributing - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": null,
      "threeYear": 0,
      "fiveYear": 0,
      "tenYear": 0
    }
  },
  "IE000NATSYT3": {
    "name": "USD Corporate 1-3 Year Bond UCITS ETF - MXN Hedged Accumulating",
    "description": "USD Corporate 1-3 Year Bond UCITS ETF - MXN Hedged Accumulating - A fixed income fund denominated in MXN.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.11630000000000001,
      "threeYear": 0.03876666666666667,
      "fiveYear": 0.023260000000000003,
      "tenYear": 0.011630000000000001
    }
  },
  "IE00BGYWFK87": {
    "name": "USD Corporate Bond UCITS ETF - (USD) Accumulating",
    "description": "USD Corporate Bond UCITS ETF - (USD) Accumulating - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0265,
      "threeYear": -0.013966666666666674,
      "fiveYear": 0.008899999999999995,
      "tenYear": 0.004449999999999997
    }
  },
  "IE00BZ163K21": {
    "name": "USD Corporate Bond UCITS ETF - (USD) Distributing",
    "description": "USD Corporate Bond UCITS ETF - (USD) Distributing - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0265,
      "threeYear": -0.013966666666666674,
      "fiveYear": 0.008879999999999996,
      "tenYear": 0.02218
    }
  },
  "IE00BGYWFL94": {
    "name": "USD Corporate Bond UCITS ETF - EUR Hedged Accumulating",
    "description": "USD Corporate Bond UCITS ETF - EUR Hedged Accumulating - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.008,
      "threeYear": -0.03693333333333334,
      "fiveYear": -0.026140000000000004,
      "tenYear": -0.013070000000000002
    }
  },
  "IE00BGYWFN19": {
    "name": "USD Corporate Bond UCITS ETF - GBP Hedged Accumulating",
    "description": "USD Corporate Bond UCITS ETF - GBP Hedged Accumulating - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.021400000000000002,
      "threeYear": -0.02406666666666667,
      "fiveYear": -0.014440000000000003,
      "tenYear": -0.007220000000000002
    }
  },
  "IE00BGYWCB81": {
    "name": "USD Emerging Markets Government Bond UCITS ETF - (USD) Accumulating",
    "description": "USD Emerging Markets Government Bond UCITS ETF - (USD) Accumulating - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.063,
      "threeYear": 0.0027666666666666673,
      "fiveYear": 0.0103,
      "tenYear": 0.00515
    }
  },
  "IE00BZ163L38": {
    "name": "USD Emerging Markets Government Bond UCITS ETF - (USD) Distributing",
    "description": "USD Emerging Markets Government Bond UCITS ETF - (USD) Distributing - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.063,
      "threeYear": 0.0027666666666666673,
      "fiveYear": 0.0103,
      "tenYear": 0.023520000000000003
    }
  },
  "IE00BGYWFS63": {
    "name": "USD Treasury Bond UCITS ETF - (USD) Accumulating",
    "description": "USD Treasury Bond UCITS ETF - (USD) Accumulating - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0007000000000000001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0062,
      "threeYear": -0.02626666666666667,
      "fiveYear": -0.004600000000000002,
      "tenYear": -0.002300000000000001
    }
  },
  "IE00BZ163M45": {
    "name": "USD Treasury Bond UCITS ETF - (USD) Distributing",
    "description": "USD Treasury Bond UCITS ETF - (USD) Distributing - A fixed income fund denominated in USD.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0007000000000000001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0062,
      "threeYear": -0.02626666666666667,
      "fiveYear": -0.004600000000000002,
      "tenYear": 0.007469999999999999
    }
  },
  "IE00BMX0B631": {
    "name": "USD Treasury Bond UCITS ETF - EUR Hedged Accumulating",
    "description": "USD Treasury Bond UCITS ETF - EUR Hedged Accumulating - A fixed income fund denominated in EUR.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.011899999999999999,
      "threeYear": -0.0476,
      "fiveYear": -0.03526,
      "tenYear": -0.01763
    }
  },
  "IE000S0PSP47": {
    "name": "USD Treasury Bond UCITS ETF - GBP Hedged Distributing",
    "description": "USD Treasury Bond UCITS ETF - GBP Hedged Distributing - A fixed income fund denominated in GBP.",
    "category": "Fixed Income",
    "assetType": "Pure Bond",
    "riskLevel": "Low",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0012,
      "threeYear": 0.010666666666666666,
      "fiveYear": 0.0064,
      "tenYear": 0.0032
    }
  },
  "GB00BK1XRK60": {
    "name": "Active U.K. Equity Fund - GBP Acc",
    "description": "Active U.K. Equity Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0045000000000000005,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.08470000000000001,
      "threeYear": 0.0025666666666666707,
      "fiveYear": 0.014040000000000002,
      "tenYear": 0.007020000000000001
    }
  },
  "GB00BK1XR392": {
    "name": "Active U.K. Equity Fund - GBP Inc",
    "description": "Active U.K. Equity Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0045000000000000005,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.08470000000000001,
      "threeYear": 0.0025000000000000022,
      "fiveYear": 0.013960000000000004,
      "tenYear": 0.006980000000000002
    }
  },
  "IE0031786696": {
    "name": "Emerging Markets Stock Index Fund - EUR Acc",
    "description": "Emerging Markets Stock Index Fund - EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0023,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1479,
      "threeYear": 0.01736666666666667,
      "fiveYear": 0.035919999999999994,
      "tenYear": 0.05708
    }
  },
  "IE00B50MZ724": {
    "name": "Emerging Markets Stock Index Fund - GBP Acc",
    "description": "Emerging Markets Stock Index Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0023,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0953,
      "threeYear": 0.0076666666666666645,
      "fiveYear": 0.029339999999999998,
      "tenYear": 0.06615
    }
  },
  "IE00B51KVT96": {
    "name": "Emerging Markets Stock Index Fund - GBP Dist",
    "description": "Emerging Markets Stock Index Fund - GBP Dist - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0023,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0953,
      "threeYear": 0.0076666666666666645,
      "fiveYear": 0.029339999999999998,
      "tenYear": 0.06614
    }
  },
  "IE00BVYPLM61": {
    "name": "Emerging Markets Stock Index Fund - Institutional Plus CHF Acc",
    "description": "Emerging Markets Stock Index Fund - Institutional Plus CHF Acc - A equity fund denominated in CHF.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1595,
      "threeYear": -0.012233333333333327,
      "fiveYear": 0.008600000000000002,
      "tenYear": 0.050780000000000006
    }
  },
  "IE00BFPM9J74": {
    "name": "Emerging Markets Stock Index Fund - Institutional Plus EUR Acc",
    "description": "Emerging Markets Stock Index Fund - Institutional Plus EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1487,
      "threeYear": 0.018066666666666665,
      "fiveYear": 0.03664,
      "tenYear": 0.05771
    }
  },
  "IE00BPT2BB99": {
    "name": "Emerging Markets Stock Index Fund - Institutional Plus GBP Acc",
    "description": "Emerging Markets Stock Index Fund - Institutional Plus GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.09609999999999999,
      "threeYear": 0.008399999999999996,
      "fiveYear": 0.030079999999999996,
      "tenYear": 0.06679
    }
  },
  "IE00BPT2B978": {
    "name": "Emerging Markets Stock Index Fund - Institutional Plus GBP Dist",
    "description": "Emerging Markets Stock Index Fund - Institutional Plus GBP Dist - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.09609999999999999,
      "threeYear": 0.008399999999999996,
      "fiveYear": 0.030079999999999996,
      "tenYear": 0.06679
    }
  },
  "IE00BFPM9H50": {
    "name": "Emerging Markets Stock Index Fund - Institutional Plus USD Acc",
    "description": "Emerging Markets Stock Index Fund - Institutional Plus USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0768,
      "threeYear": -0.011000000000000001,
      "fiveYear": 0.023939999999999996,
      "tenYear": 0.04859
    }
  },
  "IE0031786142": {
    "name": "Emerging Markets Stock Index Fund - Investor EUR Acc",
    "description": "Emerging Markets Stock Index Fund - Investor EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0023,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1479,
      "threeYear": 0.01736666666666667,
      "fiveYear": 0.035919999999999994,
      "tenYear": 0.056409999999999995
    }
  },
  "IE0031786928": {
    "name": "Emerging Markets Stock Index Fund - Investor USD Acc",
    "description": "Emerging Markets Stock Index Fund - Investor USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0023,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0761,
      "threeYear": -0.011633333333333338,
      "fiveYear": 0.023239999999999997,
      "tenYear": 0.04729
    }
  },
  "IE0031787223": {
    "name": "Emerging Markets Stock Index Fund - USD Acc",
    "description": "Emerging Markets Stock Index Fund - USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0023,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0761,
      "threeYear": -0.011633333333333338,
      "fiveYear": 0.023239999999999997,
      "tenYear": 0.047950000000000007
    }
  },
  "IE000GOJO2A3": {
    "name": "ESG Developed Asia Pacific All Cap UCITS ETF - (USD) Accumulating",
    "description": "ESG Developed Asia Pacific All Cap UCITS ETF - (USD) Accumulating - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0017000000000000001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0159,
      "threeYear": 0.05423333333333333,
      "fiveYear": 0.03254,
      "tenYear": 0.01627
    }
  },
  "IE0008T6IUX0": {
    "name": "ESG Developed Asia Pacific All Cap UCITS ETF - (USD) Distributing",
    "description": "ESG Developed Asia Pacific All Cap UCITS ETF - (USD) Distributing - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0017000000000000001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0159,
      "threeYear": 0.05423333333333333,
      "fiveYear": 0.03254,
      "tenYear": 0.01627
    }
  },
  "IE000QUOSE01": {
    "name": "ESG Developed Europe All Cap UCITS ETF - (EUR) Accumulating",
    "description": "ESG Developed Europe All Cap UCITS ETF - (EUR) Accumulating - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0956,
      "threeYear": 0.08726666666666667,
      "fiveYear": 0.052360000000000004,
      "tenYear": 0.026180000000000002
    }
  },
  "IE000NRGX9M3": {
    "name": "ESG Developed Europe All Cap UCITS ETF - (EUR) Distributing",
    "description": "ESG Developed Europe All Cap UCITS ETF - (EUR) Distributing - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0956,
      "threeYear": 0.08726666666666667,
      "fiveYear": 0.052360000000000004,
      "tenYear": 0.026180000000000002
    }
  },
  "IE00B526YN16": {
    "name": "ESG Developed Europe Index Fund - EUR Acc",
    "description": "ESG Developed Europe Index Fund - EUR Acc - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0877,
      "threeYear": 0.048500000000000015,
      "fiveYear": 0.07708000000000001,
      "tenYear": 0.07486000000000001
    }
  },
  "IE00B76VTL96": {
    "name": "ESG Developed Europe Index Fund - GBP Acc",
    "description": "ESG Developed Europe Index Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0378,
      "threeYear": 0.03969999999999999,
      "fiveYear": 0.06727999999999999,
      "tenYear": 0.07891000000000001
    }
  },
  "IE00B76VTR58": {
    "name": "ESG Developed Europe Index Fund - GBP Dist",
    "description": "ESG Developed Europe Index Fund - GBP Dist - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0378,
      "threeYear": 0.03969999999999999,
      "fiveYear": 0.0673,
      "tenYear": 0.07892
    }
  },
  "IE00BFPM9R58": {
    "name": "ESG Developed Europe Index Fund - Institutional Plus EUR Acc",
    "description": "ESG Developed Europe Index Fund - Institutional Plus EUR Acc - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.08810000000000001,
      "threeYear": 0.04893333333333335,
      "fiveYear": 0.07752,
      "tenYear": 0.07561
    }
  },
  "IE00B4Z8LP80": {
    "name": "ESG Developed Europe Index Fund - USD Acc",
    "description": "ESG Developed Europe Index Fund - USD Acc - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0196,
      "threeYear": 0.021066666666666668,
      "fiveYear": 0.06055999999999999,
      "tenYear": 0.0613
    }
  },
  "IE000VIPJ289": {
    "name": "ESG Developed World All Cap Equity Index Fund - CHF Acc",
    "description": "ESG Developed World All Cap Equity Index Fund - CHF Acc - A equity fund denominated in CHF.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2703,
      "threeYear": 0.13936666666666667,
      "fiveYear": 0.08362,
      "tenYear": 0.04181
    }
  },
  "IE0001RDRUG3": {
    "name": "ESG Developed World All Cap Equity Index Fund - CHF Hedged Acc",
    "description": "ESG Developed World All Cap Equity Index Fund - CHF Hedged Acc - A equity fund denominated in CHF.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1654,
      "threeYear": 0.1269,
      "fiveYear": 0.07614000000000001,
      "tenYear": 0.03807000000000001
    }
  },
  "IE00B5456744": {
    "name": "ESG Developed World All Cap Equity Index Fund - EUR Acc",
    "description": "ESG Developed World All Cap Equity Index Fund - EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2585,
      "threeYear": 0.09960000000000002,
      "fiveYear": 0.13540000000000002,
      "tenYear": 0.12011999999999998
    }
  },
  "IE00B76VTN11": {
    "name": "ESG Developed World All Cap Equity Index Fund - GBP Acc",
    "description": "ESG Developed World All Cap Equity Index Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2009,
      "threeYear": 0.08643333333333332,
      "fiveYear": 0.12357999999999998,
      "tenYear": 0.12414999999999998
    }
  },
  "IE00B76VTM04": {
    "name": "ESG Developed World All Cap Equity Index Fund - GBP Dist",
    "description": "ESG Developed World All Cap Equity Index Fund - GBP Dist - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.2009,
      "threeYear": 0.08643333333333332,
      "fiveYear": 0.12357999999999998,
      "tenYear": 0.12416
    }
  },
  "IE00BFPM9S65": {
    "name": "ESG Developed World All Cap Equity Index Fund - Institutional Plus EUR Acc",
    "description": "ESG Developed World All Cap Equity Index Fund - Institutional Plus EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0013,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2594,
      "threeYear": 0.10036666666666667,
      "fiveYear": 0.13618000000000002,
      "tenYear": 0.12106999999999998
    }
  },
  "IE00BNDQ1K21": {
    "name": "ESG Developed World All Cap Equity Index Fund - Institutional Plus EUR Hedged Acc",
    "description": "ESG Developed World All Cap Equity Index Fund - Institutional Plus EUR Hedged Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0013,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.19390000000000002,
      "threeYear": 0.06753333333333335,
      "fiveYear": 0.04052000000000001,
      "tenYear": 0.020260000000000004
    }
  },
  "IE00BPT2BN12": {
    "name": "ESG Developed World All Cap Equity Index Fund - Institutional Plus GBP Acc",
    "description": "ESG Developed World All Cap Equity Index Fund - Institutional Plus GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0013,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.20170000000000002,
      "threeYear": 0.0872,
      "fiveYear": 0.12438,
      "tenYear": 0.1251
    }
  },
  "IE00B505V954": {
    "name": "ESG Developed World All Cap Equity Index Fund - USD Acc",
    "description": "ESG Developed World All Cap Equity Index Fund - USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.17980000000000002,
      "threeYear": 0.07076666666666666,
      "fiveYear": 0.11923999999999998,
      "tenYear": 0.10554
    }
  },
  "IE000WZLTHJ2": {
    "name": "ESG Emerging Markets All Cap Equity Index Fund - CHF Acc",
    "description": "ESG Emerging Markets All Cap Equity Index Fund - CHF Acc - A equity fund denominated in CHF.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2039,
      "threeYear": 0.0638,
      "fiveYear": 0.038279999999999995,
      "tenYear": 0.019139999999999997
    }
  },
  "IE00BKV0W243": {
    "name": "ESG Emerging Markets All Cap Equity Index Fund - EUR Acc",
    "description": "ESG Emerging Markets All Cap Equity Index Fund - EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1928,
      "threeYear": 0.03536666666666666,
      "fiveYear": 0.031999999999999994,
      "tenYear": 0.015999999999999997
    }
  },
  "IE00BKV0VZ05": {
    "name": "ESG Emerging Markets All Cap Equity Index Fund - GBP Acc",
    "description": "ESG Emerging Markets All Cap Equity Index Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1381,
      "threeYear": 0.025299999999999993,
      "fiveYear": 0.012899999999999998,
      "tenYear": 0.006449999999999999
    }
  },
  "IE00BKV0W029": {
    "name": "ESG Emerging Markets All Cap Equity Index Fund - GBP Dist",
    "description": "ESG Emerging Markets All Cap Equity Index Fund - GBP Dist - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1381,
      "threeYear": 0.025299999999999993,
      "fiveYear": 0.012899999999999998,
      "tenYear": 0.006449999999999999
    }
  },
  "IE00BNDQ1L38": {
    "name": "ESG Emerging Markets All Cap Equity Index Fund - Institutional Plus EUR Acc",
    "description": "ESG Emerging Markets All Cap Equity Index Fund - Institutional Plus EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1936,
      "threeYear": 0.036066666666666664,
      "fiveYear": 0.02164,
      "tenYear": 0.01082
    }
  },
  "IE0005GPVQC0": {
    "name": "ESG Emerging Markets All Cap Equity Index Fund - Institutional Plus GBP Acc",
    "description": "ESG Emerging Markets All Cap Equity Index Fund - Institutional Plus GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1389,
      "threeYear": 0.02603333333333333,
      "fiveYear": 0.015619999999999998,
      "tenYear": 0.007809999999999999
    }
  },
  "IE00BKV0W136": {
    "name": "ESG Emerging Markets All Cap Equity Index Fund - Institutional Plus GBP Dist",
    "description": "ESG Emerging Markets All Cap Equity Index Fund - Institutional Plus GBP Dist - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1389,
      "threeYear": 0.02603333333333333,
      "fiveYear": 0.013479999999999997,
      "tenYear": 0.006739999999999999
    }
  },
  "IE00BKV0W359": {
    "name": "ESG Emerging Markets All Cap Equity Index Fund - USD Acc",
    "description": "ESG Emerging Markets All Cap Equity Index Fund - USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.11810000000000001,
      "threeYear": 0.004966666666666665,
      "fiveYear": -0.001100000000000001,
      "tenYear": -0.0005500000000000005
    }
  },
  "IE000KPJJWM6": {
    "name": "ESG Emerging Markets All Cap UCITS ETF - (USD) Accumulating",
    "description": "ESG Emerging Markets All Cap UCITS ETF - (USD) Accumulating - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.11900000000000001,
      "threeYear": 0.06793333333333333,
      "fiveYear": 0.040760000000000005,
      "tenYear": 0.020380000000000002
    }
  },
  "IE0001VXZTV7": {
    "name": "ESG Emerging Markets All Cap UCITS ETF - (USD) Distributing",
    "description": "ESG Emerging Markets All Cap UCITS ETF - (USD) Distributing - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.11900000000000001,
      "threeYear": 0.06796666666666668,
      "fiveYear": 0.040780000000000004,
      "tenYear": 0.020390000000000002
    }
  },
  "IE00BNG8L278": {
    "name": "ESG Global All Cap UCITS ETF - (USD) Accumulating",
    "description": "ESG Global All Cap UCITS ETF - (USD) Accumulating - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.17420000000000002,
      "threeYear": 0.06466666666666666,
      "fiveYear": 0.0388,
      "tenYear": 0.0194
    }
  },
  "IE00BNG8L385": {
    "name": "ESG Global All Cap UCITS ETF - (USD) Distributing",
    "description": "ESG Global All Cap UCITS ETF - (USD) Distributing - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.17420000000000002,
      "threeYear": 0.06466666666666666,
      "fiveYear": 0.0388,
      "tenYear": 0.0194
    }
  },
  "IE000O58J820": {
    "name": "ESG North America All Cap UCITS ETF - (USD) Accumulating",
    "description": "ESG North America All Cap UCITS ETF - (USD) Accumulating - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.23920000000000002,
      "threeYear": 0.17913333333333337,
      "fiveYear": 0.10748000000000002,
      "tenYear": 0.05374000000000001
    }
  },
  "IE000L2ZNB07": {
    "name": "ESG North America All Cap UCITS ETF - (USD) Distributing",
    "description": "ESG North America All Cap UCITS ETF - (USD) Distributing - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.23920000000000002,
      "threeYear": 0.17913333333333337,
      "fiveYear": 0.10748000000000002,
      "tenYear": 0.05374000000000001
    }
  },
  "GB00BLLZQL34": {
    "name": "ESG Screened Developed World All Cap Equity Index Fund (UK) - GBP Acc",
    "description": "ESG Screened Developed World All Cap Equity Index Fund (UK) - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2021,
      "threeYear": 0.08760000000000001,
      "fiveYear": 0.09634000000000001,
      "tenYear": 0.048170000000000004
    }
  },
  "GB00BLLXGT55": {
    "name": "ESG Screened Developed World All Cap Equity Index Fund (UK) - GBP Inc",
    "description": "ESG Screened Developed World All Cap Equity Index Fund (UK) - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.2021,
      "threeYear": 0.08760000000000001,
      "fiveYear": 0.09636,
      "tenYear": 0.04818
    }
  },
  "GB00BMC4Z756": {
    "name": "ESG Screened Developed World All Cap Equity Index Fund (UK) - Institutional Plus GBP Acc",
    "description": "ESG Screened Developed World All Cap Equity Index Fund (UK) - Institutional Plus GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0013,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2029,
      "threeYear": 0.08833333333333333,
      "fiveYear": 0.053000000000000005,
      "tenYear": 0.026500000000000003
    }
  },
  "GB00BLLZQQ88": {
    "name": "ESG Screened Developed World All Cap Equity Index Fund (UK) - Institutional Plus GBP Inc",
    "description": "ESG Screened Developed World All Cap Equity Index Fund (UK) - Institutional Plus GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0013,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.203,
      "threeYear": 0.08836666666666666,
      "fiveYear": 0.09696,
      "tenYear": 0.04848
    }
  },
  "IE0007987708": {
    "name": "European Stock Index Fund - EUR Acc",
    "description": "European Stock Index Fund - EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0886,
      "threeYear": 0.05253333333333333,
      "fiveYear": 0.07626,
      "tenYear": 0.07521
    }
  },
  "IE00BFPM9L96": {
    "name": "European Stock Index Fund - Institutional Plus EUR Acc",
    "description": "European Stock Index Fund - Institutional Plus EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.08900000000000001,
      "threeYear": 0.05296666666666666,
      "fiveYear": 0.0767,
      "tenYear": 0.07596
    }
  },
  "IE00BFPM9K89": {
    "name": "European Stock Index Fund - Institutional Plus USD Acc",
    "description": "European Stock Index Fund - Institutional Plus USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0209,
      "threeYear": 0.02519999999999999,
      "fiveYear": 0.05978,
      "tenYear": 0.06215000000000002
    }
  },
  "IE0007987690": {
    "name": "European Stock Index Fund - Investor EUR Acc",
    "description": "European Stock Index Fund - Investor EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0886,
      "threeYear": 0.05253333333333333,
      "fiveYear": 0.07626,
      "tenYear": 0.07494999999999999
    }
  },
  "IE0002639551": {
    "name": "European Stock Index Fund - USD Acc",
    "description": "European Stock Index Fund - USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.020499999999999997,
      "threeYear": 0.0248,
      "fiveYear": 0.05933999999999999,
      "tenYear": 0.061410000000000006
    }
  },
  "IE0008248803": {
    "name": "Eurozone Stock Index Fund - EUR Acc",
    "description": "Eurozone Stock Index Fund - EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0999,
      "threeYear": 0.057633333333333335,
      "fiveYear": 0.0785,
      "tenYear": 0.08063000000000001
    }
  },
  "IE00BGCC4585": {
    "name": "Eurozone Stock Index Fund - Institutional Plus EUR Acc",
    "description": "Eurozone Stock Index Fund - Institutional Plus EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1003,
      "threeYear": 0.058066666666666676,
      "fiveYear": 0.07892000000000002,
      "tenYear": 0.08137000000000001
    }
  },
  "IE00BGCC4478": {
    "name": "Eurozone Stock Index Fund - Institutional Plus USD Acc",
    "description": "Eurozone Stock Index Fund - Institutional Plus USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0315,
      "threeYear": 0.03106666666666665,
      "fiveYear": 0.06325999999999998,
      "tenYear": 0.06837
    }
  },
  "IE0008248795": {
    "name": "Eurozone Stock Index Fund - Investor EUR Acc",
    "description": "Eurozone Stock Index Fund - Investor EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0999,
      "threeYear": 0.057633333333333335,
      "fiveYear": 0.0785,
      "tenYear": 0.08037999999999998
    }
  },
  "IE00B02JYG83": {
    "name": "Eurozone Stock Index Fund - USD Acc",
    "description": "Eurozone Stock Index Fund - USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.031,
      "threeYear": 0.03059999999999999,
      "fiveYear": 0.0628,
      "tenYear": 0.06763
    }
  },
  "GB00BD3RZ368": {
    "name": "FTSE 100 Index Unit Trust - GBP Acc",
    "description": "FTSE 100 Index Unit Trust - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.09570000000000001,
      "threeYear": 0.07326666666666666,
      "fiveYear": 0.05758,
      "tenYear": 0.049179999999999995
    }
  },
  "GB00BD3RZ251": {
    "name": "FTSE 100 Index Unit Trust - GBP Inc",
    "description": "FTSE 100 Index Unit Trust - GBP Inc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0955,
      "threeYear": 0.07313333333333333,
      "fiveYear": 0.05746,
      "tenYear": 0.04908
    }
  },
  "IE00BFMXYP42": {
    "name": "FTSE 100 UCITS ETF - (GBP) Accumulating",
    "description": "FTSE 100 UCITS ETF - (GBP) Accumulating - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0949,
      "threeYear": 0.07293333333333332,
      "fiveYear": 0.05717999999999999,
      "tenYear": 0.028589999999999994
    }
  },
  "IE00B810Q511": {
    "name": "FTSE 100 UCITS ETF - (GBP) Distributing",
    "description": "FTSE 100 UCITS ETF - (GBP) Distributing - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0009,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0949,
      "threeYear": 0.07293333333333332,
      "fiveYear": 0.05717999999999999,
      "tenYear": 0.06624
    }
  },
  "IE00BFMXVQ44": {
    "name": "FTSE 250 UCITS ETF - (GBP) Accumulating",
    "description": "FTSE 250 UCITS ETF - (GBP) Accumulating - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0784,
      "threeYear": -0.00573333333333334,
      "fiveYear": 0.020599999999999997,
      "tenYear": 0.010299999999999998
    }
  },
  "IE00BKX55Q28": {
    "name": "FTSE 250 UCITS ETF - (GBP) Distributing",
    "description": "FTSE 250 UCITS ETF - (GBP) Distributing - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0784,
      "threeYear": -0.00573333333333334,
      "fiveYear": 0.020599999999999997,
      "tenYear": 0.06041
    }
  },
  "IE00BK5BR626": {
    "name": "FTSE All-World High Dividend Yield UCITS ETF - (USD) Accumulating",
    "description": "FTSE All-World High Dividend Yield UCITS ETF - (USD) Accumulating - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0029,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.09390000000000001,
      "threeYear": 0.05056666666666667,
      "fiveYear": 0.06558,
      "tenYear": 0.03279
    }
  },
  "IE00B8GKDB10": {
    "name": "FTSE All-World High Dividend Yield UCITS ETF - (USD) Distributing",
    "description": "FTSE All-World High Dividend Yield UCITS ETF - (USD) Distributing - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0029,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.09390000000000001,
      "threeYear": 0.05053333333333334,
      "fiveYear": 0.06556000000000001,
      "tenYear": 0.06692999999999999
    }
  },
  "IE00BK5BQT80": {
    "name": "FTSE All-World UCITS ETF - (USD) Accumulating",
    "description": "FTSE All-World UCITS ETF - (USD) Accumulating - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0022,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.17190000000000003,
      "threeYear": 0.07046666666666669,
      "fiveYear": 0.11092000000000002,
      "tenYear": 0.05546000000000001
    }
  },
  "IE00B3RBWM25": {
    "name": "FTSE All-World UCITS ETF - (USD) Distributing",
    "description": "FTSE All-World UCITS ETF - (USD) Distributing - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0022,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.17190000000000003,
      "threeYear": 0.07046666666666669,
      "fiveYear": 0.11092000000000002,
      "tenYear": 0.10214000000000001
    }
  },
  "IE00BK5BQZ41": {
    "name": "FTSE Developed Asia Pacific ex Japan UCITS ETF - (USD) Accumulating",
    "description": "FTSE Developed Asia Pacific ex Japan UCITS ETF - (USD) Accumulating - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0567,
      "threeYear": -0.0244,
      "fiveYear": 0.0248,
      "tenYear": 0.0124
    }
  },
  "IE00B9F5YL18": {
    "name": "FTSE Developed Asia Pacific ex Japan UCITS ETF - (USD) Distributing",
    "description": "FTSE Developed Asia Pacific ex Japan UCITS ETF - (USD) Distributing - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": -0.0567,
      "threeYear": -0.0244,
      "fiveYear": 0.0248,
      "tenYear": 0.04739999999999999
    }
  },
  "IE00BYMSW692": {
    "name": "FTSE Developed Europe ex UK Common Contractual Fund - Institutional B Accumulation - UK Life Ins. Co.",
    "description": "FTSE Developed Europe ex UK Common Contractual Fund - Institutional B Accumulation - UK Life Ins. Co. - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": -0.0019,
      "threeYear": 0.02466666666666666,
      "fiveYear": 0.06595999999999999,
      "tenYear": 0.06037
    }
  },
  "IE00BK5BQY34": {
    "name": "FTSE Developed Europe ex UK UCITS ETF - (EUR) Accumulating",
    "description": "FTSE Developed Europe ex UK UCITS ETF - (EUR) Accumulating - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0733,
      "threeYear": 0.04239999999999999,
      "fiveYear": 0.08041999999999999,
      "tenYear": 0.040209999999999996
    }
  },
  "IE00BKX55S42": {
    "name": "FTSE Developed Europe ex UK UCITS ETF - (EUR) Distributing",
    "description": "FTSE Developed Europe ex UK UCITS ETF - (EUR) Distributing - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0733,
      "threeYear": 0.04239999999999999,
      "fiveYear": 0.08041999999999999,
      "tenYear": 0.08305000000000001
    }
  },
  "GB00B5B71H80": {
    "name": "FTSE Developed Europe ex-U.K. Equity Index Fund - GBP Acc",
    "description": "FTSE Developed Europe ex-U.K. Equity Index Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.023,
      "threeYear": 0.032433333333333335,
      "fiveYear": 0.07027999999999998,
      "tenYear": 0.08737999999999999
    }
  },
  "GB00B5B74N55": {
    "name": "FTSE Developed Europe ex-U.K. Equity Index Fund - GBP Inc",
    "description": "FTSE Developed Europe ex-U.K. Equity Index Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.023,
      "threeYear": 0.032433333333333335,
      "fiveYear": 0.07027999999999998,
      "tenYear": 0.08739
    }
  },
  "GB00BPN5P014": {
    "name": "FTSE Developed Europe ex-U.K. Equity Index Fund - Institutional Plus GBP Acc",
    "description": "FTSE Developed Europe ex-U.K. Equity Index Fund - Institutional Plus GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0235,
      "threeYear": 0.03289999999999999,
      "fiveYear": 0.07072,
      "tenYear": 0.08778
    }
  },
  "GB00BPN5NZ22": {
    "name": "FTSE Developed Europe ex-U.K. Equity Index Fund - Institutional Plus GBP Inc",
    "description": "FTSE Developed Europe ex-U.K. Equity Index Fund - Institutional Plus GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0235,
      "threeYear": 0.03289999999999999,
      "fiveYear": 0.07074,
      "tenYear": 0.08779
    }
  },
  "IE00BK5BQX27": {
    "name": "FTSE Developed Europe UCITS ETF - (EUR) Accumulating",
    "description": "FTSE Developed Europe UCITS ETF - (EUR) Accumulating - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.09369999999999999,
      "threeYear": 0.05303333333333334,
      "fiveYear": 0.0771,
      "tenYear": 0.03855
    }
  },
  "IE00B945VV12": {
    "name": "FTSE Developed Europe UCITS ETF - (EUR) Distributing",
    "description": "FTSE Developed Europe UCITS ETF - (EUR) Distributing - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.09369999999999999,
      "threeYear": 0.05303333333333334,
      "fiveYear": 0.0771,
      "tenYear": 0.07695
    }
  },
  "IE00BRK93W89": {
    "name": "FTSE Developed World Common Contractual Fund - Institutional B Accumulation - UK Charity",
    "description": "FTSE Developed World Common Contractual Fund - Institutional B Accumulation - UK Charity - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1985,
      "threeYear": 0.09626666666666668,
      "fiveYear": 0.1275,
      "tenYear": 0.12440999999999999
    }
  },
  "IE00BRK94038": {
    "name": "FTSE Developed World Common Contractual Fund - Institutional B Accumulation - UK Life Ins. Co.",
    "description": "FTSE Developed World Common Contractual Fund - Institutional B Accumulation - UK Life Ins. Co. - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.19870000000000002,
      "threeYear": 0.0964,
      "fiveYear": 0.12758,
      "tenYear": 0.0862
    }
  },
  "IE00BRK94G96": {
    "name": "FTSE Developed World Common Contractual Fund - Institutional B Distributing Units - UK Charity",
    "description": "FTSE Developed World Common Contractual Fund - Institutional B Distributing Units - UK Charity - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1986,
      "threeYear": 0.09630000000000001,
      "fiveYear": 0.12754000000000001,
      "tenYear": 0.08308000000000001
    }
  },
  "IE00BRK94368": {
    "name": "FTSE Developed World Common Contractual Fund - Institutional B Hedged Accumulation - UK Life Ins. Co.",
    "description": "FTSE Developed World Common Contractual Fund - Institutional B Hedged Accumulation - UK Life Ins. Co. - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0027,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.207,
      "threeYear": 0.08843333333333332,
      "fiveYear": 0.1236,
      "tenYear": 0.09709
    }
  },
  "IE00BYMSV959": {
    "name": "FTSE Developed World ex UK Common Contractual Fund - Institutional B Accumulation - UK Life Ins. Co.",
    "description": "FTSE Developed World ex UK Common Contractual Fund - Institutional B Accumulation - UK Life Ins. Co. - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2027,
      "threeYear": 0.09800000000000002,
      "fiveYear": 0.13194,
      "tenYear": 0.09901
    }
  },
  "GB00B59G4Q73": {
    "name": "FTSE Developed World ex-U.K. Equity Index Fund - GBP Acc",
    "description": "FTSE Developed World ex-U.K. Equity Index Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.20329999999999998,
      "threeYear": 0.09756666666666665,
      "fiveYear": 0.13138,
      "tenYear": 0.13332
    }
  },
  "GB00B5B74F71": {
    "name": "FTSE Developed World ex-U.K. Equity Index Fund - GBP Inc",
    "description": "FTSE Developed World ex-U.K. Equity Index Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.20350000000000001,
      "threeYear": 0.09756666666666668,
      "fiveYear": 0.13138,
      "tenYear": 0.13334000000000001
    }
  },
  "GB00BPN5NY15": {
    "name": "FTSE Developed World ex-U.K. Equity Index Fund - Institutional Plus GBP Acc",
    "description": "FTSE Developed World ex-U.K. Equity Index Fund - Institutional Plus GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.204,
      "threeYear": 0.09820000000000001,
      "fiveYear": 0.13204,
      "tenYear": 0.13394999999999999
    }
  },
  "GB00BPN5NX08": {
    "name": "FTSE Developed World ex-U.K. Equity Index Fund - Institutional Plus GBP Inc",
    "description": "FTSE Developed World ex-U.K. Equity Index Fund - Institutional Plus GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.20420000000000002,
      "threeYear": 0.09823333333333334,
      "fiveYear": 0.13206,
      "tenYear": 0.13394
    }
  },
  "IE00BK5BQV03": {
    "name": "FTSE Developed World UCITS ETF - (USD) Accumulating",
    "description": "FTSE Developed World UCITS ETF - (USD) Accumulating - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.17859999999999998,
      "threeYear": 0.07883333333333332,
      "fiveYear": 0.1217,
      "tenYear": 0.06085
    }
  },
  "IE00BKX55T58": {
    "name": "FTSE Developed World UCITS ETF - (USD) Distributing",
    "description": "FTSE Developed World UCITS ETF - (USD) Distributing - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.17859999999999998,
      "threeYear": 0.07883333333333332,
      "fiveYear": 0.1217,
      "tenYear": 0.10933
    }
  },
  "IE00BK5BR733": {
    "name": "FTSE Emerging Markets UCITS ETF - (USD) Accumulating",
    "description": "FTSE Emerging Markets UCITS ETF - (USD) Accumulating - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0022,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1206,
      "threeYear": 0.008066666666666666,
      "fiveYear": 0.03284,
      "tenYear": 0.01642
    }
  },
  "IE00B3VVMM84": {
    "name": "FTSE Emerging Markets UCITS ETF - (USD) Distributing",
    "description": "FTSE Emerging Markets UCITS ETF - (USD) Distributing - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0022,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1206,
      "threeYear": 0.008066666666666666,
      "fiveYear": 0.03284,
      "tenYear": 0.05171999999999999
    }
  },
  "GB00BD3RZ582": {
    "name": "FTSE Global All Cap Index Fund - GBP Acc",
    "description": "FTSE Global All Cap Index Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0023,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1827,
      "threeYear": 0.08323333333333333,
      "fiveYear": 0.11286,
      "tenYear": 0.08646000000000001
    }
  },
  "GB00BD3RZ475": {
    "name": "FTSE Global All Cap Index Fund - GBP Inc",
    "description": "FTSE Global All Cap Index Fund - GBP Inc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0023,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1827,
      "threeYear": 0.08326666666666666,
      "fiveYear": 0.11281999999999999,
      "tenYear": 0.08647999999999999
    }
  },
  "GB00BSMSL150": {
    "name": "FTSE Global All Cap Index Fund - Institutional Plus GBP Acc",
    "description": "FTSE Global All Cap Index Fund - Institutional Plus GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": null,
      "threeYear": 0,
      "fiveYear": 0,
      "tenYear": 0
    }
  },
  "GB00BSMSL267": {
    "name": "FTSE Global All Cap Index Fund - Institutional Plus GBP Inc",
    "description": "FTSE Global All Cap Index Fund - Institutional Plus GBP Inc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": null,
      "threeYear": 0,
      "fiveYear": 0,
      "tenYear": 0
    }
  },
  "IE00BFMXYX26": {
    "name": "FTSE Japan UCITS ETF - (USD) Accumulating",
    "description": "FTSE Japan UCITS ETF - (USD) Accumulating - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0767,
      "threeYear": 0.037566666666666665,
      "fiveYear": 0.05278000000000001,
      "tenYear": 0.026390000000000004
    }
  },
  "IE00B95PGT31": {
    "name": "FTSE Japan UCITS ETF - (USD) Distributing",
    "description": "FTSE Japan UCITS ETF - (USD) Distributing - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0015,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0767,
      "threeYear": 0.037566666666666665,
      "fiveYear": 0.05278000000000001,
      "tenYear": 0.06939000000000001
    }
  },
  "IE00BFMXYY33": {
    "name": "FTSE Japan UCITS ETF - EUR Hedged Accumulating",
    "description": "FTSE Japan UCITS ETF - EUR Hedged Accumulating - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.23420000000000002,
      "threeYear": 0.169,
      "fiveYear": 0.12508,
      "tenYear": 0.06254
    }
  },
  "IE00BFMXZJ56": {
    "name": "FTSE Japan UCITS ETF - USD Hedged Accumulating",
    "description": "FTSE Japan UCITS ETF - USD Hedged Accumulating - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.25489999999999996,
      "threeYear": 0.19416666666666668,
      "fiveYear": 0.14218,
      "tenYear": 0.07109
    }
  },
  "IE00BK5BQW10": {
    "name": "FTSE North America UCITS ETF - (USD) Accumulating",
    "description": "FTSE North America UCITS ETF - (USD) Accumulating - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2427,
      "threeYear": 0.10400000000000002,
      "fiveYear": 0.15498000000000003,
      "tenYear": 0.07749000000000002
    }
  },
  "IE00BKX55R35": {
    "name": "FTSE North America UCITS ETF - (USD) Distributing",
    "description": "FTSE North America UCITS ETF - (USD) Distributing - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.2427,
      "threeYear": 0.10400000000000002,
      "fiveYear": 0.15498000000000003,
      "tenYear": 0.13541000000000003
    }
  },
  "GB00B3X7QG63": {
    "name": "FTSE U.K. All Share Index Unit Trust - GBP Acc",
    "description": "FTSE U.K. All Share Index Unit Trust - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0933,
      "threeYear": 0.05829999999999999,
      "fiveYear": 0.051619999999999985,
      "tenYear": 0.06566999999999998
    }
  },
  "GB00BPN5P782": {
    "name": "FTSE U.K. All Share Index Unit Trust - GBP Inc",
    "description": "FTSE U.K. All Share Index Unit Trust - GBP Inc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0933,
      "threeYear": 0.0582,
      "fiveYear": 0.051539999999999996,
      "tenYear": 0.06558
    }
  },
  "GB00BPN5P907": {
    "name": "FTSE U.K. All Share Index Unit Trust - Institutional Plus GBP Acc",
    "description": "FTSE U.K. All Share Index Unit Trust - Institutional Plus GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0005,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0934,
      "threeYear": 0.05836666666666667,
      "fiveYear": 0.0517,
      "tenYear": 0.06587000000000001
    }
  },
  "GB00BPN5P899": {
    "name": "FTSE U.K. All Share Index Unit Trust - Institutional Plus GBP Inc",
    "description": "FTSE U.K. All Share Index Unit Trust - Institutional Plus GBP Inc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0005,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0934,
      "threeYear": 0.05826666666666667,
      "fiveYear": 0.05162000000000001,
      "tenYear": 0.06576
    }
  },
  "GB00B59G4H82": {
    "name": "FTSE U.K. Equity Income Index Fund - GBP Acc",
    "description": "FTSE U.K. Equity Income Index Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1266,
      "threeYear": 0.08846666666666668,
      "fiveYear": 0.05958000000000001,
      "tenYear": 0.05997000000000001
    }
  },
  "GB00B5B74684": {
    "name": "FTSE U.K. Equity Income Index Fund - GBP Inc",
    "description": "FTSE U.K. Equity Income Index Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0014000000000000002,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1263,
      "threeYear": 0.08823333333333333,
      "fiveYear": 0.05932,
      "tenYear": 0.0598
    }
  },
  "GB00BPN5NW90": {
    "name": "FTSE U.K. Equity Income Index Fund - Institutional Plus GBP Acc",
    "description": "FTSE U.K. Equity Income Index Fund - Institutional Plus GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1268,
      "threeYear": 0.08863333333333334,
      "fiveYear": 0.059800000000000006,
      "tenYear": 0.06049000000000001
    }
  },
  "GB00BPN5NV83": {
    "name": "FTSE U.K. Equity Income Index Fund - Institutional Plus GBP Inc",
    "description": "FTSE U.K. Equity Income Index Fund - Institutional Plus GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1266,
      "threeYear": 0.08846666666666668,
      "fiveYear": 0.05956,
      "tenYear": 0.06032
    }
  },
  "IE00BG143G97": {
    "name": "Germany All Cap UCITS ETF - (EUR) Distributing",
    "description": "Germany All Cap UCITS ETF - (EUR) Distributing - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1623,
      "threeYear": 0.059633333333333344,
      "fiveYear": 0.07,
      "tenYear": 0.059840000000000004
    }
  },
  "GB00BMV9B621": {
    "name": "Global Capital Stewards Equity Fund - GBP Acc",
    "description": "Global Capital Stewards Equity Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0048,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.15439999999999998,
      "threeYear": 0.09563333333333333,
      "fiveYear": 0.05738,
      "tenYear": 0.02869
    }
  },
  "GB00BMV9B514": {
    "name": "Global Capital Stewards Equity Fund - GBP Inc",
    "description": "Global Capital Stewards Equity Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0048,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.15439999999999998,
      "threeYear": 0.0938,
      "fiveYear": 0.05628,
      "tenYear": 0.02814
    }
  },
  "GB00BZ82ZY13": {
    "name": "Global Emerging Markets Fund - GBP Acc",
    "description": "Global Emerging Markets Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0078000000000000005,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.048,
      "threeYear": 0.01736666666666667,
      "fiveYear": 0.03752,
      "tenYear": 0.05636
    }
  },
  "GB00BZ82ZX06": {
    "name": "Global Emerging Markets Fund - GBP Inc",
    "description": "Global Emerging Markets Fund - GBP Inc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0078000000000000005,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0481,
      "threeYear": 0.017099999999999997,
      "fiveYear": 0.037239999999999995,
      "tenYear": 0.05630999999999999
    }
  },
  "GB00BZ82ZT69": {
    "name": "Global Equity Fund - GBP Acc",
    "description": "Global Equity Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0048,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1243,
      "threeYear": 0.05076666666666666,
      "fiveYear": 0.0988,
      "tenYear": 0.0849
    }
  },
  "GB00BZ82ZS52": {
    "name": "Global Equity Fund - GBP Inc",
    "description": "Global Equity Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0048,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1244,
      "threeYear": 0.050800000000000005,
      "fiveYear": 0.09878,
      "tenYear": 0.0849
    }
  },
  "GB00BZ82ZW98": {
    "name": "Global Equity Income Fund - GBP Acc",
    "description": "Global Equity Income Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0048,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.13570000000000002,
      "threeYear": 0.09716666666666667,
      "fiveYear": 0.09925999999999999,
      "tenYear": 0.07011999999999999
    }
  },
  "GB00BZ82ZV81": {
    "name": "Global Equity Income Fund - GBP Inc",
    "description": "Global Equity Income Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0048,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1356,
      "threeYear": 0.09713333333333334,
      "fiveYear": 0.09928000000000001,
      "tenYear": 0.07010999999999999
    }
  },
  "IE00B42W4L06": {
    "name": "Global Small-Cap Index Fund - EUR Acc",
    "description": "Global Small-Cap Index Fund - EUR Acc - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0029,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1521,
      "threeYear": 0.045433333333333346,
      "fiveYear": 0.08848,
      "tenYear": 0.09684
    }
  },
  "IE00BDCXSH02": {
    "name": "Global Small-Cap Index Fund - EUR Dist",
    "description": "Global Small-Cap Index Fund - EUR Dist - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0029,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1521,
      "threeYear": 0.045433333333333346,
      "fiveYear": 0.08848,
      "tenYear": 0.07245
    }
  },
  "IE00B3X1NT05": {
    "name": "Global Small-Cap Index Fund - GBP Acc",
    "description": "Global Small-Cap Index Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0029,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0993,
      "threeYear": 0.0355,
      "fiveYear": 0.07908,
      "tenYear": 0.10303
    }
  },
  "IE00B3X1LS57": {
    "name": "Global Small-Cap Index Fund - GBP Dist",
    "description": "Global Small-Cap Index Fund - GBP Dist - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0029,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0993,
      "threeYear": 0.0355,
      "fiveYear": 0.07908,
      "tenYear": 0.10303
    }
  },
  "IE00BFRTDD83": {
    "name": "Global Small-Cap Index Fund - Institutional Plus EUR Acc",
    "description": "Global Small-Cap Index Fund - Institutional Plus EUR Acc - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1527,
      "threeYear": 0.045966666666666676,
      "fiveYear": 0.08904000000000001,
      "tenYear": 0.09754
    }
  },
  "IE00BPT2BD14": {
    "name": "Global Small-Cap Index Fund - Institutional Plus GBP Acc",
    "description": "Global Small-Cap Index Fund - Institutional Plus GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0999,
      "threeYear": 0.03603333333333333,
      "fiveYear": 0.07962,
      "tenYear": 0.10373999999999999
    }
  },
  "IE00BPT2BC07": {
    "name": "Global Small-Cap Index Fund - Institutional Plus GBP Dist",
    "description": "Global Small-Cap Index Fund - Institutional Plus GBP Dist - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0999,
      "threeYear": 0.03603333333333333,
      "fiveYear": 0.07962,
      "tenYear": 0.10373999999999999
    }
  },
  "IE00B42W3S00": {
    "name": "Global Small-Cap Index Fund - Investor EUR Acc",
    "description": "Global Small-Cap Index Fund - Investor EUR Acc - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0029,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1521,
      "threeYear": 0.045433333333333346,
      "fiveYear": 0.08848,
      "tenYear": 0.09673999999999999
    }
  },
  "IE00B42LF923": {
    "name": "Global Small-Cap Index Fund - USD Acc",
    "description": "Global Small-Cap Index Fund - USD Acc - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0029,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.08,
      "threeYear": 0.016700000000000003,
      "fiveYear": 0.07282,
      "tenYear": 0.08257
    }
  },
  "IE00B03HD191": {
    "name": "Global Stock Index Fund - EUR Acc",
    "description": "Global Stock Index Fund - EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.265,
      "threeYear": 0.11063333333333335,
      "fiveYear": 0.14084000000000002,
      "tenYear": 0.12419
    }
  },
  "IE00B03HD316": {
    "name": "Global Stock Index Fund - EUR Hedged Acc",
    "description": "Global Stock Index Fund - EUR Hedged Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1968,
      "threeYear": 0.07469999999999997,
      "fiveYear": 0.11477999999999997,
      "tenYear": 0.09818999999999999
    }
  },
  "IE00BFPM9N11": {
    "name": "Global Stock Index Fund - Institutional Plus EUR Acc",
    "description": "Global Stock Index Fund - Institutional Plus EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0011,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2659,
      "threeYear": 0.11143333333333334,
      "fiveYear": 0.14164000000000002,
      "tenYear": 0.12514000000000003
    }
  },
  "IE00BYVQ3L68": {
    "name": "Global Stock Index Fund - Institutional Plus GBP Acc",
    "description": "Global Stock Index Fund - Institutional Plus GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0011,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2079,
      "threeYear": 0.09929999999999999,
      "fiveYear": 0.1301,
      "tenYear": 0.08471
    }
  },
  "IE000PBKES17": {
    "name": "Global Stock Index Fund - Institutional Plus GBP Dist",
    "description": "Global Stock Index Fund - Institutional Plus GBP Dist - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0011,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": null,
      "threeYear": 0,
      "fiveYear": 0,
      "tenYear": 0
    }
  },
  "IE00BFPM9M04": {
    "name": "Global Stock Index Fund - Institutional Plus USD Acc",
    "description": "Global Stock Index Fund - Institutional Plus USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0011,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1866,
      "threeYear": 0.08109999999999999,
      "fiveYear": 0.12409999999999999,
      "tenYear": 0.10980999999999999
    }
  },
  "IE00BYVQ3K51": {
    "name": "Global Stock Index Fund - Institutional Plus USD Dist",
    "description": "Global Stock Index Fund - Institutional Plus USD Dist - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0011,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1866,
      "threeYear": 0.08109999999999999,
      "fiveYear": 0.0923,
      "tenYear": 0.04615
    }
  },
  "IE00B03HCZ61": {
    "name": "Global Stock Index Fund - Investor EUR Acc",
    "description": "Global Stock Index Fund - Investor EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.265,
      "threeYear": 0.11063333333333335,
      "fiveYear": 0.14084000000000002,
      "tenYear": 0.12393999999999998
    }
  },
  "IE00B03HD084": {
    "name": "Global Stock Index Fund - Investor USD Acc",
    "description": "Global Stock Index Fund - Investor USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1858,
      "threeYear": 0.08033333333333334,
      "fiveYear": 0.1233,
      "tenYear": 0.10862000000000002
    }
  },
  "IE00B03HD209": {
    "name": "Global Stock Index Fund - USD Acc",
    "description": "Global Stock Index Fund - USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1858,
      "threeYear": 0.08033333333333334,
      "fiveYear": 0.1233,
      "tenYear": 0.10887
    }
  },
  "IE00BF6T7P95": {
    "name": "Global Stock Index Fund - USD Dist",
    "description": "Global Stock Index Fund - USD Dist - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0018,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1858,
      "threeYear": 0.08033333333333334,
      "fiveYear": 0.1233,
      "tenYear": 0.08923
    }
  },
  "IE0007286036": {
    "name": "Japan Stock Index Fund - EUR Acc",
    "description": "Japan Stock Index Fund - EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1536,
      "threeYear": 0.06736666666666667,
      "fiveYear": 0.06864,
      "tenYear": 0.08304
    }
  },
  "IE00B50MZ948": {
    "name": "Japan Stock Index Fund - GBP Acc",
    "description": "Japan Stock Index Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1007,
      "threeYear": 0.057466666666666666,
      "fiveYear": 0.06098,
      "tenYear": 0.08828
    }
  },
  "IE00B51KW525": {
    "name": "Japan Stock Index Fund - GBP Dist",
    "description": "Japan Stock Index Fund - GBP Dist - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1007,
      "threeYear": 0.057466666666666666,
      "fiveYear": 0.06098,
      "tenYear": 0.08829000000000001
    }
  },
  "IE00BFPM9P35": {
    "name": "Japan Stock Index Fund - Institutional Plus EUR Acc",
    "description": "Japan Stock Index Fund - Institutional Plus EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.15410000000000001,
      "threeYear": 0.06780000000000001,
      "fiveYear": 0.06908,
      "tenYear": 0.08341000000000001
    }
  },
  "IE00BPT2BG45": {
    "name": "Japan Stock Index Fund - Institutional Plus GBP Acc",
    "description": "Japan Stock Index Fund - Institutional Plus GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1012,
      "threeYear": 0.0579,
      "fiveYear": 0.061419999999999995,
      "tenYear": 0.08866
    }
  },
  "IE00BPT2BF38": {
    "name": "Japan Stock Index Fund - Institutional Plus GBP Dist",
    "description": "Japan Stock Index Fund - Institutional Plus GBP Dist - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1012,
      "threeYear": 0.0579,
      "fiveYear": 0.061419999999999995,
      "tenYear": 0.08868000000000001
    }
  },
  "IE00BGCC5F53": {
    "name": "Japan Stock Index Fund - Institutional Plus JPY Acc",
    "description": "Japan Stock Index Fund - Institutional Plus JPY Acc - A equity fund denominated in JPY.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.20600000000000002,
      "threeYear": 0.1484,
      "fiveYear": 0.13291999999999998,
      "tenYear": 0.09791999999999998
    }
  },
  "IE00BGCC5335": {
    "name": "Japan Stock Index Fund - Institutional Plus USD Acc",
    "description": "Japan Stock Index Fund - Institutional Plus USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0818,
      "threeYear": 0.039066666666666666,
      "fiveYear": 0.055299999999999995,
      "tenYear": 0.05789
    }
  },
  "IE0007281425": {
    "name": "Japan Stock Index Fund - Investor EUR Acc",
    "description": "Japan Stock Index Fund - Investor EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1536,
      "threeYear": 0.06736666666666667,
      "fiveYear": 0.06864,
      "tenYear": 0.08266
    }
  },
  "IE0033862800": {
    "name": "Japan Stock Index Fund - Investor JPY Acc",
    "description": "Japan Stock Index Fund - Investor JPY Acc - A equity fund denominated in JPY.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.20550000000000002,
      "threeYear": 0.14793333333333333,
      "fiveYear": 0.13243999999999997,
      "tenYear": 0.09717999999999997
    }
  },
  "IE0007292083": {
    "name": "Japan Stock Index Fund - Investor USD Acc",
    "description": "Japan Stock Index Fund - Investor USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0814,
      "threeYear": 0.038666666666666655,
      "fiveYear": 0.05488,
      "tenYear": 0.06876
    }
  },
  "IE0033862917": {
    "name": "Japan Stock Index Fund - JPY Acc",
    "description": "Japan Stock Index Fund - JPY Acc - A equity fund denominated in JPY.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.20550000000000002,
      "threeYear": 0.14793333333333333,
      "fiveYear": 0.13243999999999997,
      "tenYear": 0.08785
    }
  },
  "IE0007292422": {
    "name": "Japan Stock Index Fund - USD Acc",
    "description": "Japan Stock Index Fund - USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0814,
      "threeYear": 0.038666666666666655,
      "fiveYear": 0.05488,
      "tenYear": 0.06910999999999999
    }
  },
  "IE00B0FP7Q41": {
    "name": "Pacific ex-Japan Stock Index Fund - AUD Acc",
    "description": "Pacific ex-Japan Stock Index Fund - AUD Acc - A equity fund denominated in AUD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1514,
      "threeYear": 0.07136666666666668,
      "fiveYear": 0.058940000000000006,
      "tenYear": 0.04721
    }
  },
  "IE0007201266": {
    "name": "Pacific ex-Japan Stock Index Fund - EUR Acc",
    "description": "Pacific ex-Japan Stock Index Fund - EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.11460000000000001,
      "threeYear": 0.04723333333333333,
      "fiveYear": 0.0489,
      "tenYear": 0.06172000000000001
    }
  },
  "IE00B523L313": {
    "name": "Pacific ex-Japan Stock Index Fund - GBP Acc",
    "description": "Pacific ex-Japan Stock Index Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0635,
      "threeYear": 0.041133333333333334,
      "fiveYear": 0.042280000000000005,
      "tenYear": 0.06956
    }
  },
  "IE00B523L081": {
    "name": "Pacific ex-Japan Stock Index Fund - GBP Dist",
    "description": "Pacific ex-Japan Stock Index Fund - GBP Dist - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0635,
      "threeYear": 0.041133333333333334,
      "fiveYear": 0.042280000000000005,
      "tenYear": 0.06956
    }
  },
  "IE00BGCC5G60": {
    "name": "Pacific ex-Japan Stock Index Fund - Institutional Plus EUR Acc",
    "description": "Pacific ex-Japan Stock Index Fund - Institutional Plus EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.115,
      "threeYear": 0.04763333333333333,
      "fiveYear": 0.049300000000000004,
      "tenYear": 0.06208999999999999
    }
  },
  "IE000QXHZ9K3": {
    "name": "Pacific ex-Japan Stock Index Fund - Institutional Plus EUR Dist",
    "description": "Pacific ex-Japan Stock Index Fund - Institutional Plus EUR Dist - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": null,
      "threeYear": 0,
      "fiveYear": 0,
      "tenYear": 0
    }
  },
  "IE00BPT2BJ75": {
    "name": "Pacific ex-Japan Stock Index Fund - Institutional Plus GBP Acc",
    "description": "Pacific ex-Japan Stock Index Fund - Institutional Plus GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0639,
      "threeYear": 0.04153333333333333,
      "fiveYear": 0.0427,
      "tenYear": 0.06994
    }
  },
  "IE00BPT2BH51": {
    "name": "Pacific ex-Japan Stock Index Fund - Institutional Plus GBP Dist",
    "description": "Pacific ex-Japan Stock Index Fund - Institutional Plus GBP Dist - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0639,
      "threeYear": 0.04153333333333333,
      "fiveYear": 0.0427,
      "tenYear": 0.06995
    }
  },
  "IE00BFPM9Q42": {
    "name": "Pacific ex-Japan Stock Index Fund - Institutional Plus USD Acc",
    "description": "Pacific ex-Japan Stock Index Fund - Institutional Plus USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0452,
      "threeYear": 0.015833333333333335,
      "fiveYear": 0.031920000000000004,
      "tenYear": 0.048409999999999995
    }
  },
  "IE0007218849": {
    "name": "Pacific ex-Japan Stock Index Fund - USD Acc",
    "description": "Pacific ex-Japan Stock Index Fund - USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0016,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.044800000000000006,
      "threeYear": 0.015433333333333335,
      "fiveYear": 0.0315,
      "tenYear": 0.04802000000000001
    }
  },
  "IE00BFMXXD54": {
    "name": "S&P 500 UCITS ETF - (USD) Accumulating",
    "description": "S&P 500 UCITS ETF - (USD) Accumulating - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0007000000000000001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2468,
      "threeYear": 0.10746666666666667,
      "fiveYear": 0.15728000000000003,
      "tenYear": 0.07864000000000002
    }
  },
  "IE00B3XXRP09": {
    "name": "S&P 500 UCITS ETF - (USD) Distributing",
    "description": "S&P 500 UCITS ETF - (USD) Distributing - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0007000000000000001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.2468,
      "threeYear": 0.10746666666666667,
      "fiveYear": 0.15728000000000003,
      "tenYear": 0.13877
    }
  },
  "IE0032126645": {
    "name": "U.S. 500 Stock Index Fund - EUR Acc",
    "description": "U.S. 500 Stock Index Fund - EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.327,
      "threeYear": 0.13593333333333335,
      "fiveYear": 0.17306000000000002,
      "tenYear": 0.15223
    }
  },
  "IE00B1G3DH73": {
    "name": "U.S. 500 Stock Index Fund - EUR Hedged Acc",
    "description": "U.S. 500 Stock Index Fund - EUR Hedged Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2232,
      "threeYear": 0.07783333333333334,
      "fiveYear": 0.13130000000000003,
      "tenYear": 0.11314000000000002
    }
  },
  "IE00BFPM9V94": {
    "name": "U.S. 500 Stock Index Fund - Institutional Plus EUR Acc",
    "description": "U.S. 500 Stock Index Fund - Institutional Plus EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.3275,
      "threeYear": 0.13636666666666666,
      "fiveYear": 0.17350000000000002,
      "tenYear": 0.15266000000000002
    }
  },
  "IE00BFPM9T72": {
    "name": "U.S. 500 Stock Index Fund - Institutional Plus USD Acc",
    "description": "U.S. 500 Stock Index Fund - Institutional Plus USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2444,
      "threeYear": 0.10506666666666664,
      "fiveYear": 0.15469999999999998,
      "tenYear": 0.136
    }
  },
  "IE0032620787": {
    "name": "U.S. 500 Stock Index Fund - Investor EUR Acc",
    "description": "U.S. 500 Stock Index Fund - Investor EUR Acc - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.327,
      "threeYear": 0.13593333333333335,
      "fiveYear": 0.17306000000000002,
      "tenYear": 0.15142
    }
  },
  "IE0002639668": {
    "name": "U.S. 500 Stock Index Fund - Investor USD Acc",
    "description": "U.S. 500 Stock Index Fund - Investor USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2439,
      "threeYear": 0.10459999999999998,
      "fiveYear": 0.15424,
      "tenYear": 0.13474999999999998
    }
  },
  "IE0002639775": {
    "name": "U.S. 500 Stock Index Fund - USD Acc",
    "description": "U.S. 500 Stock Index Fund - USD Acc - A equity fund denominated in USD.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2439,
      "threeYear": 0.10459999999999998,
      "fiveYear": 0.15424,
      "tenYear": 0.13554
    }
  },
  "IE00B4NDXJ38": {
    "name": "U.S. Equity Index Common Contractual Fund - Accumulation",
    "description": "U.S. Equity Index Common Contractual Fund - Accumulation - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.002,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.2699,
      "threeYear": 0.12646666666666664,
      "fiveYear": 0.16424,
      "tenYear": 0.16101000000000001
    }
  },
  "GB00B5B71Q71": {
    "name": "U.S. Equity Index Fund - GBP Acc",
    "description": "U.S. Equity Index Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.25730000000000003,
      "threeYear": 0.1156,
      "fiveYear": 0.15555999999999998,
      "tenYear": 0.15344
    }
  },
  "GB00B5B74S01": {
    "name": "U.S. Equity Index Fund - GBP Inc",
    "description": "U.S. Equity Index Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.001,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.2572,
      "threeYear": 0.11556666666666665,
      "fiveYear": 0.15555999999999998,
      "tenYear": 0.15343
    }
  },
  "GB00BPN5P238": {
    "name": "U.S. Equity Index Fund - Institutional Plus GBP Acc",
    "description": "U.S. Equity Index Fund - Institutional Plus GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.2577,
      "threeYear": 0.11599999999999999,
      "fiveYear": 0.15598,
      "tenYear": 0.15389999999999998
    }
  },
  "GB00BPN5P121": {
    "name": "U.S. Equity Index Fund - Institutional Plus GBP Inc",
    "description": "U.S. Equity Index Fund - Institutional Plus GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0006,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.2577,
      "threeYear": 0.11596666666666666,
      "fiveYear": 0.15598,
      "tenYear": 0.15387
    }
  },
  "IE00B03HCY54": {
    "name": "U.S. Opportunities Fund - Institutional USD Acc",
    "description": "U.S. Opportunities Fund - Institutional USD Acc - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0085,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1164,
      "threeYear": 0.06336666666666667,
      "fiveYear": 0.11338,
      "tenYear": 0.12402000000000002
    }
  },
  "IE00B03HCV24": {
    "name": "U.S. Opportunities Fund - Investor EUR Acc",
    "description": "U.S. Opportunities Fund - Investor EUR Acc - A other fund denominated in EUR.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0095,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.18969999999999998,
      "threeYear": 0.09059999999999997,
      "fiveYear": 0.1265,
      "tenYear": 0.13652
    }
  },
  "IE00B1GHC616": {
    "name": "U.S. Opportunities Fund - Investor GBP Dist",
    "description": "U.S. Opportunities Fund - Investor GBP Dist - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0095,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1352,
      "threeYear": 0.07933333333333333,
      "fiveYear": 0.11706000000000001,
      "tenYear": 0.14294
    }
  },
  "IE00B03HCW31": {
    "name": "U.S. Opportunities Fund - Investor USD Acc",
    "description": "U.S. Opportunities Fund - Investor USD Acc - A other fund denominated in USD.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0095,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1153,
      "threeYear": 0.0623,
      "fiveYear": 0.11226,
      "tenYear": 0.12289999999999998
    }
  },
  "GB00BMCQRZ38": {
    "name": "ActiveLife Climate Aware 40-50% Equity Fund - GBP Acc",
    "description": "ActiveLife Climate Aware 40-50% Equity Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0048,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.060899999999999996,
      "threeYear": 0.027000000000000007,
      "fiveYear": 0.016200000000000003,
      "tenYear": 0.008100000000000001
    }
  },
  "GB00BMCQRX14": {
    "name": "ActiveLife Climate Aware 40-50% Equity Fund - GBP Inc",
    "description": "ActiveLife Climate Aware 40-50% Equity Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0048,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0608,
      "threeYear": 0.02703333333333333,
      "fiveYear": 0.01622,
      "tenYear": 0.00811
    }
  },
  "GB00BZ830054": {
    "name": "ActiveLife Climate Aware 60-70% Equity Fund - GBP Acc",
    "description": "ActiveLife Climate Aware 60-70% Equity Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0048,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0753,
      "threeYear": 0.04646666666666666,
      "fiveYear": 0.06355999999999999,
      "tenYear": 0.05445
    }
  },
  "GB00BZ82ZZ20": {
    "name": "ActiveLife Climate Aware 60-70% Equity Fund - GBP Inc",
    "description": "ActiveLife Climate Aware 60-70% Equity Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0048,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0753,
      "threeYear": 0.04650000000000001,
      "fiveYear": 0.06358,
      "tenYear": 0.054479999999999994
    }
  },
  "GB00BMCQS161": {
    "name": "ActiveLife Climate Aware 80-90% Equity Fund - GBP Acc",
    "description": "ActiveLife Climate Aware 80-90% Equity Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0048,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.08990000000000001,
      "threeYear": 0.0667,
      "fiveYear": 0.04002,
      "tenYear": 0.02001
    }
  },
  "GB00BMCQS054": {
    "name": "ActiveLife Climate Aware 80-90% Equity Fund - GBP Inc",
    "description": "ActiveLife Climate Aware 80-90% Equity Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0048,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.09,
      "threeYear": 0.06686666666666667,
      "fiveYear": 0.04012,
      "tenYear": 0.02006
    }
  },
  "GB00B41XG308": {
    "name": "LifeStrategy® 100% Equity Fund - GBP Acc",
    "description": "LifeStrategy® 100% Equity Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0022,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.168,
      "threeYear": 0.08006666666666667,
      "fiveYear": 0.10078000000000001,
      "tenYear": 0.10920999999999999
    }
  },
  "GB00B545NX97": {
    "name": "LifeStrategy® 100% Equity Fund - GBP Inc",
    "description": "LifeStrategy® 100% Equity Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0022,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.168,
      "threeYear": 0.08006666666666667,
      "fiveYear": 0.10078000000000001,
      "tenYear": 0.10920999999999999
    }
  },
  "GB00B4NXY349": {
    "name": "LifeStrategy® 20% Equity Fund - GBP Acc",
    "description": "LifeStrategy® 20% Equity Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0022,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.032400000000000005,
      "threeYear": -0.018099999999999995,
      "fiveYear": 0.007820000000000002,
      "tenYear": 0.02848
    }
  },
  "GB00B4620290": {
    "name": "LifeStrategy® 20% Equity Fund - GBP Inc",
    "description": "LifeStrategy® 20% Equity Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0022,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0323,
      "threeYear": -0.018133333333333324,
      "fiveYear": 0.007800000000000004,
      "tenYear": 0.028460000000000003
    }
  },
  "IE00BMVB5K07": {
    "name": "LifeStrategy® 20% Equity UCITS ETF - (EUR) Accumulating",
    "description": "LifeStrategy® 20% Equity UCITS ETF - (EUR) Accumulating - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.051,
      "threeYear": -0.01099999999999999,
      "fiveYear": -0.0016199999999999943,
      "tenYear": -0.0008099999999999971
    }
  },
  "IE00BMVB5L14": {
    "name": "LifeStrategy® 20% Equity UCITS ETF - (EUR) Distributing",
    "description": "LifeStrategy® 20% Equity UCITS ETF - (EUR) Distributing - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.051,
      "threeYear": -0.01099999999999999,
      "fiveYear": -0.0016199999999999943,
      "tenYear": -0.0008099999999999971
    }
  },
  "GB00B3ZHN960": {
    "name": "LifeStrategy® 40% Equity Fund - GBP Acc",
    "description": "LifeStrategy® 40% Equity Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0022,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0608,
      "threeYear": 0.003066666666666662,
      "fiveYear": 0.028720000000000002,
      "tenYear": 0.047200000000000006
    }
  },
  "GB00B41F6L43": {
    "name": "LifeStrategy® 40% Equity Fund - GBP Inc",
    "description": "LifeStrategy® 40% Equity Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0022,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0608,
      "threeYear": 0.003066666666666662,
      "fiveYear": 0.028720000000000002,
      "tenYear": 0.047200000000000006
    }
  },
  "IE00BMVB5M21": {
    "name": "LifeStrategy® 40% Equity UCITS ETF - (EUR) Accumulating",
    "description": "LifeStrategy® 40% Equity UCITS ETF - (EUR) Accumulating - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0981,
      "threeYear": 0.016433333333333328,
      "fiveYear": 0.02642,
      "tenYear": 0.01321
    }
  },
  "IE00BMVB5N38": {
    "name": "LifeStrategy® 40% Equity UCITS ETF - (EUR) Distributing",
    "description": "LifeStrategy® 40% Equity UCITS ETF - (EUR) Distributing - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0981,
      "threeYear": 0.016433333333333328,
      "fiveYear": 0.02642,
      "tenYear": 0.01321
    }
  },
  "GB00B3TYHH97": {
    "name": "LifeStrategy® 60% Equity Fund - GBP Acc",
    "description": "LifeStrategy® 60% Equity Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0022,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0965,
      "threeYear": 0.02856666666666667,
      "fiveYear": 0.05267999999999999,
      "tenYear": 0.06794999999999998
    }
  },
  "GB00B4R2F348": {
    "name": "LifeStrategy® 60% Equity Fund - GBP Inc",
    "description": "LifeStrategy® 60% Equity Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0022,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0965,
      "threeYear": 0.02856666666666667,
      "fiveYear": 0.05267999999999999,
      "tenYear": 0.06793999999999999
    }
  },
  "IE00BMVB5P51": {
    "name": "LifeStrategy® 60% Equity UCITS ETF - (EUR) Accumulating",
    "description": "LifeStrategy® 60% Equity UCITS ETF - (EUR) Accumulating - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1472,
      "threeYear": 0.044466666666666675,
      "fiveYear": 0.055360000000000006,
      "tenYear": 0.027680000000000003
    }
  },
  "IE00BMVB5Q68": {
    "name": "LifeStrategy® 60% Equity UCITS ETF - (EUR) Distributing",
    "description": "LifeStrategy® 60% Equity UCITS ETF - (EUR) Distributing - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.1472,
      "threeYear": 0.044466666666666675,
      "fiveYear": 0.055360000000000006,
      "tenYear": 0.027680000000000003
    }
  },
  "GB00B4PQW151": {
    "name": "LifeStrategy® 80% Equity Fund - GBP Acc",
    "description": "LifeStrategy® 80% Equity Fund - GBP Acc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0022,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.132,
      "threeYear": 0.05406666666666667,
      "fiveYear": 0.07668,
      "tenYear": 0.08850999999999999
    }
  },
  "GB00B4KWNF91": {
    "name": "LifeStrategy® 80% Equity Fund - GBP Inc",
    "description": "LifeStrategy® 80% Equity Fund - GBP Inc - A equity fund denominated in GBP.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0022,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.132,
      "threeYear": 0.05406666666666667,
      "fiveYear": 0.07668,
      "tenYear": 0.08850999999999999
    }
  },
  "IE00BMVB5R75": {
    "name": "LifeStrategy® 80% Equity UCITS ETF - (EUR) Accumulating",
    "description": "LifeStrategy® 80% Equity UCITS ETF - (EUR) Accumulating - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.19870000000000002,
      "threeYear": 0.0732,
      "fiveYear": 0.08538000000000001,
      "tenYear": 0.042690000000000006
    }
  },
  "IE00BMVB5S82": {
    "name": "LifeStrategy® 80% Equity UCITS ETF - (EUR) Distributing",
    "description": "LifeStrategy® 80% Equity UCITS ETF - (EUR) Distributing - A equity fund denominated in EUR.",
    "category": "Equity",
    "assetType": "Pure Equity",
    "riskLevel": "Moderate-High",
    "expenseRatio": 0.0025,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.19870000000000002,
      "threeYear": 0.0732,
      "fiveYear": 0.08538000000000001,
      "tenYear": 0.042690000000000006
    }
  },
  "GB00BZ6VHV15": {
    "name": "Target Retirement 2015 Fund - GBP Acc",
    "description": "Target Retirement 2015 Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0582,
      "threeYear": 0.016500000000000004,
      "fiveYear": 0.032580000000000005,
      "tenYear": 0.046579999999999996
    }
  },
  "GB00BZ6VJ593": {
    "name": "Target Retirement 2020 Fund - GBP Acc",
    "description": "Target Retirement 2020 Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.0703,
      "threeYear": 0.021733333333333337,
      "fiveYear": 0.04198,
      "tenYear": 0.05714
    }
  },
  "GB00BZ6VJB58": {
    "name": "Target Retirement 2025 Fund - GBP Acc",
    "description": "Target Retirement 2025 Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.08869999999999999,
      "threeYear": 0.02816666666666666,
      "fiveYear": 0.05045999999999999,
      "tenYear": 0.0641
    }
  },
  "GB00BZ6VJD72": {
    "name": "Target Retirement 2030 Fund - GBP Acc",
    "description": "Target Retirement 2030 Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.10039999999999999,
      "threeYear": 0.03416666666666667,
      "fiveYear": 0.05664,
      "tenYear": 0.06961
    }
  },
  "GB00BZ6VJH11": {
    "name": "Target Retirement 2035 Fund - GBP Acc",
    "description": "Target Retirement 2035 Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.109,
      "threeYear": 0.04036666666666667,
      "fiveYear": 0.06268,
      "tenYear": 0.07448999999999999
    }
  },
  "GB00BZ6VK781": {
    "name": "Target Retirement 2040 Fund - GBP Acc",
    "description": "Target Retirement 2040 Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.11779999999999999,
      "threeYear": 0.046566666666666666,
      "fiveYear": 0.06861999999999999,
      "tenYear": 0.07955
    }
  },
  "GB00BZ6VK898": {
    "name": "Target Retirement 2045 Fund - GBP Acc",
    "description": "Target Retirement 2045 Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1266,
      "threeYear": 0.05253333333333333,
      "fiveYear": 0.07472,
      "tenYear": 0.08357
    }
  },
  "GB00BZ6VKT06": {
    "name": "Target Retirement 2050 Fund - GBP Acc",
    "description": "Target Retirement 2050 Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1322,
      "threeYear": 0.05586666666666667,
      "fiveYear": 0.07716,
      "tenYear": 0.08481
    }
  },
  "GB00BZ6VKV28": {
    "name": "Target Retirement 2055 Fund - GBP Acc",
    "description": "Target Retirement 2055 Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1324,
      "threeYear": 0.05586666666666667,
      "fiveYear": 0.07702,
      "tenYear": 0.08511
    }
  },
  "GB00BF38WW17": {
    "name": "Target Retirement 2060 Fund - GBP Acc",
    "description": "Target Retirement 2060 Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1322,
      "threeYear": 0.056033333333333345,
      "fiveYear": 0.07724,
      "tenYear": 0.05249
    }
  },
  "GB00BF38WX24": {
    "name": "Target Retirement 2065 Fund - GBP Acc",
    "description": "Target Retirement 2065 Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0024,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.1324,
      "threeYear": 0.05593333333333333,
      "fiveYear": 0.07722,
      "tenYear": 0.052500000000000005
    }
  },
  "GB00BFYDWM59": {
    "name": "Sterling Short-Term Money Market Fund - GBP Acc",
    "description": "Sterling Short-Term Money Market Fund - GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": null,
      "threeYear": 0,
      "fiveYear": 0,
      "tenYear": 0
    }
  },
  "GB00BGB6GZ57": {
    "name": "Sterling Short-Term Money Market Fund - GBP Inc",
    "description": "Sterling Short-Term Money Market Fund - GBP Inc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0012,
    "dividendYield": 0,
    "accumulationFund": false,
    "historicalReturns": {
      "oneYear": 0.0512,
      "threeYear": 0.036333333333333336,
      "fiveYear": 0.022380000000000004,
      "tenYear": 0.011190000000000002
    }
  },
  "GB00BFYDWN66": {
    "name": "Sterling Short-Term Money Market Fund - Institutional Plus GBP Acc",
    "description": "Sterling Short-Term Money Market Fund - Institutional Plus GBP Acc - A other fund denominated in GBP.",
    "category": "Other",
    "assetType": "Blended",
    "riskLevel": "Moderate",
    "expenseRatio": 0.0008,
    "dividendYield": 0,
    "accumulationFund": true,
    "historicalReturns": {
      "oneYear": 0.051399999999999994,
      "threeYear": 0.0367,
      "fiveYear": 0.022439999999999998,
      "tenYear": 0.011219999999999999
    }
  }
};
