import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  ThemeProvider,
  createTheme,
  Grid,
  Chip,
  Stack,
  CircularProgress,
  IconButton,
  Tooltip,
  Alert,
  LinearProgress,
  Collapse,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import TimelineIcon from '@mui/icons-material/Timeline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, ResponsiveContainer, Area, AreaChart } from 'recharts';
import { marketDataService } from '../services/marketDataService';

// Theme configuration with modern design
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
    },
    secondary: {
      main: '#66bb6a',
    },
    background: {
      default: '#f8fafc',
      paper: '#ffffff',
    },
    error: {
      main: '#ef5350',
      light: '#ffcdd2',
    },
    warning: {
      main: '#ffa726',
      light: '#ffe0b2',
    },
    success: {
      main: '#66bb6a',
      light: '#c8e6c9',
    },
    info: {
      main: '#42a5f5',
      light: '#bbdefb',
    }
  },
  shape: {
    borderRadius: 12
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          boxShadow: '0 2px 12px 0 rgba(0,0,0,0.05)'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: 8
        }
      }
    }
  }
});

// Market trend monitoring constants with updated thresholds
const TREND_THRESHOLDS = {
  BEAR_MARKET: {
    threshold: -0.15,
    description: 'Bear market conditions detected',
    severity: 'high',
    icon: <TrendingDownIcon />,
    color: 'error.main'
  },
  BULL_MARKET: {
    threshold: 0.15,
    description: 'Bull market momentum',
    severity: 'low',
    icon: <TrendingUpIcon />,
    color: 'success.main'
  },
  RECESSION_RISK: {
    threshold: -0.10,
    description: 'Recession indicators present',
    severity: 'high',
    icon: <WarningIcon />,
    color: 'warning.main'
  },
  INTEREST_RATE_IMPACT: {
    threshold: -0.05,
    description: 'Interest rate sensitivity',
    severity: 'medium',
    icon: <InfoIcon />,
    color: 'info.main'
  }
};

// Add new constants for market shift detection
const SHIFT_THRESHOLDS = {
  RAPID_CHANGE: 0.05, // 5% change in a short period
  TREND_REVERSAL: -0.02, // -2% reversal from previous trend
  VOLATILITY_SPIKE: 0.08, // 8% increase in volatility
};

// Add suggestion explanations
const SUGGESTION_EXPLANATIONS = {
  'Consider increasing positions in growing sectors': {
    title: 'Growing Sectors Strategy',
    explanation: 'When the market is showing strong positive signs, it might be a good time to invest more in sectors that are performing well. This is like backing the winning horse - but remember to only invest what you can afford to lose!'
  },
  'Consider implementing protective stops or hedging strategies': {
    title: 'Protection Strategy',
    explanation: 'When the market looks risky, you can protect your investments by setting automatic sell points (stops) or using other investments that go up when your main investments go down (hedging). Think of it like having an insurance policy for your investments.'
  },
  'Consider rebalancing portfolio to defensive positions': {
    title: 'Defensive Strategy',
    explanation: 'Moving your money to safer investments like bonds or stable companies that sell essential products. This is like moving to higher ground when you see storm clouds gathering - you might miss some sun, but you\'ll stay dry if it rains!'
  },
  'Consider reducing position sizes and increasing diversification': {
    title: 'Risk Management Strategy',
    explanation: 'Instead of putting all your eggs in one basket, spread your investments across different types of assets. This way, if one investment performs poorly, the others might help balance it out. It\'s like having a backup plan for your backup plan!'
  }
};

// Market trend analysis function with live data integration
const analyzeMarketTrends = (funds) => {
  const trends = {
    bearMarketSignals: [],
    bullMarketSignals: [],
    recessionSignals: [],
    interestRateImpacts: [],
    lastUpdated: new Date().toISOString()
  };

  Object.entries(funds).forEach(([isin, fund]) => {
    const oneYearReturn = fund.historicalReturns?.oneYear || 0;
    const threeYearReturn = fund.historicalReturns?.threeYear || 0;
    const fiveYearReturn = fund.historicalReturns?.fiveYear || 0;

    // Enhanced trend detection
    if (oneYearReturn < TREND_THRESHOLDS.BEAR_MARKET.threshold) {
      trends.bearMarketSignals.push({
        fund: fund.name,
        isin,
        return: oneYearReturn,
        momentum: calculateMomentum(oneYearReturn, threeYearReturn),
        description: TREND_THRESHOLDS.BEAR_MARKET.description
      });
    }

    if (oneYearReturn > TREND_THRESHOLDS.BULL_MARKET.threshold) {
      trends.bullMarketSignals.push({
        fund: fund.name,
        isin,
        return: oneYearReturn,
        momentum: calculateMomentum(oneYearReturn, threeYearReturn),
        description: TREND_THRESHOLDS.BULL_MARKET.description
      });
    }

    if (oneYearReturn < TREND_THRESHOLDS.RECESSION_RISK.threshold && 
        threeYearReturn < TREND_THRESHOLDS.RECESSION_RISK.threshold) {
      trends.recessionSignals.push({
        fund: fund.name,
        isin,
        returns: { oneYear: oneYearReturn, threeYear: threeYearReturn },
        severity: calculateSeverity(oneYearReturn, threeYearReturn),
        description: TREND_THRESHOLDS.RECESSION_RISK.description
      });
    }

    if (oneYearReturn < TREND_THRESHOLDS.INTEREST_RATE_IMPACT.threshold && 
        fund.category === 'Fixed Income') {
      trends.interestRateImpacts.push({
        fund: fund.name,
        isin,
        return: oneYearReturn,
        sensitivity: calculateSensitivity(oneYearReturn, fund.category),
        description: TREND_THRESHOLDS.INTEREST_RATE_IMPACT.description
      });
    }
  });

  return trends;
};

// Helper functions for trend analysis
const calculateMomentum = (oneYearReturn, threeYearReturn) => {
  return ((oneYearReturn - threeYearReturn) / Math.abs(threeYearReturn || 1)) * 100;
};

const calculateSeverity = (oneYearReturn, threeYearReturn) => {
  const severity = (Math.abs(oneYearReturn) + Math.abs(threeYearReturn)) / 2;
  return severity > 0.2 ? 'High' : severity > 0.1 ? 'Medium' : 'Low';
};

const calculateSensitivity = (return_value, category) => {
  const baseSensitivity = Math.abs(return_value) * 100;
  return category === 'Fixed Income' ? baseSensitivity * 1.5 : baseSensitivity;
};

// Add new helper function for shift detection
const detectMarketShifts = (signals = [], previousSignals = []) => {
  const shifts = {
    warnings: [],
    suggestions: [],
    severity: 'low'
  };

  // If no signals, return empty shifts
  if (!signals.length) {
    return shifts;
  }

  // Calculate average momentum change
  const currentMomentum = signals.reduce((acc, s) => acc + (s.momentum || 0), 0) / signals.length;
  const previousMomentum = previousSignals.length 
    ? previousSignals.reduce((acc, s) => acc + (s.momentum || 0), 0) / previousSignals.length 
    : 0;
  const momentumChange = currentMomentum - previousMomentum;

  // Detect rapid changes
  if (Math.abs(momentumChange) > SHIFT_THRESHOLDS.RAPID_CHANGE) {
    shifts.warnings.push({
      type: 'rapid-change',
      message: `Rapid ${momentumChange > 0 ? 'positive' : 'negative'} momentum shift detected`,
      severity: 'high'
    });
    shifts.suggestions.push({
      type: momentumChange > 0 ? 'opportunity' : 'protection',
      message: momentumChange > 0 
        ? 'Consider increasing positions in growing sectors'
        : 'Consider implementing protective stops or hedging strategies'
    });
    shifts.severity = 'high';
  }

  // Detect trend reversals
  if (momentumChange < SHIFT_THRESHOLDS.TREND_REVERSAL) {
    shifts.warnings.push({
      type: 'trend-reversal',
      message: 'Potential trend reversal forming',
      severity: 'medium'
    });
    shifts.suggestions.push({
      type: 'rebalance',
      message: 'Consider rebalancing portfolio to defensive positions'
    });
    shifts.severity = Math.max(shifts.severity === 'high' ? 2 : 1, 1);
  }

  // Detect volatility spikes (with safe array handling)
  if (signals.length && previousSignals.length) {
    const currentVolatility = Math.std(signals.map(s => s.return || 0));
    const previousVolatility = Math.std(previousSignals.map(s => s.return || 0));
    
    if (previousVolatility !== 0 && 
        (currentVolatility - previousVolatility) / previousVolatility > SHIFT_THRESHOLDS.VOLATILITY_SPIKE) {
      shifts.warnings.push({
        type: 'volatility-spike',
        message: 'Increased market volatility detected',
        severity: 'medium'
      });
      shifts.suggestions.push({
        type: 'risk-management',
        message: 'Consider reducing position sizes and increasing diversification'
      });
    }
  }

  return shifts;
};

// Update Math.std helper with safe array handling
Math.std = function(array = []) {
  if (!array.length) return 0;
  
  const n = array.length;
  const mean = array.reduce((a, b) => a + (b || 0), 0) / n;
  return Math.sqrt(array.map(x => Math.pow((x || 0) - mean, 2)).reduce((a, b) => a + b, 0) / n);
};

// Market Trends Report Component with modern design and stacked performance bar
const MarketTrendsReport = ({ trends, onRefresh, loading }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedMarketType, setSelectedMarketType] = useState(null);
  const [previousSignals, setPreviousSignals] = useState({
    bull: [],
    bear: []
  });

  // Calculate market shifts from real data
  const marketShifts = useMemo(() => ({
    bull: detectMarketShifts(trends.bullMarketSignals, previousSignals.bull),
    bear: detectMarketShifts(trends.bearMarketSignals, previousSignals.bear)
  }), [trends, previousSignals]);

  // Add recession and interest rate warnings
  const additionalWarnings = useMemo(() => {
    const warnings = [];
    const suggestions = [];

    // Add recession warnings
    if (trends.recessionSignals?.length > 0) {
      warnings.push({
        type: 'recession-risk',
        message: `Recession risk detected across ${trends.recessionSignals.length} funds`,
        severity: 'high',
        timestamp: new Date().toLocaleTimeString()
      });
      suggestions.push({
        type: 'defensive',
        message: 'Consider moving to defensive assets like government bonds'
      });
    }

    // Add interest rate impact warnings
    if (trends.interestRateImpacts?.length > 0) {
      warnings.push({
        type: 'interest-rate',
        message: `Interest rate sensitivity detected in ${trends.interestRateImpacts.length} funds`,
        severity: 'medium',
        timestamp: new Date().toLocaleTimeString()
      });
      suggestions.push({
        type: 'rate-hedge',
        message: 'Consider shorter duration bonds or floating rate securities'
      });
    }

    return { warnings, suggestions };
  }, [trends]);

  // Combine all real warnings and suggestions
  const allWarnings = useMemo(() => ({
    warnings: [
      ...marketShifts.bull.warnings,
      ...marketShifts.bear.warnings,
      ...additionalWarnings.warnings
    ],
    suggestions: [
      ...marketShifts.bull.suggestions,
      ...marketShifts.bear.suggestions,
      ...additionalWarnings.suggestions
    ]
  }), [marketShifts, additionalWarnings]);

  // Update previous signals when trends change
  useEffect(() => {
    setPreviousSignals({
      bull: trends.bullMarketSignals,
      bear: trends.bearMarketSignals
    });
  }, [trends]);

  // Add Warning and Suggestions Panel
  const renderWarningsPanel = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);

    const handleSuggestionClick = (suggestion) => {
      setSelectedSuggestion(suggestion);
      setOpenDialog(true);
    };

    // Only show panel if there are actual warnings
    if (!allWarnings.warnings.length && !allWarnings.suggestions.length) {
      return null;
    }

    return (
      <>
        <Paper 
          elevation={0}
          sx={{ 
            p: 3,
            mb: 3,
            bgcolor: '#fffbeb',
            display: 'block',
            borderRadius: 2,
            border: '2px solid',
            borderColor: '#854d0e'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant="h6" fontWeight="bold" sx={{ color: '#854d0e' }}>
              Market Shift Alerts
            </Typography>
            <ErrorOutlineIcon sx={{ color: '#854d0e', fontSize: 28 }} />
          </Box>
          
          {/* Real Warnings */}
          <Stack spacing={2}>
            {allWarnings.warnings.map((warning, index) => (
              <Alert 
                key={index} 
                severity="warning"
                icon={<ErrorOutlineIcon sx={{ color: 'black' }} />}
                sx={{ 
                  bgcolor: '#fef9c3',
                  color: 'black',
                  border: '1px solid',
                  borderColor: '#854d0e',
                  '& .MuiAlert-message': { 
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center'
                  }
                }}
              >
                <Box>
                  <Typography variant="subtitle2" fontWeight="bold" color="black">
                    {warning.message}
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'rgba(0,0,0,0.7)' }}>
                    Detected {warning.timestamp}
                  </Typography>
                </Box>
              </Alert>
            ))}
          </Stack>

          {/* Real Suggestions */}
          {allWarnings.suggestions.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ color: '#854d0e' }} gutterBottom>
                Recommended Actions
              </Typography>
              <Stack spacing={1}>
                {allWarnings.suggestions.map((suggestion, index) => (
                  <Box 
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      bgcolor: '#fef9c3',
                      p: 1.5,
                      borderRadius: 1,
                      cursor: 'pointer',
                      border: '1px solid',
                      borderColor: '#854d0e',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        bgcolor: '#fef08a',
                        transform: 'translateY(-1px)'
                      }
                    }}
                  >
                    <InfoIcon sx={{ color: '#854d0e' }} fontSize="small" />
                    <Typography variant="body2" fontWeight="medium" sx={{ color: 'black' }}>
                      {suggestion.message}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Box>
          )}
        </Paper>

        {/* Explanation Dialog */}
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          {selectedSuggestion && (
            <>
              <DialogTitle sx={{ 
                bgcolor: '#fffbeb',
                color: '#854d0e',
                fontWeight: 'bold'
              }}>
                {SUGGESTION_EXPLANATIONS[selectedSuggestion.message]?.title || 'Investment Strategy'}
              </DialogTitle>
              <DialogContent sx={{ bgcolor: '#fffbeb', mt: 1 }}>
                <DialogContentText sx={{ color: 'black' }}>
                  {SUGGESTION_EXPLANATIONS[selectedSuggestion.message]?.explanation || 
                   'This strategy helps protect your investments during changing market conditions.'}
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ bgcolor: '#fffbeb', p: 2 }}>
                <Button 
                  onClick={() => setOpenDialog(false)}
                  sx={{ 
                    color: '#854d0e',
                    '&:hover': {
                      bgcolor: '#fef9c3'
                    }
                  }}
                >
                  Got it
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </>
    );
  };

  return (
    <Box sx={{ width: '100%', mb: 4 }}>
      <Box sx={{ 
        display: 'flex',
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3
      }}>
          <Typography variant="h4" sx={{ 
          fontWeight: 700,
            background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}>
          <TimelineIcon sx={{ fontSize: 32 }} /> Market Trend Analysis
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="caption" color="text.secondary">
            Last updated: {new Date(trends.lastUpdated).toLocaleTimeString()}
          </Typography>
          <Tooltip title="Refresh Data">
            <IconButton onClick={onRefresh} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : <RefreshIcon />}
            </IconButton>
                </Tooltip>
              </Box>
      </Box>

      {loading && <LinearProgress sx={{ mb: 2 }} />}
              
      {/* Stacked Performance Bar */}
                    <Paper 
        elevation={0}
                      sx={{ 
          p: 3,
          mb: 3,
          position: 'relative',
          overflow: 'hidden',
          transition: 'transform 0.2s'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6" fontWeight="bold">Market Performance Distribution</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Typography variant="caption" color="text.secondary">
              {trends.bullMarketSignals.length > trends.bearMarketSignals.length ? 'Bull Market Growing' : 'Bear Market Growing'}
                          </Typography>
            <IconButton 
                              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setShowDetails(!showDetails);
                setSelectedMarketType(null);
              }}
            >
              {showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
                          </Box>
        
        <Box sx={{ 
          height: 80, 
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 1,
          overflow: 'hidden',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          position: 'relative'
        }}>
          {/* Bull Market Bar */}
          <Box
                        onClick={() => {
              setShowDetails(true);
              setSelectedMarketType('bull');
            }}
            sx={{ 
              width: `${trends.bullMarketSignals.length > 0 ? (trends.bullMarketSignals.length / (trends.bullMarketSignals.length + trends.bearMarketSignals.length)) * 100 : 0}%`,
              height: '50%',
              bgcolor: 'success.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'all 0.5s ease',
              opacity: 0.6 + Math.min(Math.abs((trends.bullMarketSignals.reduce((acc, signal) => acc + signal.return, 0) / (trends.bullMarketSignals.length || 1))), 0.4),
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.9,
                transform: 'translateY(-2px)'
              }
            }}
          >
            <Typography variant="body2" color="white" fontWeight="bold">
              {trends.bullMarketSignals.length > 0 ? `${((trends.bullMarketSignals.length / (trends.bullMarketSignals.length + trends.bearMarketSignals.length)) * 100).toFixed(1)}% Bull` : 'No Bull Market Signals'}
            </Typography>
          </Box>
          
          {/* Bear Market Bar */}
          <Box
            onClick={() => {
              setShowDetails(true);
              setSelectedMarketType('bear');
            }}
                        sx={{
              width: `${trends.bearMarketSignals.length > 0 ? (trends.bearMarketSignals.length / (trends.bullMarketSignals.length + trends.bearMarketSignals.length)) * 100 : 0}%`,
              height: '50%',
              bgcolor: 'error.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'all 0.5s ease',
              opacity: 0.6 + Math.min(Math.abs((trends.bearMarketSignals.reduce((acc, signal) => acc + signal.return, 0) / (trends.bearMarketSignals.length || 1))), 0.4),
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.9,
                transform: 'translateY(2px)'
              }
            }}
          >
            {trends.bearMarketSignals.length > 0 ? (
              <Typography variant="body2" color="white" fontWeight="bold">
                {`${((trends.bearMarketSignals.length / (trends.bullMarketSignals.length + trends.bearMarketSignals.length)) * 100).toFixed(1)}% Bear`}
              </Typography>
            ) : (
              <Typography variant="caption" color="white" sx={{ fontSize: '0.6rem' }}>
                Minimal Bear Activity
              </Typography>
            )}
          </Box>
        </Box>

        {/* Performance Stats */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Typography variant="body2" color="text.secondary">
            Total Signals: {trends.bullMarketSignals.length + trends.bearMarketSignals.length}
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Typography variant="body2" color="success.main">
              Bull Momentum: {trends.bullMarketSignals.length > 0 
                ? `${(trends.bullMarketSignals.reduce((acc, signal) => acc + signal.return, 0) / (trends.bullMarketSignals.length || 1)).toFixed(1)}%` 
                : 'No Bull Market Signals'}
                          </Typography>
            <Typography variant="body2" color="error.main">
              Bear Momentum: {trends.bearMarketSignals.length > 0 
                ? `${(trends.bearMarketSignals.reduce((acc, signal) => acc + signal.return, 0) / (trends.bearMarketSignals.length || 1)).toFixed(1)}%` 
                : 'No Bear Market Signals'}
                          </Typography>
                        </Box>
        </Box>
      </Paper>

      {/* Collapsible Details */}
      <Collapse in={showDetails}>
        <Grid container spacing={3}>
          {/* Show panels based on selection */}
          {(!selectedMarketType || selectedMarketType === 'bull') && (
            <Grid item xs={12} md={selectedMarketType ? 12 : 6}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3,
                  bgcolor: 'success.light',
                  position: 'relative',
                  overflow: 'hidden'
                }}
              >
                <Box sx={{ 
                  position: 'absolute',
                  top: 16,
                  right: 16,
                  color: 'success.main'
                }}>
                  <TrendingUpIcon sx={{ fontSize: 32 }} />
                </Box>
                <Typography variant="h6" gutterBottom fontWeight="bold">Bull Market Signals</Typography>
                {trends.bullMarketSignals.length > 0 ? (
                  <Stack spacing={2}>
                    {trends.bullMarketSignals.map((signal, index) => (
                      <Box key={index} sx={{ 
                              bgcolor: 'background.paper',
                        p: 2,
                        borderRadius: 2
                      }}>
                        <Typography variant="subtitle1" fontWeight="bold">{signal.fund}</Typography>
                        <Grid container spacing={1} sx={{ mt: 1 }}>
                          <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary">Return</Typography>
                            <Typography variant="body2" color="success.main" fontWeight="bold">
                              {(signal.return * 100).toFixed(2)}%
                              </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary">Momentum</Typography>
                            <Typography variant="body2" fontWeight="bold">
                              {signal.momentum.toFixed(1)}%
                              </Typography>
                          </Grid>
                        </Grid>
                            </Box>
                    ))}
                    </Stack>
                ) : (
                  <Alert severity="info" sx={{ mt: 2 }}>No bull market signals detected</Alert>
                )}
              </Paper>
            </Grid>
          )}
          {(!selectedMarketType || selectedMarketType === 'bear') && (
            <Grid item xs={12} md={selectedMarketType ? 12 : 6}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3,
                  bgcolor: 'error.light',
                  position: 'relative',
                  overflow: 'hidden'
                }}
              >
                  <Box sx={{ 
                  position: 'absolute',
                  top: 16,
                  right: 16,
                  color: 'error.main'
                }}>
                  <TrendingDownIcon sx={{ fontSize: 32 }} />
                  </Box>
                <Typography variant="h6" gutterBottom fontWeight="bold">Bear Market Signals</Typography>
                {trends.bearMarketSignals.length > 0 ? (
                  <Stack spacing={2}>
                    {trends.bearMarketSignals.map((signal, index) => (
                      <Box key={index} sx={{ 
                        bgcolor: 'background.paper',
                        p: 2,
                        borderRadius: 2
                      }}>
                        <Typography variant="subtitle1" fontWeight="bold">{signal.fund}</Typography>
                        <Grid container spacing={1} sx={{ mt: 1 }}>
                          <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary">Return</Typography>
                            <Typography variant="body2" color="error.main" fontWeight="bold">
                              {(signal.return * 100).toFixed(2)}%
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary">Momentum</Typography>
                            <Typography variant="body2" fontWeight="bold">
                              {signal.momentum.toFixed(1)}%
                                </Typography>
                              </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </Stack>
                ) : (
                  <Alert severity="info" sx={{ mt: 2 }}>No bear market signals detected</Alert>
                )}
              </Paper>
            </Grid>
          )}
          
          {/* Market Trends Chart - show only if no specific market type is selected */}
          {!selectedMarketType && (
            <Grid item xs={12}>
              <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom fontWeight="bold">Market Trend Overview</Typography>
                <Box sx={{ height: 300, mt: 2 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                      data={[
                        ...trends.bearMarketSignals.map(signal => ({
                          name: signal.fund,
                          value: signal.return * 100,
                          type: 'Bear'
                        })),
                        ...trends.bullMarketSignals.map(signal => ({
                          name: signal.fund,
                          value: signal.return * 100,
                          type: 'Bull'
                        }))
                      ]}
                      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <ChartTooltip />
                      <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#8884d8"
                        fill="#8884d8"
                        fillOpacity={0.3}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </Box>
              </Paper>
            </Grid>
          )}
          </Grid>
      </Collapse>

      {/* Add Warnings Panel */}
      {renderWarningsPanel()}
    </Box>
  );
};

const FundMonitoringNew = () => {
  const [loading, setLoading] = useState(false);
  const [fundData, setFundData] = useState({});
  const [error, setError] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(new Date());

  // Calculate market trends with real data
  const marketTrends = useMemo(() => analyzeMarketTrends(fundData), [fundData]);

  // Fetch real market data
  const fetchMarketData = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await marketDataService.getFunds();
      setFundData(data);
      setLastUpdate(new Date());
    } catch (err) {
      setError('Failed to fetch market data. Please try again later.');
      console.error('Error fetching market data:', err);
    } finally {
      setLoading(false);
    }
  };

  // Initial data fetch
  useEffect(() => {
    fetchMarketData();
  }, []);

  // Auto-refresh every 5 minutes
  useEffect(() => {
    const interval = setInterval(fetchMarketData, 300000);
    return () => clearInterval(interval);
  }, []);

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        minHeight: '100vh',
        backgroundColor: 'background.default',
        py: 4,
        px: 2
      }}>
        <Container maxWidth="xl">
          <MarketTrendsReport 
            trends={marketTrends} 
            onRefresh={fetchMarketData}
            loading={loading}
          />
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default FundMonitoringNew; 