export const FAQ_ITEMS = [
  {
    question: "What is an ETF?",
    answer: `ETF stands for Exchange-Traded Fund. It's an investment fund that trades on stock exchanges, much like stocks. ETFs typically track a specific index, sector, commodity, or other asset, but can be bought and sold during the day at market prices.

Key benefits of ETFs include:
• Lower costs compared to traditional mutual funds
• Greater transparency in holdings
• Better tax efficiency
• More trading flexibility (can be bought/sold throughout the trading day)
• Diversification within a single investment`
  },
  {
    question: "Why choose Vanguard ETFs?",
    answer: "Vanguard is known for pioneering low-cost index investing. Their ETFs typically offer some of the lowest expense ratios in the industry, strong track records of performance, and excellent transparency in their investment strategies."
  },
  {
    question: "What is an expense ratio?",
    answer: "An expense ratio is the annual fee that funds charge their shareholders. It's expressed as a percentage of your investment. For example, an expense ratio of 0.15% means you'll pay £1.50 in fees for every £1,000 invested annually."
  },
  {
    question: "What is dividend reinvestment?",
    answer: "Dividend reinvestment means automatically using dividend payments to purchase additional shares of the fund. This can significantly boost long-term returns through compound growth, as shown in our calculator's results."
  },
  {
    question: "How often should I review my investments?",
    answer: "While it's good to stay informed, most long-term investors benefit from reviewing their portfolio quarterly or semi-annually. This helps maintain a disciplined investment approach while avoiding reactive decisions based on short-term market movements."
  },
  {
    question: "How do I interpret the investment metrics?",
    answer: `Each fund shows several key metrics to help you make informed decisions:

    • Expense Ratio (0.03% - 0.25%): The annual cost of the fund. Lower is better - a 0.1% ratio means £1 fee per £1,000 invested.
    
    • Historical Returns: Past performance over 1, 3, 5, and 10 years. While not guaranteeing future results, they help set expectations.
    
    • Dividend Yield (1% - 4%): Annual dividend payments as a percentage of fund price. Some funds reinvest dividends (accumulation) while others pay them out.
    
    • Risk Level: Ranges from Very Low to High. Higher risk typically means higher potential returns but also larger price swings.
    
    • Minimum Investment: The minimum amount needed to start. ETFs typically have lower minimums (£0 - £500) compared to traditional funds.`
  },
  {
    question: "What is an Accumulation Fund?",
    answer: "An accumulation fund automatically reinvests any income (like dividends) back into the fund. This means your money is used to buy more shares, helping your investment grow over time. It's like a snowball getting bigger as it rolls downhill."
  },
  {
    question: "What is a Distribution Fund?",
    answer: "A distribution fund pays out any income (like dividends) directly to you. You can use this as regular income or reinvest it yourself. Think of it like a fruit tree - you can collect the fruit (income) while the tree (your investment) continues to grow."
  },
  {
    question: "How do ISAs protect my investments from tax year after year?",
    answer: "Money in an ISA is protected from tax forever, not just in the year you invest. Here's an example:\n\nYear 1: You invest £20,000 (your full ISA allowance)\nYear 2: Your investment grows to £22,000 (£2,000 gain) - No tax on this gain\nYear 3: It grows to £25,000 (another £3,000 gain) - Still no tax\nYear 4: You get £1,000 in dividends - No tax on these dividends\nYear 5: You sell some shares for a £5,000 profit - No tax on this profit\n\nEach new tax year, you get a fresh £20,000 ISA allowance to protect more investments from future tax. The money already in your ISA stays tax-free forever, even if it grows to £100,000 or more. It's like each year you can put more money under a permanent tax-free umbrella."
  },
  {
    question: "How are ISAs different for tax?",
    answer: "An ISA (Individual Savings Account) is completely tax-free. You pay NO tax on: 1) Any dividends received or reinvested, 2) Any profits when you sell, regardless of the amount. For example, if you invest £20,000 in an ISA and it grows to £30,000 with £1,500 yearly dividends, you keep all the growth and dividends tax-free. The annual ISA allowance is £20,000 (2023/24)."
  },
  {
    question: "What's the difference between Distribution and Accumulation funds?",
    answer: `Distribution funds (often marked 'Inc' or 'Income'):
    • Pay out dividends as cash to your bank account
    • Good if you want regular income payments
    • Common for retirees or those needing regular income
    
    Accumulation funds (often marked 'Acc'):
    • Automatically reinvest dividends to buy more shares
    • Value grows faster due to compound growth
    • Better for long-term wealth building
    • Popular choice while you're still working and saving
    
    For example: If a fund pays 4% dividend yield annually:
    • Distribution fund: £10,000 investment would pay you £400 as cash
    • Accumulation fund: £10,000 investment would use that £400 to buy more shares`
  },
  {
    question: "What are the three main types of Vanguard funds?",
    answer: `Vanguard offers three main types of funds, each serving different investment goals:

    1. Blended Funds (e.g., LifeStrategy):
    • Combine both stocks and bonds in a single fund
    • Pre-made portfolios with fixed allocations (e.g., 60% stocks, 40% bonds)
    • Automatically rebalanced to maintain the target mix
    • Great for hands-off investors who want a complete solution
    • Available in different risk levels (20/80 to 100/0 equity/bond split)

    2. Equity Funds:
    • Invest purely in stocks (shares) of companies
    • Higher growth potential but also higher risk
    • Various focuses: global markets, specific regions, or investment styles
    • Examples include FTSE All-World ETF (global stocks) or S&P 500 ETF (US stocks)
    • Best for long-term growth and those comfortable with market volatility

    3. Fixed Income (Bond) Funds:
    • Invest in government and corporate bonds
    • Generally lower risk with more stable returns
    • Provide regular income through interest payments
    • Help protect against stock market volatility
    • Examples include UK Gilt funds or Global Bond funds
    • Suitable for conservative investors or those near retirement`
  },
  {
    question: "Which funds are best for a Stocks & Shares ISA?",
    answer: `For ISA investors, there are several popular approaches depending on your goals:

    1. Single Global Fund Approach:
    • FTSE All-World UCITS ETF (VWRL/VWRP)
    • Provides exposure to thousands of companies worldwide
    • Simple "set and forget" option
    • Good for most long-term investors

    2. Two-Fund Split:
    • S&P 500 ETF (VUSA) for US markets (60-70%)
    • FTSE 100 ETF (VUKE) for UK markets (30-40%)
    • Combines global growth with domestic market exposure

    3. Conservative Approach:
    • LifeStrategy 60% or 80% Equity funds
    • Automatically balanced between stocks and bonds
    • Good for more cautious investors

    Key considerations:
    • Accumulation funds (Acc) are popular for ISAs as they reinvest dividends tax-free
    • Consider starting with broad market funds rather than specialized ones
    • Look for low expense ratios (typically under 0.25%)`
  }
]; 