import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Box, Card, CardContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FAQ_ITEMS } from '../data/faq';

function FAQ() {
  return (
    <Box sx={{ 
      minHeight: '100vh',
      backgroundColor: 'background.default',
      py: 4,
      px: 2
    }}>
      <Container maxWidth="xl">
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 'bold',
            background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            mb: 4
          }}
        >
          Frequently Asked Questions
        </Typography>

        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {FAQ_ITEMS.map((item, index) => (
                <Accordion 
                  key={index} 
                  sx={{
                    backgroundColor: 'rgba(33, 150, 243, 0.04)',
                    borderRadius: '8px !important',
                    '&:before': { display: 'none' },
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    '&:first-of-type': { borderRadius: '8px !important' },
                    '&:last-of-type': { borderRadius: '8px !important' },
                    '&.Mui-expanded': {
                      margin: '0 !important',
                      borderRadius: '8px !important',
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main' }} />}
                    sx={{
                      '&.Mui-expanded': {
                        minHeight: '48px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                      },
                      '& .MuiAccordionSummary-content': {
                        margin: '12px 0',
                      }
                    }}
                  >
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontSize: '1.1rem', 
                        fontWeight: 500,
                        color: 'primary.main'
                      }}
                    >
                      {item.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ pt: 3, pb: 2 }}>
                    <Typography 
                      sx={{ 
                        whiteSpace: 'pre-line', 
                        color: 'text.secondary',
                        lineHeight: 1.7
                      }}
                    >
                      {item.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

export default FAQ; 