import { FALLBACK_FUND_DATA } from '../data/historicalFundData';

const DEFAULT_DATA = {
  historicalData: {
    oneYear: 0,
    threeYear: 0,
    fiveYear: 0,
    tenYear: 0,
    prices: []
  },
  currentPrice: 0,
  priceChange: 0,
  percentageChange: 0,
  historicalReturns: {
    oneYear: 0,
    threeYear: 0,
    fiveYear: 0,
    tenYear: 0
  },
  lastUpdated: new Date().toISOString()
};

export const updateFundData = async (symbol) => {
  // Just return our fallback data with a timestamp
  const fallbackData = FALLBACK_FUND_DATA[symbol];
  if (fallbackData) {
    console.log(`Using fallback data for ${symbol}`);
    return {
      ...fallbackData,
      lastUpdated: new Date().toISOString()
    };
  }
  
  // Return default data if no fallback exists
  console.log('No fallback data available for', symbol);
  return DEFAULT_DATA;
};

export const updateExchangeRates = async () => {
  // Return default exchange rates
  return {
    USD: 1.27,
    EUR: 1.16,
    GBP: 1.0,
    JPY: 148.42,
    CHF: 1.12,
    AUD: 1.92,
    CAD: 1.71,
    ZAR: 23.81,
    lastUpdated: new Date().toISOString()
  };
};

export const shouldUpdate = (lastUpdate) => {
  if (!lastUpdate) return true;
  const now = new Date();
  const lastUpdateTime = new Date(lastUpdate);
  const hoursSinceLastUpdate = (now - lastUpdateTime) / (1000 * 60 * 60);
  return hoursSinceLastUpdate >= 24;
}; 