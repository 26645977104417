import { useState, useEffect } from 'react';
import { 
  Container, 
  Card, 
  CardContent, 
  TextField, 
  Typography, 
  Box,
  ThemeProvider,
  createTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Chip,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  ListSubheader
} from '@mui/material';
import { VANGUARD_FUNDS } from './data/generated/vanguardETFs.js';
import { CURRENCIES, convertCurrency } from './data/currencies';
import { updateFundData, updateExchangeRates, shouldUpdate } from './services/marketData';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';

// Add new calculation parameters
const MARKET_FACTORS = {
  inflation: 0.024, // Current UK inflation rate
  platformFee: 0.0025, // Average platform fee
  tradingCosts: 0.001, // Average trading costs
  // Add scenario adjustments
  bearMarket: -0.15, // -15% adjustment for bear market
  recessionImpact: -0.10, // -10% for recession
  highInterestRates: -0.05, // -5% for high interest rates environment
  bullMarket: 0.15, // +15% for bull market
};

// Define ETF categories
const ETF_CATEGORIES = {
  OTHER: 'Other',
  EQUITY: 'Equity',
  FIXED_INCOME: 'Fixed Income'
};

// Import our calculation functions
const calculateInvestmentGrowth = (
  initialInvestment,
  monthlyContribution,
  annualReturnRate,
  years,
  expenseRatio
) => {
  // Adjust return rate for all costs
  const totalCosts = expenseRatio + MARKET_FACTORS.platformFee + MARKET_FACTORS.tradingCosts;
  const inflationAdjustedReturn = Math.max(0, annualReturnRate - MARKET_FACTORS.inflation); // Ensure non-negative
  const effectiveReturnRate = Math.max(0, (inflationAdjustedReturn - totalCosts) / 12); // Ensure non-negative monthly rate
  
  let futureValue = initialInvestment;
  let monthlyInflationAdjustment = 1 + (MARKET_FACTORS.inflation / 12);

  for (let month = 0; month < years * 12; month++) {
    // Adjust monthly contribution for inflation
    const inflationAdjustedContribution = monthlyContribution * Math.pow(monthlyInflationAdjustment, month);
    
    // Apply monthly growth
    futureValue = futureValue * (1 + effectiveReturnRate) + inflationAdjustedContribution;
  }

  return Math.round(futureValue * 100) / 100;
};

const calculateExpenseRatioImpact = (
  investmentAmount,
  expenseRatio,
  years
) => {
  // Include all costs
  const totalCosts = expenseRatio + MARKET_FACTORS.platformFee + MARKET_FACTORS.tradingCosts;
  const totalCost = investmentAmount * totalCosts * years;
  return Math.round(totalCost * 100) / 100;
};

const calculateTotalContributions = (
  initialInvestment,
  monthlyContribution,
  years
) => {
  const totalContributions = initialInvestment + (monthlyContribution * 12 * years);
  return Math.round(totalContributions * 100) / 100;
};

const calculateWithDividendReinvestment = (
  initialInvestment,
  monthlyContribution,
  annualReturnRate,
  dividendYield,
  years,
  expenseRatio
) => {
  const totalCosts = expenseRatio + MARKET_FACTORS.platformFee + MARKET_FACTORS.tradingCosts;
  const inflationAdjustedReturn = (annualReturnRate - MARKET_FACTORS.inflation);
  const effectiveReturnRate = (inflationAdjustedReturn - totalCosts) / 12;
  
  // Assume modest dividend growth rate
  const dividendGrowthRate = 0.02 / 12; // 2% annual dividend growth
  let currentDividendRate = dividendYield / 12;
  let futureValue = initialInvestment;
  let monthlyInflationAdjustment = 1 + (MARKET_FACTORS.inflation / 12);

  for (let month = 0; month < years * 12; month++) {
    // Adjust monthly contribution for inflation
    const inflationAdjustedContribution = monthlyContribution * Math.pow(monthlyInflationAdjustment, month);
    
    // Apply monthly growth
    futureValue = futureValue * (1 + effectiveReturnRate);
    
    // Apply growing dividend rate
    futureValue = futureValue * (1 + currentDividendRate);
    currentDividendRate *= (1 + dividendGrowthRate);
    
    // Add monthly contribution
    futureValue += inflationAdjustedContribution;
  }

  return Math.round(futureValue * 100) / 100;
};

const calculateYearlyDividends = (
  futureValue,
  dividendYield
) => {
  return Math.round(futureValue * dividendYield * 100) / 100;
};

// Default values
const DEFAULT_VALUES = {
  initialInvestment: 800, // ~1000 USD in GBP
  monthlyContribution: 80, // ~100 USD in GBP
  years: 10,
  annualReturnRate: 0.08,
  expenseRatio: 0.0015
};

// Create theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3', // Modern blue
      light: '#64b5f6',
      dark: '#1976d2',
    },
    secondary: {
      main: '#66bb6a', // Success green
    },
    background: {
      default: '#f5f7fa',
      paper: '#ffffff',
    },
    text: {
      primary: '#2c3e50',
      secondary: '#546e7a',
    },
  },
  typography: {
    h5: {
      fontWeight: 600,
      color: '#1a237e',
    },
    h6: {
      fontWeight: 500,
      color: '#1565c0',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: { xs: 8, sm: 16 },
          boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
          transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
          '&:hover': {
            transform: { xs: 'none', sm: 'translateY(-4px)' },
            boxShadow: '0 8px 24px 0 rgba(0,0,0,0.15)',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 8,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontWeight: 500,
          margin: '4px',
        },
      },
    },
  },
});

const getRiskColor = (riskLevel) => {
  switch (riskLevel) {
    case RISK_LEVELS.LOW:
      return '#4caf50'; // Green
    case RISK_LEVELS.LOW_MODERATE:
      return '#8bc34a'; // Light Green
    case RISK_LEVELS.MODERATE:
      return '#ffc107'; // Amber
    case RISK_LEVELS.MODERATE_HIGH:
      return '#ff9800'; // Orange
    case RISK_LEVELS.HIGH:
      return '#f44336'; // Red
    default:
      return '#757575'; // Grey
  }
};

const INVESTMENT_TYPES = {
  ISA_STARTER: 'ISA Starter',
  ISA_GROWTH: 'ISA Growth',
  ISA_INCOME: 'ISA Income',
  GENERAL: 'General Investment'
};

const RISK_LEVELS = {
  ALL: 'All Risk Levels',
  LOW: 'Low',
  LOW_MODERATE: 'Low-Moderate',
  MODERATE: 'Moderate',
  MODERATE_HIGH: 'Moderate-High',
  HIGH: 'High'
};

// Update the risk level filter options
const RISK_LEVEL_OPTIONS = [
  RISK_LEVELS.ALL,
  RISK_LEVELS.LOW,
  RISK_LEVELS.LOW_MODERATE,
  RISK_LEVELS.MODERATE,
  RISK_LEVELS.MODERATE_HIGH,
  RISK_LEVELS.HIGH
];

const DISTRIBUTION_TYPES = {
  ALL: 'All Types',
  INCOME: 'Income (Distribution)',
  GROWTH: 'Growth (Accumulation)'
};

// Add ISA eligibility check
const isISAEligible = (fund) => {
  // Check if fund meets ISA criteria
  const hasLowCost = fund.expenseRatio <= 0.0045; // 0.45% max expense ratio
  const isEstablished = fund.historicalReturns?.fiveYear !== undefined;
  const hasGoodLiquidity = !fund.minimumInvestment || fund.minimumInvestment <= 500;
  
  return hasLowCost && isEstablished && hasGoodLiquidity;
};

const sortFundsByReturns = (funds) => {
  return Object.entries(funds)
    .map(([isin, fund]) => ({
      isin,
      ...fund,
      // Calculate overall return score prioritizing longer-term returns
      returnScore: (
        (fund.historicalReturns?.tenYear || 0) * 2 + // Double weight for 10-year returns
        (fund.historicalReturns?.fiveYear || 0)      // Regular weight for 5-year returns
      )
    }))
    .sort((a, b) => b.returnScore - a.returnScore) // Sort by return score descending
    .slice(0, 90); // Take top 90 funds
};

function App() {
  const [inputs, setInputs] = useState(DEFAULT_VALUES);
  const [results, setResults] = useState({
    futureValue: 0,
    futureValueWithDividends: 0,
    expenseImpact: 0,
    totalContributions: 0,
    yearlyDividends: 0
  });
  const [selectedFund, setSelectedFund] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('GBP');
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState('');
  const [marketScenarios, setMarketScenarios] = useState({
    bearMarket: false,
    recession: false,
    highInterestRates: false,
    bullMarket: false
  });

  useEffect(() => {
    calculateResults();
  }, [inputs, selectedFund, marketScenarios]);

  const calculateResults = () => {
    let adjustedReturnRate = inputs.annualReturnRate;
    
    // Apply market scenario adjustments
    if (marketScenarios.bearMarket) {
      adjustedReturnRate += MARKET_FACTORS.bearMarket;
    }
    if (marketScenarios.recession) {
      adjustedReturnRate += MARKET_FACTORS.recessionImpact;
    }
    if (marketScenarios.highInterestRates) {
      adjustedReturnRate += MARKET_FACTORS.highInterestRates;
    }
    if (marketScenarios.bullMarket) {
      adjustedReturnRate += MARKET_FACTORS.bullMarket;
    }

    // Ensure return rate doesn't go below -30% or above 40%
    adjustedReturnRate = Math.max(-0.30, Math.min(0.40, adjustedReturnRate));
    
    const futureValue = calculateInvestmentGrowth(
      inputs.initialInvestment,
      inputs.monthlyContribution,
      adjustedReturnRate,
      inputs.years,
      inputs.expenseRatio
    );

    const expenseImpact = calculateExpenseRatioImpact(
      inputs.initialInvestment + (inputs.monthlyContribution * 12 * inputs.years),
      inputs.expenseRatio,
      inputs.years
    );

    let futureValueWithDividends = futureValue;
    let yearlyDividends = 0;

    // If a fund is selected, calculate with dividend reinvestment
    if (selectedFund && VANGUARD_FUNDS[selectedFund]) {
      const fund = VANGUARD_FUNDS[selectedFund];
      if (fund.dividendYield) {
        futureValueWithDividends = calculateWithDividendReinvestment(
          inputs.initialInvestment,
          inputs.monthlyContribution,
          inputs.annualReturnRate,
          fund.dividendYield,
          inputs.years,
          inputs.expenseRatio
        );
        yearlyDividends = calculateYearlyDividends(futureValueWithDividends, fund.dividendYield);
      }
    }

    const totalContributions = calculateTotalContributions(
      inputs.initialInvestment,
      inputs.monthlyContribution,
      inputs.years
    );

    console.log('Calculation results:', { 
      futureValue, 
      futureValueWithDividends, 
      expenseImpact, 
      totalContributions, 
      yearlyDividends 
    }); // Debug log

    setResults({
      futureValue,
      futureValueWithDividends,
      expenseImpact,
      totalContributions,
      yearlyDividends
    });
  };

  const updateInput = (field, value) => {
    // Remove leading zeros and handle empty/invalid inputs
    let cleanValue = value;
    if (value !== '') {
      // Convert to number and back to string to remove leading zeros
      cleanValue = Number(value).toString();
      // If the conversion resulted in 'NaN', use '0'
      if (cleanValue === 'NaN') {
        cleanValue = '0';
      }
    }
    const numValue = parseFloat(cleanValue) || 0;
    setInputs(prev => ({ ...prev, [field]: numValue }));
  };

  const handleFundChange = (event) => {
    const isin = event.target.value;
    console.log('Selected fund ISIN:', isin); // Debug log
    setSelectedFund(isin);
    
    if (isin && VANGUARD_FUNDS[isin]) {
      const fund = VANGUARD_FUNDS[isin];
      console.log('Fund data:', fund); // Debug log
      
      // Get the historical return rate (convert from percentage to decimal if needed)
      let returnRate = fund.historicalReturns?.fiveYear;
      if (returnRate < 0) {
        // If 5-year return is negative, try 10-year return
        returnRate = fund.historicalReturns?.tenYear;
      }
      if (!returnRate || returnRate < 0) {
        // If still no valid return rate, use category average or default
        returnRate = 0.08; // 8% default return rate
      }
      
      // Update inputs with fund data
      const newInputs = {
        ...inputs,
        annualReturnRate: Math.abs(returnRate), // Ensure positive return rate
        expenseRatio: fund.expenseRatio || DEFAULT_VALUES.expenseRatio
      };
      console.log('New inputs:', newInputs); // Debug log
      setInputs(newInputs);
    }
  };

  const handleCurrencyChange = (event) => {
    const newCurrency = event.target.value;
    const oldCurrency = selectedCurrency;
    
    // Convert input values to new currency
    const newInitialInvestment = convertCurrency(inputs.initialInvestment, oldCurrency, newCurrency);
    const newMonthlyContribution = convertCurrency(inputs.monthlyContribution, oldCurrency, newCurrency);
    
    setSelectedCurrency(newCurrency);
    setInputs(prev => ({
      ...prev,
      initialInvestment: Math.round(newInitialInvestment * 100) / 100,
      monthlyContribution: Math.round(newMonthlyContribution * 100) / 100
    }));
  };

  const formatCurrency = (value) => {
    if (value === undefined || value === null) {
      return '0.00';
    }
    try {
      return CURRENCIES[selectedCurrency]?.format(value) || `${value.toFixed(2)}`;
    } catch (error) {
      console.error('Error formatting currency:', error);
      return `${value.toFixed(2)}`;
    }
  };

  const formatPercent = (value) => {
    if (value === undefined || value === null) {
      return '0.00%';
    }
    try {
      return (value * 100).toFixed(2) + '%';
    } catch (error) {
      console.error('Error formatting percentage:', error);
      return '0.00%';
    }
  };

  const clearFundSelection = () => {
    setSelectedFund('');
    setInputs(prev => ({
      ...prev,
      annualReturnRate: DEFAULT_VALUES.annualReturnRate,
      expenseRatio: DEFAULT_VALUES.expenseRatio
    }));
  };

  const renderFundOption = (fund) => (
    <Box sx={{ 
      p: 1.5,
      width: '100%',
      borderLeft: `4px solid ${getRiskColor(fund.riskLevel)}`,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      }
    }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
        {fund.name}
      </Typography>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
          <Box component="span" sx={{ 
            width: 8, 
            height: 8, 
            borderRadius: '50%', 
            backgroundColor: getRiskColor(fund.riskLevel),
            mr: 0.5 
          }}/>
          {fund.riskLevel}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          •
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {fund.category}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          •
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Expense: {(fund.expenseRatio * 100).toFixed(2)}%
        </Typography>
        <Typography variant="caption" color="text.secondary">
          •
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {fund.accumulationFund ? 'Accumulating' : 'Distributing'}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        minHeight: '100vh',
        backgroundColor: 'background.default',
        py: { xs: 2, sm: 4 },
        px: { xs: 1, sm: 2 }
      }}>
        <Container maxWidth="xl">
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 'bold',
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
              textAlign: { xs: 'center', sm: 'left' },
              background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: { xs: 2, sm: 4 }
            }}
          >
            ISA-Eligible Fund Performance & Investment Calculator
          </Typography>

          <Card sx={{ mb: { xs: 2, sm: 3 } }}>
            <CardContent>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between', 
                alignItems: { xs: 'stretch', sm: 'center' }, 
                mb: 3,
                gap: 2
              }}>
                <Typography variant="h5" sx={{ 
                  mb: 0,
                  textAlign: { xs: 'center', sm: 'left' }
                }}>
                  Fund Selection
                </Typography>
                <FormControl sx={{ 
                  minWidth: { xs: '100%', sm: 150 }
                }}>
                  <InputLabel>Currency</InputLabel>
                  <Select
                    value={selectedCurrency}
                    label="Currency"
                    onChange={handleCurrencyChange}
                    sx={{ borderRadius: 2 }}
                  >
                    {Object.entries(CURRENCIES).map(([code, currency]) => (
                      <MenuItem key={code} value={code}>
                        {currency.symbol} {code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Search funds by name or ISIN"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      sx={{ mb: 2 }}
                    />
                    <FormControl fullWidth>
                      <InputLabel>Select from Top 90 by Returns</InputLabel>
                      <Select
                        value={selectedFund}
                        label="Select from Top 90 by Returns"
                        onChange={handleFundChange}
                        sx={{ borderRadius: 2 }}
                      >
                        {sortFundsByReturns(VANGUARD_FUNDS)
                          .filter(fund => 
                            isISAEligible(fund) &&
                            (searchQuery === '' || 
                              fund.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                              fund.isin.toLowerCase().includes(searchQuery.toLowerCase()))
                          )
                          .map(fund => (
                            <MenuItem key={fund.isin} value={fund.isin}>
                              {renderFundOption(fund)}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>

              {selectedFund && (
                <Box sx={{ mb: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <Box sx={{ 
                        p: { xs: 1, sm: 2 },
                        bgcolor: 'rgba(33, 150, 243, 0.04)',
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2
                      }}>
                        <Box>
                          <Typography variant="h6" gutterBottom sx={{
                            fontSize: { xs: '1rem', sm: '1.25rem' }
                          }}>
                            {VANGUARD_FUNDS[selectedFund].name} ({selectedFund})
                          </Typography>
                          <Typography color="text.secondary" paragraph sx={{
                            fontSize: { xs: '0.875rem', sm: '1rem' }
                          }}>
                            {VANGUARD_FUNDS[selectedFund].description}
                          </Typography>
                          
                          <Box sx={{ 
                            display: 'flex', 
                            flexWrap: 'wrap', 
                            gap: 1,
                            justifyContent: { xs: 'center', sm: 'flex-start' }
                          }}>
                            <Chip 
                              label={`Risk Level: ${VANGUARD_FUNDS[selectedFund].riskLevel}`}
                              sx={{ 
                                borderColor: getRiskColor(VANGUARD_FUNDS[selectedFund].riskLevel),
                                color: getRiskColor(VANGUARD_FUNDS[selectedFund].riskLevel)
                              }}
                              variant="outlined"
                            />
                            <Chip 
                              label={`Category: ${VANGUARD_FUNDS[selectedFund].category}`}
                              color="secondary"
                              variant="outlined"
                            />
                            <Chip 
                              label={`Dividend Yield: ${(VANGUARD_FUNDS[selectedFund].dividendYield * 100).toFixed(2)}%`}
                              color="success"
                              variant="outlined"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                      <Paper sx={{ p: 2, borderRadius: 2, height: '100%' }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>Market Scenarios</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Bear Market <Typography component="span" color="error" variant="caption">(-15%)</Typography></Typography>
                            <ToggleButton
                              value="bearMarket"
                              selected={marketScenarios.bearMarket}
                              onChange={() => setMarketScenarios(prev => ({ ...prev, bearMarket: !prev.bearMarket, bullMarket: false }))}
                              color="error"
                              size="small"
                            >
                              {marketScenarios.bearMarket ? 'On' : 'Off'}
                            </ToggleButton>
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Recession <Typography component="span" color="error" variant="caption">(-10%)</Typography></Typography>
                            <ToggleButton
                              value="recession"
                              selected={marketScenarios.recession}
                              onChange={() => setMarketScenarios(prev => ({ ...prev, recession: !prev.recession }))}
                              color="error"
                              size="small"
                            >
                              {marketScenarios.recession ? 'On' : 'Off'}
                            </ToggleButton>
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>High Interest Rates <Typography component="span" color="warning" variant="caption">(-5%)</Typography></Typography>
                            <ToggleButton
                              value="highInterestRates"
                              selected={marketScenarios.highInterestRates}
                              onChange={() => setMarketScenarios(prev => ({ ...prev, highInterestRates: !prev.highInterestRates }))}
                              color="warning"
                              size="small"
                            >
                              {marketScenarios.highInterestRates ? 'On' : 'Off'}
                            </ToggleButton>
                          </Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Bull Market <Typography component="span" color="success" variant="caption">(+15%)</Typography></Typography>
                            <ToggleButton
                              value="bullMarket"
                              selected={marketScenarios.bullMarket}
                              onChange={() => setMarketScenarios(prev => ({ ...prev, bullMarket: !prev.bullMarket, bearMarket: false }))}
                              color="success"
                              size="small"
                            >
                              {marketScenarios.bullMarket ? 'On' : 'Off'}
                            </ToggleButton>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </CardContent>
          </Card>

          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Investment Calculator
              </Typography>
              
              <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    label={`Initial Investment (${CURRENCIES[selectedCurrency].symbol})`}
                    value={inputs.initialInvestment === 0 ? '' : inputs.initialInvestment.toString()}
                    onChange={(e) => updateInput('initialInvestment', e.target.value)}
                    type="number"
                    inputProps={{ min: 0 }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    fullWidth
                    label={`Monthly Contribution (${CURRENCIES[selectedCurrency].symbol})`}
                    value={inputs.monthlyContribution === 0 ? '' : inputs.monthlyContribution.toString()}
                    onChange={(e) => updateInput('monthlyContribution', e.target.value)}
                    type="number"
                    inputProps={{ min: 0 }}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField
                    fullWidth
                    label="Years"
                    value={inputs.years === 0 ? '' : inputs.years.toString()}
                    onChange={(e) => updateInput('years', e.target.value)}
                    type="number"
                    inputProps={{ min: 1 }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Results
              </Typography>
              
              <Grid container spacing={3}>
                {[
                  { 
                    label: 'Future Value', 
                    value: results.futureValue,
                    color: 'primary.main',
                    xs: 12,
                    sm: 6,
                    md: 4
                  },
                  { 
                    label: 'Future Value (With Dividend Reinvestment)', 
                    value: results.futureValueWithDividends,
                    color: '#66bb6a',
                    xs: 12,
                    sm: 6,
                    md: 4
                  },
                  { 
                    label: 'Total Contributions', 
                    value: results.totalContributions,
                    color: 'primary.main',
                    xs: 12,
                    sm: 6,
                    md: 4
                  },
                  { 
                    label: 'Investment Growth', 
                    value: results.futureValueWithDividends - results.totalContributions,
                    color: 'primary.main',
                    xs: 12,
                    sm: 6,
                    md: 4
                  },
                  { 
                    label: 'Projected Yearly Dividend Income', 
                    value: results.yearlyDividends,
                    color: '#66bb6a',
                    xs: 12,
                    sm: 6,
                    md: 4
                  },
                  { 
                    label: 'Expense Cost', 
                    value: results.expenseImpact,
                    color: '#ef5350',
                    xs: 12,
                    sm: 6,
                    md: 4
                  }
                ].map((item) => (
                  <Grid item xs={item.xs} sm={item.sm} md={item.md} key={item.label}>
                    <Box sx={{ 
                      p: { xs: 2, sm: 3 },
                      bgcolor: 'rgba(33, 150, 243, 0.04)',
                      borderRadius: 2,
                      textAlign: 'center',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}>
                      <Typography color="text.secondary" gutterBottom sx={{
                        fontSize: { xs: '0.875rem', sm: '1rem' }
                      }}>
                        {item.label}
                      </Typography>
                      <Typography variant="h5" sx={{ 
                        color: item.color, 
                        fontWeight: 'bold',
                        fontSize: { xs: '1.25rem', sm: '1.5rem' }
                      }}>
                        {formatCurrency(item.value)}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
